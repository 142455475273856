import React, { useEffect, useState } from "react";
import { Box, Container, Divider, Drawer, FormControl, Grid, IconButton, InputAdornment, Stack, Typography, } from "@mui/material";
import { IOnlineSubscriptionDetails, ISubscriptionPlan } from "../../Interfaces/ISubscription";
import { useFormik } from "formik";
import * as Yup from "yup";
import CadisTextField from "../../Components/Input/CadisTextField";
import apis from "../../HttpConfig/Api";
import { FormRenewMode, currentHost } from "../../Common/helper";
import { toast } from "react-toastify";
import { t } from 'i18next';
import { ApplyDiscountCouponRoute, SelectedSubscriptionDetails } from "../../Common/api.routes";
import CadisButton from "../../Components/Input/CadisButton";
import { formateDecimalValues, isAuthenticated } from "../../Common/helperFunction";
import { auto } from "@popperjs/core";

const validationSchema = Yup.object({
  numOfSubscriptions: Yup.number()
    .required("No. of Subscriptions is required.")
    .min(1, "Must be at least 1.")
    .max(999, 'Maximum value is 999.')
});

const OrgAdminAddSubscriptionPlan = ({
  currentSubscriptionPlans,
  isOpenAddSelectedPlan,
  setAddSelectedPlanDrawer,
  planDetails,
  renewCurrentSubscriptionPlan,
  setRenewSubscriptionDrawer,
  renewSubscriptionDrawer,
  onlineSubscriptionDetailsMode,
}: {
  isOpenAddSelectedPlan?: boolean;
  currentSubscriptionPlans?: ISubscriptionPlan;
  setAddSelectedPlanDrawer?: any;
  planDetails?: any;
  renewCurrentSubscriptionPlan?: IOnlineSubscriptionDetails;
  setRenewSubscriptionDrawer?: any;
  renewSubscriptionDrawer?: boolean;
  onlineSubscriptionDetailsMode?: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponMessage, setCouponMessage] = useState('');
  const [discountPrice, setDiscountPrice] = useState<string>('');
  const [couponStatus, setCouponStatus] = useState(false);
  const [discountPercentageNum, setDiscountPercentageNum] = useState<string>('');

  const user = isAuthenticated();

  const formattedPrice = Number(planDetails?.total_price).toFixed(2)
  const { values, errors, touched, handleSubmit, setFieldValue, handleChange, setErrors } = useFormik({
    initialValues: {
      numOfSubscriptions: 1,
      couponCode: '',
      userCount: planDetails?.max_user_bundle_count,
      totalPrice: Number(formattedPrice),
      discountedPrice: 0,
      renewSubscriptionTotalPrice: 0,
      dataLimit: planDetails?.data_limit
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      setLoading(true);
      sendOnlineSubscriptiondetails();
    },
  });

  const renewTotalPriceWithOutDiscount = ((renewCurrentSubscriptionPlan?.planAmount ?? 0) * (renewCurrentSubscriptionPlan?.noOfSubscription ?? 0));
  useEffect(() => {
    setFieldValue("totalPrice", Number(formattedPrice).toFixed(2));
    setFieldValue("userCount", planDetails?.max_user_bundle_count);
    if (planDetails?.data_limit != 0 && planDetails !== undefined) {
      setFieldValue("dataLimit", parseFloat(planDetails?.data_limit));
    } else {
      const dataLimininGb = renewCurrentSubscriptionPlan?.dataLimit ? renewCurrentSubscriptionPlan?.dataLimit / 1024 : 0
      setFieldValue("dataLimit", dataLimininGb);
    }
    setFieldValue("renewSubscriptionTotalPrice", Number(renewTotalPriceWithOutDiscount).toFixed(2));
  }, [planDetails, isOpenAddSelectedPlan]);



  const handleInputChange = (e: any) => {
    clearStateVaules();
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\s/g, '');
    if (inputValue === '' || inputValue === '0') {
      setFieldValue('numOfSubscriptions', '');
      setFieldValue('userCount', 0);
      setFieldValue("totalPrice", 0);
      setFieldValue("dataLimit", 0);
      return;
    }
    if (inputValue.length > 1 && inputValue[0] === '0') {
      inputValue = inputValue.slice(1);
    }
    if (!/^\d+$/.test(inputValue)) {
      return;
    }
    const userCount = inputValue * planDetails?.max_user_bundle_count;
    const TotalAmount = inputValue * planDetails?.total_price;
    const toatalData = inputValue * planDetails?.data_limit
    if (isNaN(inputValue)) {
      setFieldValue('userCount', 0);
      setFieldValue("dataLimit", 0);
      setFieldValue("totalPrice", Number(planDetails?.total_price).toFixed(2));
    } else {
      setFieldValue('userCount', userCount);
      setFieldValue('dataLimit', toatalData);
      setFieldValue("totalPrice", TotalAmount.toFixed(2))
    }
    //used to trigger logic associated with the error & handleInputChange.
    handleChange("numOfSubscriptions")(e);
  }

  const handleCloseDrawer = () => {
    if (onlineSubscriptionDetailsMode === FormRenewMode)
      setRenewSubscriptionDrawer(false);
    else {
      setAddSelectedPlanDrawer(false);
    }
    setFieldValue('numOfSubscriptions', 1);
    setErrors({});
    clearStateVaules();
  }

  const sendOnlineSubscriptiondetails = async () => {
    setLoading(true);
    let data;
    if (onlineSubscriptionDetailsMode === FormRenewMode) {
      data = {
        "userName": user.name,
        "userId": user.userId,
        "userEmail": user.userEmail,
        "orgId": user.orgId,
        "totalAmount": couponCode ? values.discountedPrice : values.renewSubscriptionTotalPrice,
        "planId": renewCurrentSubscriptionPlan?.planId,
        "planName": renewCurrentSubscriptionPlan?.planName,
        "planAmount": renewCurrentSubscriptionPlan?.planAmount,
        "noOfSubscription": renewCurrentSubscriptionPlan?.noOfSubscription,
        "noOfUsers": renewCurrentSubscriptionPlan?.noOfUsers,
        "successUrl": currentHost + "/dashboard/myPlans/1",
        "cancelUrl": currentHost + "/dashboard/myPlans/2",
        "discountCode": couponCode ? couponCode : "",
        "isRenew": true,
        "subscriptionId": renewCurrentSubscriptionPlan?.subscriptionId,
        "dataLimit": renewCurrentSubscriptionPlan?.isDataUnlimited ? 0 : values.dataLimit,
        "isDataUnlimited": renewCurrentSubscriptionPlan?.isDataUnlimited
      }
    } else {
      data = {
        "userName": user.name,
        "userId": user.userId,
        "userEmail": user.userEmail,
        "orgId": user.orgId,
        "totalAmount": couponCode ? values.discountedPrice : values.totalPrice,
        "planId": planDetails?.plan_id,
        "planName": planDetails?.name,
        "planAmount": planDetails?.total_price,
        "noOfSubscription": parseInt(values.numOfSubscriptions.toString()),
        "noOfUsers": values.userCount,
        "successUrl": currentHost + "/dashboard/subscriptionPlans/orgAdmins/1",
        "cancelUrl": currentHost + "/dashboard/subscriptionPlans/orgAdmins/2",
        "discountCode": couponCode ? couponCode : "",
        "isRenew": false,
        "subscriptionId": 0,
        "dataLimit": planDetails?.is_data_unlimited ? 0 : values.dataLimit,
        "isDataUnlimited": planDetails?.is_data_unlimited === 1 ? true : false
      }
    }

    try {
      await apis.instance.post(`${SelectedSubscriptionDetails}`, data)
        .then((resp: any) => {
          if (resp.data.success) {
            if (onlineSubscriptionDetailsMode === FormRenewMode) {
              setRenewSubscriptionDrawer(false);
            } else {
              setAddSelectedPlanDrawer(false);
            }
            window.location.href = resp.data.checkoutUrl;
            setLoading(false);
          } else {
            const messageCompare = resp.data.error.toLowerCase() === "plan is not active."
            if (messageCompare && onlineSubscriptionDetailsMode === FormRenewMode) {
              toast.error(`${t("lbl_renewalFailed")}${t("lbl_planNoLongerAvailable")}`);
            }else{
              toast.error(t("lbl_planNoLongerAvailable"));
            }
          }
        })
        .finally(() => setLoading(false));
    } catch (error: any) {
      setLoading(false);
      toast.error(t('responseMsg.msg_wrongEntry'));
    }
  }
  const clearStateVaules = () => {
    setCouponStatus(false);
    setCouponMessage('');
    setCouponCode('');
    setDiscountPrice('');
    setFieldValue('couponCode', '');
    setDiscountPercentageNum('');
  }

  const handleCouponClear = () => {
    clearStateVaules();
  }

  const applyCouponCode = async () => {
    try {
      const params = {
        "code": values.couponCode
      }
      await apis.instance.get(`${ApplyDiscountCouponRoute}`, { params })
        .then((resp: any) => {
          if (resp.data.success) {
            const coupon = resp.data.data[0].discount_code;
            let discountPercentage = resp.data.data[0].discount_percentage;
            let totalAmount;
            let discountAmount;
            let formattedTotalAmount;
            setDiscountPercentageNum('(' + discountPercentage + '%)');
            if (onlineSubscriptionDetailsMode === FormRenewMode) {
              totalAmount = (values.renewSubscriptionTotalPrice - (values.renewSubscriptionTotalPrice * discountPercentage) / 100).toFixed(2);
              formattedTotalAmount = parseFloat(totalAmount);
              discountAmount = values.renewSubscriptionTotalPrice - formattedTotalAmount;
              setDiscountPrice(parseFloat(discountAmount.toFixed(2)) + ' (' + resp.data.data[0].discount_percentage + '% Off)');
            } else {
              totalAmount = (values.totalPrice - (values.totalPrice * discountPercentage) / 100).toFixed(2);
              formattedTotalAmount = parseFloat(totalAmount);
              discountAmount = values.totalPrice - formattedTotalAmount;
              setDiscountPrice(parseFloat(discountAmount.toFixed(2)) + ' (' + resp.data.data[0].discount_percentage + '% Off)');
            }
            const couponMessage = t('responseMsg.msg_couponCodeSuccessMessage');
            setCouponStatus(true);
            setCouponCode(coupon);
            setCouponMessage(couponMessage);
            setFieldValue('couponCode', '');
            setFieldValue("totalPrice", (values.totalPrice));
            if (onlineSubscriptionDetailsMode === FormRenewMode) {
              setFieldValue("discountedPrice", Number(totalAmount).toFixed(2));
            } else {
              setFieldValue("discountedPrice", Number(totalAmount).toFixed(2));
            }
          } else {
            if (resp.data.error === "discount code not found") {
              const couponMessage = t('responseMsg.msg_couponCodeErrorMessage');
              setCouponStatus(false);
              setCouponMessage(couponMessage);
            }
          }
        })
        .finally(() => setLoading(false));
    }
    catch (error: any) {
      setLoading(false);
      toast.error(t('responseMsg.msg_wrongEntry'));
    }
  }

  const handleCouponCodeChange = (e: any) => {
    setCouponMessage('');
    handleChange(e);
  }

  const cleanedDiscountPrice = discountPrice.replace(/\s*\(.*\)\s*/, '');

  //Calculate paybleAmount for new subscription or renewal whether coupon applied or not. 
  const totalPayableAmountValue = () => {
    let payableAmount;
    if (couponStatus) {
      payableAmount = ('$' + values.discountedPrice); // add subscription or renew subscription when coupon added 
    } else if (onlineSubscriptionDetailsMode === FormRenewMode) {
      payableAmount = ('$' + values.renewSubscriptionTotalPrice); // renew subscription when coupon not added
    } else {
      payableAmount = ('$' + values.totalPrice); // add subscription when coupon not added
    }
    return payableAmount;
  }

  return (
    <>
      <Drawer
        anchor='right'
        open={isOpenAddSelectedPlan}
        onClose={() => { return null }}
      >
        <Box role="presentation">
          <Box sx={{ pt: 1.5, position: 'sticky', top: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }} >
            <Container sx={{ alignItems: 'center', display: 'flex' }} disableGutters>
              <Box sx={{ width: '40px', height: '40px', flexGrow: 1, display: { xs: 'flex', pr: 2 } }}>
                <IconButton onClick={handleCloseDrawer} className="c-pointer" >
                  <span className="cad-close"></span>
                </IconButton>
              </Box>
            </Container>
            <Box sx={{ mb: 2, pb: 2, mt: 2, display: "flex" }}>
              <Typography className="mt-0 mb-0 slide-right-title" sx={(theme) => theme.typography.h5} >
                {onlineSubscriptionDetailsMode === FormRenewMode ? 'Renew' : 'Buy'} Subscription Plan - <Typography sx={{ display: 'inline', font: 'inherit' }} color={'success.main'} >{onlineSubscriptionDetailsMode === FormRenewMode ? renewCurrentSubscriptionPlan?.planName : planDetails?.name}</Typography>
              </Typography>
            </Box>
          </Box>
          <Grid container width={'100%'}>
            <form onSubmit={handleSubmit} className="w-100p">

              <Stack spacing={2} direction={'column'} paddingX={'20px'}>
                <FormControl variant="outlined" size="small">
                  <label id="noOfSubsLabelID" className='form-label'>{t("lbl_noOfSubscriptions")}{onlineSubscriptionDetailsMode !== FormRenewMode && <span className='mandatory-star'></span>}</label>
                  <CadisTextField onChange={handleInputChange}
                    value={onlineSubscriptionDetailsMode === FormRenewMode ? renewCurrentSubscriptionPlan?.noOfSubscription : values.numOfSubscriptions}
                    error={touched.numOfSubscriptions && Boolean(errors.numOfSubscriptions)}
                    helperText={touched.numOfSubscriptions && errors.numOfSubscriptions}
                    disabled={onlineSubscriptionDetailsMode === FormRenewMode}
                  />
                </FormControl>

                <FormControl variant="outlined" size="small">
                  <label id="noOfUserLabelID" className='form-label'>No. of users</label>
                  <CadisTextField disabled value={onlineSubscriptionDetailsMode === FormRenewMode ? renewCurrentSubscriptionPlan?.noOfUsers : values.userCount} />
                </FormControl>

                {planDetails?.is_data_unlimited || renewCurrentSubscriptionPlan?.isDataUnlimited ?
                  <FormControl variant="outlined" size="small">
                    <label id="noOfUserLabelID" className='form-label'>
                      {t('lbl_dataUsageFor')} {planDetails?.billing_cycle > 0 ? planDetails?.billing_cycle : renewCurrentSubscriptionPlan?.billing_cycle} {((Number(planDetails?.billing_cycle) === 1) || (Number(renewCurrentSubscriptionPlan?.billing_cycle) === 1)) ? t("lbl_month") : t("lbl_months")} {t("lbl_for_subscription")}
                    </label>
                    <CadisTextField disabled value={"Unlimited"} name="dataLimit" />
                  </FormControl> :
                  <FormControl variant="outlined" size="small">
                    <label id="noOfUserLabelID" className='form-label'>
                      {t('lbl_dataUsageFor')} {planDetails?.billing_cycle > 0 ? planDetails?.billing_cycle : renewCurrentSubscriptionPlan?.billing_cycle} {((Number(planDetails?.billing_cycle) === 1) || (Number(renewCurrentSubscriptionPlan?.billing_cycle) === 1)) ? t("lbl_month") : t("lbl_months")} {t("lbl_for_subscription")}
                    </label>
                    <CadisTextField disabled value={values.dataLimit} name="dataLimit" InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ fontSize: '1.2rem' }}>
                        GB
                      </InputAdornment>
                    ),
                  }} />
                  </FormControl>}
                {(renewCurrentSubscriptionPlan?.isPlanExpired && renewCurrentSubscriptionPlan?.totalRolloverData) ? <FormControl variant="outlined" size="small">
                  <label id="noOfUserLabelID" className='form-label'>{t("lbl_totalRolloverData")}</label>
                  <CadisTextField disabled value={renewCurrentSubscriptionPlan.totalRolloverData} name="dataLimit" InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ fontSize: '1.2rem' }}>
                        GB
                      </InputAdornment>
                    ),
                  }} />
                </FormControl> : ""}

                <Grid container columnSpacing={0} rowSpacing={0} rowGap={0} columnGap={1} alignItems={"flex-end"}>
                  <Grid item xs>
                    <FormControl variant="outlined" fullWidth>
                      <label className='form-label '>
                        {t('lbl_couponCode')}
                      </label>
                      <CadisTextField
                        autoComplete='false'
                        id="couponCode"
                        name="couponCode"
                        value={values.couponCode}
                        disabled={(!values.numOfSubscriptions || couponStatus)}
                        onChange={handleCouponCodeChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs="auto">
                    <CadisButton size="large" variant="contained" color='primary' disabled={loading || !values.couponCode}
                      onClick={applyCouponCode}>
                      {t('lbl_apply')}
                    </CadisButton>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <Stack>
                      {couponStatus &&
                        <Grid container alignItems="center" className='access-permission-box' width={"100%"} marginLeft={0} columnGap={1} paddingX={2} paddingY={1} sx={{ borderColor: 'success.main' }}>
                          <Grid xs={auto}>
                            <span className='cad-tick-icon text-secondary mr-3'></span>
                          </Grid>
                          <Grid xs>
                            <Typography component="div" variant="body1" alignSelf="flex-start">
                              <strong>{couponCode}</strong>&nbsp;{couponMessage}
                              <Typography variant="body2" color="GrayText">-${discountPrice}</Typography>
                            </Typography>
                          </Grid>
                          <Grid xs={auto}>
                            <CadisButton onClick={handleCouponClear} color="error">{t('lbl_remove')}</CadisButton>
                          </Grid>
                        </Grid>
                      }
                      {(couponMessage && !couponStatus) &&
                        <Typography color='error' marginTop={-0.5} fontSize={'0.75rem'} className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained">
                          {couponMessage}
                        </Typography>
                      }
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
              <Stack spacing={1.5} direction={'column'} paddingX={'20px'} paddingY={3}>
                <Stack spacing={2} direction={'row'} justifyContent={"space-between"}>
                  <Typography variant="body1">
                    Subscription Amount
                  </Typography>
                  <Typography variant="body1" fontWeight={'bold'}>
                    {onlineSubscriptionDetailsMode === FormRenewMode ? ('$' + Number(renewCurrentSubscriptionPlan?.planAmount).toFixed(2)) : ('$' + (formattedPrice))}
                  </Typography>
                </Stack>

                {/* {couponStatus && ( */}
                {/* <> */}
                <Stack spacing={2} direction={'row'} justifyContent={"space-between"}>
                  <Typography variant="body1">
                    Sub Total
                  </Typography>
                  <Typography variant="body1" fontWeight={'bold'}>
                    {onlineSubscriptionDetailsMode === FormRenewMode ? ('$' + (values.renewSubscriptionTotalPrice)) : ('$' + (values.totalPrice))}
                  </Typography>
                </Stack>

                <Stack spacing={2} direction={'row'} justifyContent={"space-between"}>
                  <Typography variant="body1">
                    Discount {discountPercentageNum}
                  </Typography>
                  <Typography variant="body1" fontWeight={'bold'} color={couponStatus ? "success.main" : ''}>
                    -${Number(cleanedDiscountPrice).toFixed(2)}
                  </Typography>
                </Stack>
                <Divider></Divider>

                <Stack spacing={2} direction={'row'} justifyContent={"space-between"}>
                  <Typography variant="h4">
                    Total Payable
                  </Typography>
                  <Typography variant="h4">
                    {totalPayableAmountValue()}
                  </Typography>
                </Stack>

              </Stack>
              <Box sx={{ pt: 2, pb: 2, position: 'sticky', bottom: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }}>
                <Grid container spacing={0} alignItems="center"  >
                  <Grid item xs="auto" >
                    <CadisButton type='submit' disabled={values.numOfSubscriptions === 0 || isNaN(values.numOfSubscriptions)} variant="contained" color="primary">
                      Make Payment
                    </CadisButton>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Grid>
        </Box>
      </Drawer>
    </>
  );
};

export default OrgAdminAddSubscriptionPlan;
