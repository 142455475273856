import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { USER_MUTE, USER_UNMUTE, WEB_ASSISTANT_MUTE, WEB_ASSISTANT_UNMUTE } from '../../Common/helper';
import { useSocketContext } from '../../context/SocketContext';

const ToggleComponent = ({ sfutest, isVideo, socketRef, localVideoStream, recordingMute, setWebAsstSelfMute, }) => {
  const [isMuted, setIsMuted] = useState(false);
  const { sessionIdRef } = useSocketContext();

  const toggleHandler = () => {
    if (isVideo) {
      const videoState = sfutest?.isVideoMuted();
      if (videoState) {
        sfutest?.unmuteVideo();
        localVideoStream.getVideoTracks().forEach(track => {
          track.enabled = true;
        });
      } else {
        sfutest?.muteVideo();
        localVideoStream.getVideoTracks().forEach(track => {
          track.enabled = false;
        });
      }
    } else {
      const audioState = sfutest?.isAudioMuted();
      if (audioState) {
        if (socketRef && socketRef.current) {
          // Access socketRef.current only if it exists
          if (window.location.pathname.includes("webassistantcall")) {
            if (!recordingMute) {
              sfutest?.unmuteAudio();
            }
            setWebAsstSelfMute(false);
          } else {
            sfutest?.unmuteAudio();
            socketRef.current.emit(USER_UNMUTE, sessionIdRef.current);
          }
        }
      } else {
        if (socketRef && socketRef.current) {
          // Access socketRef.current only if it exists
          if (window.location.pathname.includes("webassistantcall")) {
            if (!recordingMute) {
              sfutest?.muteAudio();
            }
            setWebAsstSelfMute(true);
          } else {
            sfutest?.muteAudio();
            socketRef.current.emit(USER_MUTE, sessionIdRef.current);
          }
        }
      }
      setIsMuted(!isMuted);
      if (window.location.pathname.includes("webassistantcall")) {
        if (!isMuted) {
          sessionStorage.setItem("wastSelfMuted", JSON.stringify(true));
          socketRef.current.emit(WEB_ASSISTANT_MUTE, sessionIdRef.current);
        } else if (isMuted) {
          sessionStorage.setItem("wastSelfMuted", JSON.stringify(false));
          socketRef.current.emit(WEB_ASSISTANT_UNMUTE, sessionIdRef.current);
        }
      }
    }
  };

  return (
    <IconButton
      aria-label={isVideo ? 'video' : 'audio'}
      size="small"
      onClick={toggleHandler}
      className="custom-default"
      color="default"
    >
      {isVideo ? (sfutest?.isVideoMuted() ? <VideocamOffIcon /> : <VideocamIcon />) :
        (isMuted ? <MicOffIcon /> : <MicIcon />)
      }
    </IconButton>
  );
};

export default ToggleComponent;
