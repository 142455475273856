import { Iroutes, ROLE } from '../Interfaces/IConstants';
import OraganizationList from "../Pages/Oraganization/OraganizationList";
import ConsultantList from '../Pages/Consultant/ConsultantList';
import AssistantList from '../Pages/Assistants/AssistantsList';
import CadisAdminList from '../Pages/CadisAdmin/CadisAdminList';
import CallSessions from '../Pages/Assistants/CallSessions';
import OraganizationAdminList from '../Pages/OrganizationAdmin/OrganizationAdminList';
import DeletedOrganizationList from '../Pages/Oraganization/DeletedOrganizationList';
import DeletedUserList from '../Pages/User/DeletedUserList';
import SubscriptionPlans from '../Pages/Subscription/SubscriptionPlans';
import OrgSubscriptionDetails from '../Pages/Subscription/OrgSubscriptionDetails';
import MyPlans from '../Pages/Subscription/MyPlans';
import ManageUsers from '../Pages/Subscription/ManageUsers';
import DiscountCouponCode from '../Pages/DiscountCouponCode/DiscountCouponCode';
import DataAddOnLists from '../Pages/DataAddOns/DataAddOnList';

const cadisAdminRoutes: Iroutes[] = [
    {
        path: "Organizations",
        children: <OraganizationList />,
        roles: [ROLE.CadisAdmin],
    },
    {
        path: "cadisAdmin",
        children: <CadisAdminList />,
        roles: [ROLE.CadisAdmin],
    },
    {
        path: "deletedOrganizations",
        children: <DeletedOrganizationList />,
        roles: [ROLE.CadisAdmin],
    },
    {
        path: "deletedUserList/:roleId",
        children: <DeletedUserList />,
        roles: [ROLE.CadisAdmin, ROLE.OrgAdmin],
    },
    {
        path: "subscriptionPlans/:roleId/:id",
        children: <SubscriptionPlans />,
        roles: [ROLE.CadisAdmin, ROLE.OrgAdmin],
    },
    {
        path: "subscriptionDetails",
        children: <OrgSubscriptionDetails />,
        roles: [ROLE.CadisAdmin],
    },
    {
        path: "CouponCode",
        children: <DiscountCouponCode />,
        roles: [ROLE.CadisAdmin],
    },
    {
        path: "DataAddOnsList",
        children: <DataAddOnLists />,
        roles: [ROLE.CadisAdmin],
    },
];

const consultantRoutes: Iroutes[] = [
    {
        path: "orgAdmins",
        children: <OraganizationAdminList />,
        roles: [ROLE.OrgAdmin],
    },
    {
        path: "consultants",
        children: <ConsultantList />,
        roles: [ROLE.Consultant, ROLE.OrgAdmin],
    },
    {
        path: "assistants",
        children: <AssistantList />,
        roles: [ROLE.Consultant, ROLE.OrgAdmin],
    },
    {
        path: "callSessions",
        children: <CallSessions />,
        roles: [ROLE.Consultant, ROLE.OrgAdmin],
    },
    {
        path: "myPlans/:id",
        children: <MyPlans />,
        roles: [ROLE.OrgAdmin],
    },
    {
        path: "myPlans/manageUsers",
        children: <ManageUsers />,
        roles: [ROLE.OrgAdmin],
    }
];

const routes = [...cadisAdminRoutes, ...consultantRoutes];

export default routes;