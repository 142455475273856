import { Box, Checkbox, Container, Divider, Drawer, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Select, Stack, Switch, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AddSubscriptionPlan, GetAllFeatures, UpdateSubscriptionPlan } from '../../Common/api.routes';
import * as Helper from '../../Common/helper';
import { FormAddMode, FormUpdateMode, numberRegex } from '../../Common/helper';
import { getAccuratePrice, validateAndSetMonthPrice, validateToOnedecimalOnly } from '../../Common/helperFunction';
import { subscriptionValidationSchema } from '../../Common/validationSchemas';
import CadisButton from '../../Components/Input/CadisButton';
import CadisTextField from '../../Components/Input/CadisTextField';
import CadisLoader from '../../Components/Loading/CadisLoader';
import apis from '../../HttpConfig/Api';
import { IFeature, ISubscriptionPlan, } from '../../Interfaces/ISubscription';

const AddUpdateSubscriptionPlans = (
    { isOpenAddUpdateSubscriptionPlans, setIsOpenAddUpdateSubscriptionPlans, currentSubscriptionPlans, mode, getSubscriptionPlan, planDuration }
        : {
            isOpenAddUpdateSubscriptionPlans: boolean, setIsOpenAddUpdateSubscriptionPlans: any, currentSubscriptionPlans: ISubscriptionPlan, mode: string, getSubscriptionPlan: any, planDuration: any
        }) => {
    const [loading, setLoading] = useState(false);
    const [features, setFeatures] = useState<IFeature[]>([]);
    const disableSwitchRef = useRef<HTMLElement>(null);
    const [isPlanDisable, setIsPlanDisable] = useState(false);
    const { t } = useTranslation();

    const { values, dirty, handleReset, handleSubmit, handleChange, touched, errors, setFieldValue, resetForm, setErrors } = useFormik({
        initialValues: {
            planId: currentSubscriptionPlans.sub_PlanId ?? 0,
            planName: currentSubscriptionPlans.sub_name,
            planDuration: currentSubscriptionPlans.sub_billing_cycle.length === 0 ? planDuration[0] : currentSubscriptionPlans.sub_billing_cycle,
            userCount: currentSubscriptionPlans.sub_min_user_bundle_count,
            oneMonthPrice: mode === Helper.FormUpdateMode ? getAccuratePrice(parseFloat(currentSubscriptionPlans.sub_one_month_price.toString())) : currentSubscriptionPlans.sub_one_month_price,
            totalPrice: mode === Helper.FormUpdateMode ? getAccuratePrice(parseFloat(currentSubscriptionPlans.sub_total_price.toString())) : currentSubscriptionPlans.sub_total_price,
            notifyExpiry: currentSubscriptionPlans.sub_remind_before,
            data_limit: currentSubscriptionPlans.is_data_unlimited ? "" : mode === FormAddMode ? "" : parseFloat(currentSubscriptionPlans.data_limit.toString()),
            is_data_unlimited: currentSubscriptionPlans.is_data_unlimited,
            is_plan_disable: currentSubscriptionPlans.is_plan_disable,
        },
        validationSchema: subscriptionValidationSchema,
        enableReinitialize: true,
        onSubmit: async () => {
            AddUpdateSubscriptionApiCall()
            setLoading(true);
        },
    });
    const onFormSubmitComplete = (resp: any) => {
        if (resp.data.success) {
            handleReset(values);
            setIsOpenAddUpdateSubscriptionPlans(false);
            setIsPlanDisable(false);
            toast.success(`${mode === FormAddMode ? t('lbl_planCreated') : t('lbl_planupdated')} ${t('lbl_successfully')}`);
            getSubscriptionPlan();
            setLoading(false);
        } else {
            if (resp.data.error === "plan name is already exist") {
                toast.error("Plan name is already exists.");
            } else {
                toast.error(resp.data.error)
            }
            setLoading(false);
        }
    }
    const AddUpdateSubscriptionApiCall = async () => {
        const planName = `${values.planName.charAt(0).toUpperCase()}${values.planName.slice(1)}`;
        const SubscriptionData = {
            'plan_id': values.planId,
            'name': planName,
            'billing_cycle': values.planDuration,
            'max_user_bundle_count': values.userCount,
            'one_month_price': values.oneMonthPrice,
            'total_price': values.totalPrice,
            'remind_before': values.notifyExpiry,
            'data_limit': values.is_data_unlimited ? 0 : values.data_limit,
            'is_data_unlimited': values.is_data_unlimited,
            "is_plan_disabled": mode === "add" ? true : values.is_plan_disable
        }
        try {
            if (mode === Helper.FormAddMode) {
                await apis.instance.post(`${AddSubscriptionPlan}`, SubscriptionData)
                    .then((resp: any) => {
                        onFormSubmitComplete(resp);
                    })
                    .finally(() => setLoading(false));
            }
            else if (mode === Helper.FormUpdateMode) {
                await apis.instance.put(`${UpdateSubscriptionPlan}`, SubscriptionData)
                    .then((resp: any) => {
                        onFormSubmitComplete(resp);
                    })
                    .finally(() => setLoading(false));
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(t('responseMsg.msg_wrongEntry'));
            console.log(error.message);
        }
    }

    useEffect(() => {
        apis.instance.get(`${GetAllFeatures}`, {
        })
            .then(response => {
                setFeatures(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
            });
    }, []);

    const handlePriceChange = async (e: any) => {
        validateAndSetMonthPrice(e.target.value, 'oneMonthPrice', mode, setFieldValue)
        handleOneMonthPriceChange(e);
    };
    const handleDataLimitChange = async (e: any) => {
        const regex = /^(1(\.0*)?|[2-9]\d*(\.\d*)?|[1-9]\d*(\.\d*)?)$/;
        if (regex.test(e.target.value) || e.target.value === "") {
            validateToOnedecimalOnly(e.target.value, 'data_limit', mode, setFieldValue);
        }
    };


    const handlePlanDurationChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        const selectedDuration = e.target.value as string;
        updateTotalPrice(selectedDuration, values.oneMonthPrice);
    };

    const handleOneMonthPriceChange = (e: any) => {
        validateAndSetMonthPrice(e.target.value, 'oneMonthPrice', mode, setFieldValue);
        updateTotalPrice(values.planDuration, e.target.value);
    };

    const updateTotalPrice = (selectedDuration: string, oneMonthPrice: string | number) => {
        if (oneMonthPrice === '' || oneMonthPrice === '0' || oneMonthPrice === '.' || oneMonthPrice === '..' || selectedDuration === 'Select Plan Duration') {
            setFieldValue('totalPrice', 0);
            return;
        }
        let priceAsString: string;
        if (typeof oneMonthPrice === 'string' && oneMonthPrice.includes('.')) {
            const [integerPart, decimalPart] = oneMonthPrice.split('.');
            const truncatedDecimalPart = decimalPart.slice(0, 2);
            priceAsString = `${integerPart}.${truncatedDecimalPart}`;
        } else {
            priceAsString = oneMonthPrice.toString();
        }
        const priceAsNumber = parseFloat(priceAsString);
        const calculatedTotalPrice = parseInt(selectedDuration) * priceAsNumber;
        setFieldValue('totalPrice', getAccuratePrice(parseFloat(calculatedTotalPrice.toString())));
    };

    const handlePlanDuration = (e: any) => {
        handlePlanDurationChange(e);
        handleChange(e);
    }

    useEffect(() => {
        if (mode !== Helper.FormUpdateMode)
            updateTotalPrice(values.planDuration, values.oneMonthPrice);
        setTimeout(() => {
            if ((!currentSubscriptionPlans.is_plan_disable && disableSwitchRef.current)) {
                disableSwitchRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);
    }, [isOpenAddUpdateSubscriptionPlans]);

    return (
        <>
            <Drawer
                anchor='right'
                open={isOpenAddUpdateSubscriptionPlans}
                onClose={() => { return null }}
            >
                <Box role="presentation">
                    <Box sx={{ pt: 1.5, position: 'sticky', top: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }} >
                        <Container sx={{ alignItems: 'center', display: 'flex' }} disableGutters>
                            <Box sx={{ width: '40px', height: '40px', flexGrow: 1, display: { xs: 'flex', pr: 2 } }}>
                                <IconButton onClick={() => {
                                    resetForm()
                                    setIsOpenAddUpdateSubscriptionPlans(false)
                                    setIsPlanDisable(false);
                                }} sx={{}} className='c-pointer'>
                                    <span className='cad-close'></span>
                                </IconButton>
                            </Box>
                        </Container>
                        <Box sx={{ mb: 2, pb: 2, mt: 2, display: 'flex' }}>
                            <Typography className="mt-0 mb-0 slide-right-title" sx={(theme) => theme.typography.h5}>{mode === FormAddMode ? t('lbl_crateNewPlan') : t('lbl_updatePlan')} </Typography>
                        </Box>
                    </Box>
                    <Grid container width={'100%'}>
                        <form onSubmit={handleSubmit} onReset={handleReset} style={{ width: '100%' }}>
                            <Stack spacing={2} direction={'column'} paddingX={'20px'}>
                                <FormControl variant="outlined">
                                    <label className='form-label'>
                                        {t('lbl_planName')}
                                        <span className='mandatory-star'></span>
                                    </label>
                                    <CadisTextField
                                        autoComplete='false'
                                        id="planName"
                                        name="planName"
                                        value={values.planName.trimStart()}
                                        onChange={handleChange}
                                        error={touched.planName && Boolean(errors.planName)}
                                        helperText={touched.planName && t(`${errors.planName ? errors.planName : ''}`)}
                                    />
                                </FormControl>
                                <FormControl variant="outlined" size="small">
                                    <label className='form-label'>
                                        {t('lbl_planDuration')}
                                        <span className='mandatory-star'></span>
                                    </label>
                                    <Select fullWidth
                                        labelId='planDuration'
                                        id="planDuration"
                                        name='planDuration'
                                        value={values.planDuration}
                                        onChange={handlePlanDuration}
                                    >
                                        {planDuration.map((title: string, index: number) => { return <MenuItem value={title} key={index}>{title}</MenuItem> })}
                                    </Select>
                                    {errors.planDuration && touched.planDuration ? (
                                        <Typography variant="caption" color="error">
                                            {t(errors.planDuration as string | TemplateStringsArray)}
                                        </Typography>) : null}
                                </FormControl>
                                <FormControl variant="outlined">
                                    <label className='form-label'>
                                        {t('lbl_userCount')}
                                        <span className='mandatory-star'></span>
                                    </label>
                                    <CadisTextField
                                        autoComplete='false'
                                        id="userCount"
                                        name="userCount"
                                        value={values.userCount}
                                        onChange={async (e: any) => {
                                            const inputValue = e.currentTarget.value;
                                            const trimmedValue = inputValue.trim();
                                            if (trimmedValue.includes('.'))
                                                return;
                                            if (trimmedValue.length < 1) {
                                                setFieldValue("userCount", trimmedValue);
                                            } else if (numberRegex.test(trimmedValue) && trimmedValue[0] !== '0') {
                                                setFieldValue("userCount", trimmedValue);
                                            }
                                        }}
                                        error={touched.userCount && Boolean(errors.userCount)}
                                        helperText={touched.userCount && t(`${errors.userCount ? errors.userCount : ''}`)}
                                    />
                                </FormControl>
                                <FormControl variant="outlined" size="small">
                                    <label className='form-label'>
                                        {t('lbl_priceForSubscriptionPlan')}
                                        <span className='mandatory-star'></span>
                                    </label>
                                    <CadisTextField
                                        autoComplete='false'
                                        id="oneMonthPrice"
                                        name="oneMonthPrice"
                                        value={values.oneMonthPrice}
                                        onChange={handlePriceChange}
                                        error={touched.oneMonthPrice && Boolean(errors.oneMonthPrice)}
                                        helperText={touched.oneMonthPrice && t(`${errors.oneMonthPrice ? errors.oneMonthPrice : ''}`)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" sx={{ fontSize: '1.2rem' }}>
                                                    <span className='cad-dollar'></span>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                                <FormControl variant="outlined" size="small">
                                    <label className='form-label'>
                                        {t('lbl_totalPriceForSubscriptionPlan')}
                                    </label>
                                    <CadisTextField
                                        autoComplete='false'
                                        id="totalPrice"
                                        name="totalPrice"
                                        value={values.totalPrice}
                                        onChange={handlePriceChange}
                                        disabled
                                        type='number'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" sx={{ fontSize: '1.2rem' }}>
                                                    <span className='cad-dollar'></span>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>

                                <FormControl variant="outlined" size="small">
                                    <label className='form-label'>
                                        {t('lbl_dataUsageForSubscriptionPlan')}
                                        <span className='mandatory-star'></span>
                                    </label>
                                    <span className='mandatory-star'></span>
                                    <CadisTextField
                                        autoComplete='false'
                                        id="data_limit"
                                        name="data_limit"
                                        error={touched.data_limit && Boolean(errors.data_limit)}
                                        helperText={touched.data_limit && t(`${errors.data_limit ? errors.data_limit : ''}`)}
                                        value={values.data_limit}
                                        onChange={handleDataLimitChange}
                                        disabled={values.is_data_unlimited}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" sx={{ fontSize: '1.2rem' }}>
                                                    GB
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>

                                <Divider>OR</Divider>

                                <FormControl sx={{ marginTop: '0 !important' }}>
                                    <FormControlLabel control={
                                        <Checkbox id='isDataUnlimited' name='isDataUnlimited'
                                            value={values.is_data_unlimited}
                                            checked={values.is_data_unlimited}
                                            onChange={(event: any, checked: boolean) => {
                                                setFieldValue('is_data_unlimited', checked)
                                            }} />} label={t('lbl_isDataUnlimited')} />
                                </FormControl>

                                <FormControl variant="outlined" size="small">
                                    <label className='form-label '>
                                        {t('lbl_notifyExpiry')}
                                        <span className='mandatory-star'></span>
                                    </label>
                                    <Select fullWidth
                                        labelId='notifyExpiry'
                                        id="notifyExpiry"
                                        name='notifyExpiry'
                                        value={values.notifyExpiry}
                                        onChange={handleChange}
                                    >
                                        {Helper.NotifyExpiry.map((title, index) => { return <MenuItem value={title} key={index}>{title}</MenuItem> })}
                                    </Select>
                                    {errors.notifyExpiry && touched.notifyExpiry ? (
                                        <Typography variant="caption" color="error">
                                            {t(errors.notifyExpiry)}
                                        </Typography>) : null}
                                </FormControl>
                                {mode !== 'add' ?
                                    <FormControl>
                                        <label className='form-label '>
                                            {t('lbl_planDisable')}
                                        </label>
                                        <Box ref={disableSwitchRef} className='access-permission-box' sx={{ px: 2, py: 1 }}>
                                            < FormControlLabel control={
                                                <Switch color='success' id="is_plan_disable" name="is_plan_disable" checked={Boolean(values.is_plan_disable)} onChange={(event: any, checked: boolean) => {
                                                    setFieldValue("is_plan_disable", checked)
                                                    setIsPlanDisable(!checked)
                                                }} />} label={`${values.is_plan_disable ? t('lbl_disable') : t('lbl_enable')}${t('lbl_subscriptionPlan')}`} />

                                            {(isPlanDisable && currentSubscriptionPlans.total_org_count === 0) ?
                                                <div className='helper-text'>
                                                    {t('lbl_disablePlanWarning')}
                                                </div> : <div className='helper-text'>{
                                                    isPlanDisable && currentSubscriptionPlans.total_org_count > 0 ? <>
                                                        {t("lbl_planPurchased")} {currentSubscriptionPlans.total_org_count}
                                                        {currentSubscriptionPlans.total_org_count === 1 ? t("lbl_organizationDot") : t("lbl_organizationsDot")}  <br />{t("lbl_disablePlanConfirmation")}
                                                    </> : ""
                                                } </div>}
                                        </Box>
                                    </FormControl> : null}
                            </Stack>
                            <Box sx={{ pt: 2, pb: 2, position: 'sticky', bottom: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }}>
                                <Grid container spacing={0} alignItems="center"  >
                                    <Grid item xs="auto" >
                                        <CadisButton type='submit' variant="contained" color='primary' disabled={mode === 'update' && !dirty || loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                                            {mode === 'add' ? t('lbl_create') : t('lbl_update')}
                                        </CadisButton>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Box sx={{ pl: 1 }}>
                                            <CadisButton type='reset' variant="contained" color='error' disabled={mode === 'update' && !dirty || loading}>
                                                {mode === FormUpdateMode ? `${t('lbl_reset')}` : `${t('lbl_clear')}`}
                                            </CadisButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    </Grid>
                </Box>
            </Drawer>
        </>
    )
}

export default AddUpdateSubscriptionPlans;