import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CadisButton from '../../Components/Input/CadisButton';
import { useTranslation } from "react-i18next";

interface DataExhaustedDialogProps {
    isOpen: boolean;
    handleDataExhaustedPopup?: () => void;
    dataFinished?: boolean;
    dataExhausted?: boolean;
    asstDataFinished?: boolean;
}

const DataExhaustedDialog: React.FC<DataExhaustedDialogProps> = ({
    isOpen,
    handleDataExhaustedPopup,
    dataFinished,
    dataExhausted,
    asstDataFinished,
}) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={isOpen}
            onClose={() => { return null }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {isOpen ? <DialogTitle id="alert-dialog-title" color="error">
                {dataFinished || asstDataFinished ? 'Data Limit Exceeded' : t('lbl_warning')}
            </DialogTitle> : ""}
            <DialogContent>
                {/* data below 10% */}
                {dataExhausted && t('lbl_dataLimitReached')}

                {/* data zero */}
                {dataFinished && t('lbl_dataExhaustedInitiateCall')}

                {/* Assistant data zero */}
                {asstDataFinished && t('lbl_asstDifferentSubscritpionDataExhausted')}
            </DialogContent>
            <DialogActions>
                <CadisButton variant="contained" color="primary" onClick={handleDataExhaustedPopup}>OK</CadisButton>
            </DialogActions>
        </Dialog>
    );
};

export default DataExhaustedDialog;