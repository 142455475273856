import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTimer } from "../../Hooks/useTimerHook";
import { useSocketContext } from "../../context/SocketContext";
import Janus from "../../janus-client/janus";
// ICONS
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import { webAssistantSubscriptionDataUsage } from "../../Common/api.routes";
import {
  CALL_USER_LEFT,
  CLEAR_SCREEN,
  CONSULTANT_END_CALL,
  END_CALL,
  LEAVE_CALL,
  MUTE_YOURSELF,
  OFF_AUDIO_TO_WEB_AST,
  ON_AUDIO_TO_WEB_AST,
  RECORDING_ENDED,
  RECORDING_STARTED,
  ROOM_DESTROYED,
  UNMUTE_YOURSELF,
  USER_MUTE,
  WAST_CALL_TRANSFERRED,
  WEB_AST_CALL_INITIATION,
} from "../../Common/helper";
import CadisUnmuteIcon from "../../Components/CustomUnmuteIcon/CadisUnmuteIcon";
import CadisDialog from "../../Components/Dialog/CadisDialog";
import CadisButton from "../../Components/Input/CadisButton";
import preventBackNavigation from "../../Components/NavigationBackDialog/PreventBackNavigation";
import useIsMobileDevice from "../../Hooks/useIsMobileDevice";
import apis from "../../HttpConfig/Api";
import ToggleComponent from "./ToggleComponent";

// video call essential variables
var janus = null;
var sfutest = null;
var opaqueId = "videoroom-" + Janus.randomString(12);
var myusername = null;
var myid = null;
var mystream = null;
var zoomLevel = 100;

// We use this other ID just to map our subscriptions to us
var mypvtid = null;
var localTracks = {};
var localVideos = 0;
var feeds = [];
var feedStreams = {};
var bitrateTimer = [];
let bitrateTimeout;
var isSelfSecondary = true;

// recording variables
let mediaRecorder;

//data consumption variables
let prevBytesSent = 0;
var consultantIntervalId = null;
var consDataReceived = 0;
var dataSent = 0;
var wastIntervalDataSent = 0;
var consIntervalDataReceived = 0;
var timeoutId;

const WebAssistantCallScreen = () => {
  const {
    socketRef,
    janusURL,
    failedPong,
    callToAssistantRef,
    webAsstRoomDetails,
    sessionIdRef,
    roomData,
    isMuteConsultant,
    endCallDialog,
    setRoomDetails,
    setInCommingCall,
    setInviteDialog,
    setJanusURL,
    setCallToAssistant,
    setUserDetails,
    openInviteDialog,
    openReconnectDialog,
    setIsConferenceCall,
    openEndCallPopup,
    setEndCallDialog,
  } = useSocketContext(); // socket context custom hook
  const { seconds, minutes, hours, handleStart, handleReset } = useTimer(); // timer custom hook
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const { navigationBackPopup, setNavigationBackPopup } =
    preventBackNavigation("/webAssistantJoin");

  // Refs for video and canvas elements
  const remoteVideoRef = useRef();
  const remoteAudioRef = useRef();
  const localVideoRef = useRef();

  // State variables
  const [isRemoteVideo, setIsRemoteVideo] = useState(false);
  const [isPluginAttached, setIsPluginAttached] = useState(false);
  const [remoteAudioTrack, setRemoteAudioTrack] = useState();
  const [localAudioTrack, setLocalAudioTrack] = useState();
  const [userLeftAlert, setUserLeftAlert] = useState(false);
  const [showMuteIcon, setShowMuteIcon] = useState(false);
  const [container1, setContainer1] = useState("container1");
  const [container2, setContainer2] = useState("container2");
  const [recordingStarted, setRecordingStarted] = useState(false);
  const [recordingMute, setRecordingMute] = useState(false);
  const [localVideoStream, setLocalVideoStream] = useState(null)
  const [webAsstSelfMute, setWebAsstSelfMute] = useState(
    sessionStorage.getItem("wastSelfMuted") === "true"
  );
  const [dataUsageTimer, setDataUsageTimer] = useState(0);
  const [isFrontCamera, setIsFrontCamera] = useState(true);

  const isMobileDevice = useIsMobileDevice();

  const onJoinCall = () => {
    const janusURL = state.janusURL;
    if (janusURL != null) {
      setJanusURL(janusURL);
    }
    let callSession1 = state.callSessionId;
    if (!socketRef.current && apis.socketURL) {
      socketRef.current = io(apis.socketURL);
    }
    socketRef.current?.emit(WEB_AST_CALL_INITIATION, {
      webAssistantName: sessionStorage.getItem("webAssistantName"),
      sessionId: callSession1,
    });
  };

  const RejoinWast = () => {
    const SessionId = sessionStorage.getItem("WastSessionId");
    let callSession = JSON.parse(SessionId);
    navigate("/webAssistantJoin/" + callSession);
  };

  useEffect(() => {
    setWebAsstSelfMute(sessionStorage.getItem("wastSelfMuted") === "true");
  }, [webAsstSelfMute]);

  useEffect(() => {
    //Commented code required for reference
    //const getRoom = sessionStorage.getItem("Room_Id");
    // if (getRoom != null) {
    //   onJoinCall();
    // }

    // storing wast session id in sessionstorage
    if (state?.callSessionId?.length > 0 && state?.callSessionId != undefined) {
      sessionStorage.setItem(
        "WastSessionId",
        JSON.stringify(state.callSessionId)
      );
    }

    if (state != null) {
      let callSession = state.callSessionId;
      socketRef.current?.on(WAST_CALL_TRANSFERRED, async () => {
        if (mediaRecorder?.state === "recording") {
          mediaRecorder?.stop();
        }
        await sfutest?.hangup();
        await janus?.destroy();
        handleReset();
        navigate("/webAssistantJoin/" + callSession);
        toast.warning(t("responseMsg.msg_sessionExpired"));
      });
    }

    if (state == null) {
      RejoinWast();
    }

    // socket event on if glass user end call
    socketRef.current?.on(ROOM_DESTROYED, async () => {
      if (mediaRecorder?.state === "recording") {
        mediaRecorder?.stop();
      }
      await sfutest?.hangup();
      await janus?.destroy();
      handleReset();
      setEndCallDialog(true);
    });

    socketRef.current?.on(CALL_USER_LEFT, () => {
      // canvas.clear();
      if (mediaRecorder?.state === "recording") {
        mediaRecorder.stop();
      }
      setRecording(false);
      setUserLeftAlert(true);
      setIsRemoteVideo(false);
    });

    socketRef.current?.on(CONSULTANT_END_CALL, async () => {
      if (mediaRecorder?.state === "recording") {
        mediaRecorder?.stop();
      }
      localVideoRef.current.srcObject = null;
      setLocalVideoStream(null);
      await sfutest?.hangup();
      await janus?.destroy();
      handleReset();
      setEndCallDialog(true);
    });

    socketRef.current?.on(CLEAR_SCREEN, () => {
      // canvas.clear();
    });

    // listining the consultant audio only when web assistant is on main view in consultant call screen.
    socketRef.current?.on(ON_AUDIO_TO_WEB_AST, (data) => {
      const audioElement = document.getElementById("consultantAudio");
      if (audioElement) {
        audioElement.muted = false;
      }
    });

    // whenever web assistant is not on main view of consultant call screen, we are preventing consultant audio.
    socketRef.current?.on(OFF_AUDIO_TO_WEB_AST, (data) => {
      const audioElement = document.getElementById("consultantAudio");
      if (audioElement) {
        audioElement.muted = true;
      }
    });

    socketRef.current?.on(RECORDING_STARTED, () => {
      setRecordingStarted(true);
    });

    socketRef.current?.on(RECORDING_ENDED, () => {
      setRecordingStarted(false);
    });

    const username = JSON.parse(sessionStorage.getItem("username"));
    let userRole = parseInt(JSON.parse(sessionStorage.getItem("role")));
    setUserDetails({ username: username, userrole: userRole });
    if (!socketRef.current && userRole === 3 && apis.socketURL) {
      socketRef.current = io(apis.socketURL);
    }
    return () => {
      setInCommingCall(false);
      setRoomDetails(null);
      setJanusURL(null);
      setCallToAssistant({ open: false, UserID: null, AssistantName: "" });
      zoomLevel = 100;
      callToAssistantRef.current = {
        open: false,
        UserID: null,
        AssistantName: "",
      };
      socketRef.current?.off(ROOM_DESTROYED);
      socketRef.current?.off(CALL_USER_LEFT);
      socketRef.current?.off(WAST_CALL_TRANSFERRED);
      if (mediaRecorder?.state === "recording") {
        mediaRecorder.stop();
      }
      clearTimeout(bitrateTimeout);
      bitrateTimeout = null;
      // canvas.clear();
      // canvasTxtRef.current = null;
    };
  }, [socketRef.current]);

  useEffect(() => {
    // If roomId exists, initialize Janus client
    if (webAsstRoomDetails?.roomId && !isPluginAttached) {
      Janus.init({
        debug: "all",
        callback: function () {
          janusStart(janusURL, webAsstRoomDetails.turnServer);
        },
      });
    }

    // If roomId exists and Janus plugin is attached, join Janus room
    if (webAsstRoomDetails?.roomId && isPluginAttached) {
      try {
        var join = {
          request: "join",
          room: webAsstRoomDetails.roomId,
          ptype: "publisher",
          display: "webassistant",
        };
        sfutest?.send({
          message: join,
          success: (data) => { },
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [webAsstRoomDetails, isPluginAttached, janusURL]);

  // useEffect for display network status
  useEffect(() => {
    if (failedPong === 2) toast.warning("connection is unstable");
    if (failedPong === 5) {
      toast.error("connection lost with server. Please try after some time.");
      navigate("/dashboard/assistants");
    }
  }, [failedPong, navigate]);

  useEffect(() => {
    timeoutId = setInterval(() => {
      dataConsumption(dataSent, false);
      setDataUsageTimer(dataUsageTimer + 1);
    }, 120000);
  }, []);

  useEffect(() => {
    socketRef.current?.on(MUTE_YOURSELF, () => {
      sfutest?.muteAudio();
      setRecordingMute(true);
      setRecordingStarted(false);
    });
    socketRef.current?.on(UNMUTE_YOURSELF, () => {
      const selfWastMuted = sessionStorage.getItem("wastSelfMuted") === "true";
      if (!selfWastMuted) {
        sfutest?.unmuteAudio();
      }
      setRecordingMute(false);
      setRecordingStarted(true);
    });
  }, [recordingMute]);

  var acodec = null;
  var vcodec = null;
  var doDtx = false;
  var subscriber_mode = false;
  var use_msid = false;

  const janusStart = (server, turnServer) => {
    // calls the Janus function in janus.js file
    janus = new Janus({
      server: server,
      echo_cancellation: true,
      iceServers: turnServer,
      iceTransportPolicy: "all", // It should work with relay
      success: () => {
        // attach video room plugin
        janus.attach({
          plugin: "janus.plugin.videoroom",
          opaqueId: opaqueId,
          success: (pluginHandle) => {
            // after successfully attached it return pluginHandle
            sfutest = pluginHandle;
            setIsPluginAttached(true);
            // Janus.log(
            //   "Plugin attached! (" +
            //     sfutest.getPlugin() +
            //     ", id=" +
            //     sfutest.getId() +
            //     ")"
            // );
          },
          error: (error) => {
            console.error("  -- Error attaching plugin...", error);
          },
          iceState: function (state) {
            Janus.log("ICE state changed to " + state);
          },
          mediaState: function (medium, on, mid) {
            Janus.log(
              "Janus " +
              (on ? "started" : "stopped") +
              " receiving our " +
              medium +
              " (mid=" +
              mid +
              ")"
            );
          },
          slowLink: function (uplink, lost, mid) {
            Janus.warn(
              "Janus reports problems " +
              (uplink ? "sending" : "receiving") +
              " packets on mid " +
              mid +
              " (" +
              lost +
              " lost packets)"
            );
          },
          onmessage: function (msg, jsep) {
            Janus.debug(" ::: Got a message (publisher) :::", msg);
            var event = msg["videoroom"];
            if (event) {
              if (event === "joined") {
                myid = msg["id"];
                mypvtid = msg["private_id"];
                if (subscriber_mode) {
                } else {
                  publishOwnFeed(true);
                }
                if (msg["publishers"].length !== 0) {
                }
                // Any new feed to attach to?
                if (msg["publishers"]) {
                  var list = msg["publishers"];
                  for (var f in list) {
                    if (list[f]["dummy"]) continue;
                    var id = list[f]["id"];
                    let streams = list[f]["streams"];
                    var display = list[f]["display"];
                    for (var i in streams) {
                      var stream = streams[i];
                      stream["id"] = id;
                      stream["display"] = display;
                    }
                    feedStreams[id] = streams;
                    newRemoteFeed(id, display, streams);
                  }
                }
              } else if (event === "destroyed") {
                // The room has been destroyed
              } else if (event === "event") {
                // Any info on our streams or a new feed to attach to?
                if (msg["streams"]) {
                  var streams = msg["streams"];
                  for (let i in streams) {
                    let stream = streams[i];
                    stream["id"] = myid;
                    stream["display"] = myusername;
                  }
                  feedStreams[myid] = streams;
                } else if (msg["publishers"]) {
                  let list = msg["publishers"];
                  for (let f in list) {
                    if (list[f]["dummy"]) continue;
                    let id = list[f]["id"];
                    let display = list[f]["display"];
                    let streams = list[f]["streams"];
                    for (let i in streams) {
                      let stream = streams[i];
                      stream["id"] = id;
                      stream["display"] = display;
                    }
                    feedStreams[id] = streams;
                    newRemoteFeed(id, display, streams);
                  }
                } else if (msg["leaving"]) {
                  // One of the publishers has gone away?
                  var leaving = msg["leaving"];
                  // Janus.log("Publisher left: " + leaving);
                  // toast.warning('User has been left');
                  var remoteFeed = null;
                  for (let i = 1; i < 6; i++) {
                    if (feeds[i] && feeds[i].rfid === leaving) {
                      remoteFeed = feeds[i];
                      break;
                    }
                  }
                  if (remoteFeed) {
                    feeds[remoteFeed.rfindex] = null;
                    remoteFeed.detach();
                  }
                  delete feedStreams[leaving];
                } else if (msg["unpublished"]) {
                  // One of the publishers has unpublished?
                  var unpublished = msg["unpublished"];
                  // Janus.log("Publisher left: unpublished " + unpublished);
                  if (unpublished === "ok") {
                    // That's us
                    sfutest.hangup();
                    return;
                  }
                  let remoteFeed = null;
                  for (let i = 1; i < 6; i++) {
                    if (feeds[i] && feeds[i].rfid === unpublished) {
                      remoteFeed = feeds[i];
                      break;
                    }
                  }
                  if (remoteFeed) {
                    feeds[remoteFeed.rfindex] = null;
                    remoteFeed.detach();
                  }
                  delete feedStreams[unpublished];
                } else if (msg["error"]) {
                  if (msg["error_code"] === 426) {
                    // This is a "no such room" error: give a more meaningful description
                  } else {
                    console.error(msg["error"]);
                  }
                }
              }
            }
            if (jsep) {
              Janus.debug("Handling SDP as well...", jsep);
              // set answerSDP to remoteSDP
              sfutest.handleRemoteJsep({ jsep: jsep });
              // Check if any of the media we wanted to publish has
              // been rejected (e.g., wrong or unsupported codec)
              var audio = msg["audio_codec"];
              if (
                mystream &&
                mystream.getAudioTracks() &&
                mystream.getAudioTracks().length > 0 &&
                !audio
              ) {
                // Audio has been rejected
              }
              var video = msg["video_codec"];
              if (
                mystream &&
                mystream.getVideoTracks() &&
                mystream.getVideoTracks().length > 0 &&
                !video
              ) {
                // Video has been rejected
                // Hide the webcam video
              }
            }
          },
          onlocaltrack: async function (track, on) {
            // We use the track ID as name of the element, but it may contain invalid characters
            var trackId = track.id.replace(/[{}]/g, "");
            if (!on) {
              // Track removed, get rid of the stream and the rendering
              var stream = localTracks[trackId];
              if (stream) {
                try {
                  var tracks = stream.getTracks();
                  for (var i in tracks) {
                    var mst = tracks[i];
                    if (mst !== null && mst !== undefined) mst.stop();
                  }
                } catch (e) { }
              }
              if (track.kind === "video") {
                localVideos--;
                if (localVideos === 0) {
                  // No video, at least for now: show a placeholder
                }
              }
              delete localTracks[trackId];
              return;
            }

            // If we're here, a new track was added
            var stream = localTracks[trackId];
            if (stream) {
              // We've been here already
              return;
            }

            // append mute button functionality remaining line 321- 329
            if (track.kind === "audio") {
              setLocalAudioTrack(track);
              let audioStream = new MediaStream([track]);
              localTracks[trackId] = audioStream;
              if (localVideos === 0) {
                // No video, at least for now: show a placeholder
              }
            } else if (track.kind === "video") {
              // Handle video track
              localVideos++;
              let videoStream = new MediaStream([track]);
              localTracks[trackId] = videoStream;
              if (localVideoRef.current) {
                localVideoRef.current.srcObject = videoStream;
              }
            }
            if (
              sfutest.webrtcStuff.pc.iceConnectionState !== "completed" &&
              sfutest.webrtcStuff.pc.iceConnectionState !== "connected"
            ) {
              //publishing
            }
          },
        });
      },
      error: function (error) {
        Janus.error(error);
        console.error(error);
      },
      destroyed: function () {
        console.log("janus destroyed");
        clearInterval(consultantIntervalId);
      },
    });
  };

  function publishOwnFeed(useAudio, videoDeviceId = null) {
    let tracks = [];
    if (useAudio) tracks.push({ type: "audio", capture: true, recv: false });
    tracks.push({
      type: "video",
      capture: true,
      recv: false,
      simulcast: true,
    });
    sfutest.createOffer({
      tracks: tracks,
      customizeSdp: function (jsep) {
        // If DTX is enabled, munge the SDP
        if (doDtx) {
          jsep.sdp = jsep.sdp.replace(
            "useinbandfec=1",
            "useinbandfec=1;usedtx=1"
          );
        }
      },
      success: function (jsep) {
        Janus.debug("Got publisher SDP!", jsep);
        var publish = { request: "configure", audio: useAudio, video: true };
        if (acodec) publish["audiocodec"] = acodec;
        if (vcodec) publish["videocodec"] = vcodec;
        sfutest.send({ message: publish, jsep: jsep });
        calculateBitrate();
      },
      error: function (error) {
        Janus.error("WebRTC error:", error);
        if (useAudio) {
          publishOwnFeed(false, videoDeviceId);
        } else {
          console.error("WebRTC error... " + error.message);
        }
      },
    });
  }

  const calculateBitrate = () => {
    setInterval(() => {
      // Get WebRTC statistics
      const pc = sfutest.webrtcStuff.pc;
      if (!pc) {
        return;
      }
      pc.getStats(null)
        .then((stats) => {
          // Iterate through stats to find the bitrate
          stats.forEach((report) => {
            if (report.type === "outbound-rtp") {
              const bytesSent = report.bytesSent;
              if (bytesSent >= prevBytesSent) {
                const bytesSentDelta = bytesSent - prevBytesSent;
                dataSent = (prevBytesSent + bytesSentDelta) / (1024 * 1024);
                prevBytesSent = bytesSent;
              } else {
                // Handle unexpected decrease in bytesSent (optional)
                console.warn("Unexpected decrease in bytesSent");
              }
            }
          });
        })
        .catch((error) => {
          console.error("Failed to get WebRTC statistics:", error);
        });
    }, 1000);
  };

  // another participant feed display on web
  function newRemoteFeed(id, display, streams) {
    // handleStart();
    var remoteFeed = null;
    if (!streams) streams = feedStreams[id];
    janus.attach({
      plugin: "janus.plugin.videoroom",
      opaqueId: opaqueId,
      success: function (pluginHandle) {
        remoteFeed = pluginHandle;
        remoteFeed.remoteTracks = {};
        remoteFeed.remoteVideos = 0;
        remoteFeed.simulcastStarted = true;
        // Prepare the streams to subscribe to, as an array: we have the list of
        // streams the feed is publishing, so we can choose what to pick or skip
        var subscription = [];
        for (var i in streams) {
          var stream = streams[i];
          // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
          if (
            stream.type === "video" &&
            Janus.webRTCAdapter.browserDetails.browser === "safari" &&
            (stream.codec === "vp9" ||
              (stream.codec === "vp8" && !Janus.safariVp8))
          ) {
            continue;
          }
          subscription.push({
            feed: stream.id, // This is mandatory
            mid: stream.mid, // This is optional (all streams, if missing)
          });
          // FIXME Right now, this is always the same feed: in the future, it won't
          remoteFeed.rfid = stream.id;
          remoteFeed.rfdisplay = escapeXmlTags(stream.display);
        }
        // We wait for the plugin to send us an offer
        var subscribe = {
          request: "join",
          room: parseInt(webAsstRoomDetails.roomId),
          ptype: "subscriber",
          streams: subscription,
          use_msid: use_msid,
          private_id: mypvtid,
        };
        remoteFeed.send({ message: subscribe });
      },
      error: function (error) {
        Janus.error("  -- Error attaching plugin...", error);
      },
      iceState: function (state) {
        Janus.log(
          "ICE state (feed #" + remoteFeed.rfindex + ") changed to " + state
        );
      },
      webrtcState: function (on) {
        Janus.log(
          "Janus says this WebRTC PeerConnection (feed #" +
          remoteFeed.rfindex +
          ") is " +
          (on ? "up" : "down") +
          " now"
        );
      },
      slowLink: function (uplink, lost, mid) {
        Janus.warn(
          "Janus reports problems " +
          (uplink ? "sending" : "receiving") +
          " packets on mid " +
          mid +
          " (" +
          lost +
          " lost packets)"
        );
      },
      onmessage: function (msg, jsep) {
        Janus.debug(" ::: Got a message (subscriber) :::", msg);
        var event = msg["videoroom"];
        if (msg["error"]) {
          console.error(msg["error"]);
        } else if (event) {
          if (event === "attached") {
            // Subscriber created and attached
            for (var i = 1; i < 6; i++) {
              if (!feeds[i]) {
                feeds[i] = remoteFeed;
                remoteFeed.rfindex = i;
                break;
              }
            }
          } else if (event === "event") {
            // Check if we got a simulcast-related event from this publisher
            var substream = msg["substream"];
            var temporal = msg["temporal"];
            if (
              (substream !== null && substream !== undefined) ||
              (temporal !== null && temporal !== undefined)
            ) {
              if (!remoteFeed.simulcastStarted) {
                remoteFeed.simulcastStarted = true;
                // Add some new buttons
              }
              // We just received notice that there's been a switch, update the buttons
              // updateSimulcastButtons(remoteFeed.rfindex, substream, temporal);
            }
          } else {
            // What has just happened?
          }
        }
        if (jsep) {
          var stereo = jsep.sdp.indexOf("stereo=1") !== -1;

          // Answer and attach
          remoteFeed.createAnswer({
            jsep: jsep,
            // We only specify data channels here, as this way in
            // case they were offered we'll enable them. Since we
            // don't mention audio or video tracks, we autoaccept them
            // as recvonly (since we won't capture anything ourselves)
            tracks: [{ type: "data" }],
            customizeSdp: function (jsep) {
              if (stereo && jsep.sdp.indexOf("stereo=1") === -1) {
                // Make sure that our offer contains stereo too
                jsep.sdp = jsep.sdp.replace(
                  "useinbandfec=1",
                  "useinbandfec=1;stereo=1"
                );
              }
            },
            success: function (jsep) {
              Janus.debug("Got SDP!", jsep);
              var body = {
                request: "start",
                room: parseInt(webAsstRoomDetails.roomId),
              };
              // var body = { request: "start", room: parseInt(myroom) };
              remoteFeed.send({ message: body, jsep: jsep });
            },
            error: function (error) {
              Janus.error("WebRTC error:", error);
            },
          });
        }
      },
      onlocaltrack: function (track, on) {
        // The subscriber stream is recvonly, we don't expect anything here
      },
      onremotetrack: function (track, mid, on) {
        if (!on) {
          // Track removed, get rid of the stream and the rendering
          if (track.kind === "video") {
            remoteFeed.remoteVideos--;
            if (remoteFeed.remoteVideos === 0) {
              //"Track removed, get rid of the stream and the rendering"
            }
          }
          delete remoteFeed.remoteTracks[mid];
          return;
        }
        // If we're here, a new track was added
        if (remoteFeed.spinner) {
          remoteFeed.spinner.stop();
          remoteFeed.spinner = null;
        }
        if (track.kind === "audio") {
          setRemoteAudioTrack(track);
          // New audio track: create a stream out of it, and use a hidden <audio> element
          let stream = new MediaStream([track]);
          remoteFeed.remoteTracks[mid] = stream;

          // attach remote video stream
          if (remoteFeed.rfdisplay === "consultant")
            Janus.attachMediaStream(remoteAudioRef.current, stream);

          if (remoteFeed.remoteVideos === 0) {
            // No video, at least for now: show a placeholder
          }
        } else {
          // New video track: create a stream out of it
          remoteFeed.remoteVideos++;
          let stream = new MediaStream([track]);
          remoteFeed.remoteTracks[mid] = stream;
          if (remoteFeed.rfdisplay === "consultant")
            Janus.attachMediaStream(remoteVideoRef.current, stream);
          consultantBitrate(remoteFeed);
          setIsRemoteVideo(true);
          handleStart();
          // Note: we'll need this for additional videos too

          if (!bitrateTimer[remoteFeed.rfindex]) {
            // description
            // bitrateTimer[remoteFeed.rfindex] = bitrateTimeout = setInterval(function() {
            //   let bitrate = remoteFeed.getBitrate();
            //   let bitspersec = document.getElementById('biterate');
            //   if(bitspersec) bitspersec.innerText = bitrate;
            // }, 1000);
          }
        }
      },
      oncleanup: function () {
        remoteFeed.remoteTracks = {};
        remoteFeed.remoteVideos = 0;
      },
    });
  }

  function consultantBitrate(remotefeed) {
    if (consultantIntervalId) {
      clearInterval(consultantIntervalId);
    }
    consultantIntervalId = setInterval(function () {
      if (!remotefeed) {
        return;
      }
      let bitrate = remotefeed.getBitrate();
      let numericBitrate = parseFloat(bitrate) / (1000 * 8);
      if (numericBitrate !== null) {
        if (!isNaN(numericBitrate)) {
          consDataReceived += numericBitrate;
        }
      }
    }, 1000);
  }

  // Helper to escape XML tags
  function escapeXmlTags(value) {
    if (value) {
      var escapedValue = value.replace(new RegExp("<", "g"), "&lt");
      escapedValue = escapedValue.replace(new RegExp(">", "g"), "&gt");
      return escapedValue;
    }
  }

  useEffect(() => {
    window.onbeforeunload = () => {
      if (mediaRecorder?.state === "recording") {
        mediaRecorder.stop();
        socketRef.current?.emit(RECORDING_ENDED, sessionIdRef.current);
        setRecordingStarted(false);
      }
      if (window.location.pathname.includes("/webassistantcall")) {
        dataConsumption(dataSent, false);
      }
      socketRef.current?.on(USER_MUTE, sessionIdRef.current);
    };
  }, []);

  // Function to handle ending the call
  const handleEndCall = async () => {
    if (mediaRecorder?.state === "recording") {
      mediaRecorder.stop();
    }
    sfutest?.hangup();
    socketRef.current?.emit(END_CALL, sessionIdRef.current);
    navigate("/webAssistantJoin/:sessionId");
    handleReset();
    setIsConferenceCall(false);
    setRecordingMute(false);
  };

  // Function to handle staying in the call
  const handleStayInCall = () => {
    setUserLeftAlert(false);
    setNavigationBackPopup(false);
  };

  const swapVideos = () => {
    const videoElement1 = remoteVideoRef.current;
    const videoElement2 = localVideoRef.current;
    if (videoElement1 || videoElement2) {
      const newContainer1 = container2;
      const newContainer2 = container1;
      const currentContainer1 = document.getElementById(container1);
      const currentContainer2 = document.getElementById(container2);
      if (currentContainer1 && currentContainer2) {
        isSelfSecondary = !isSelfSecondary;
        currentContainer1.appendChild(videoElement2);
        currentContainer2.appendChild(videoElement1);
      }
      setContainer1(newContainer1);
      setContainer2(newContainer2);
    }
    if (videoElement1) {
      setShowMuteIcon(!showMuteIcon);
    }
  };

  // Function to handle leaving the call
  const handleLeaveCall = async () => {
    clearInterval(timeoutId);
    const callSessionId = state.callSessionId;
    if (mediaRecorder?.state === "recording") {
      mediaRecorder.stop();
    }
    sfutest?.hangup();
    socketRef.current?.emit(LEAVE_CALL, sessionIdRef.current);
    setLocalVideoStream(null);
    navigate("/webAssistantJoin/" + callSessionId);
    sessionStorage.removeItem("webAssistantName");
    sessionStorage.removeItem("consultantName");
    sessionStorage.removeItem("WastSessionId");
    handleReset();
    setIsConferenceCall(false);
    isSelfSecondary = true;
    sessionStorage.removeItem("wastSelfMuted");
    dataConsumption(dataSent, true);
    clearInterval(consultantIntervalId);
  };

  // outer container height
  const outerContainerRef = useRef(null);
  // const viewportHeightRef = useRef(window.innerHeight);
  const viewportHeightRef = useRef(document.documentElement.clientHeight);

  useEffect(() => {
    const initResizeOuterContainer = () => {
      setTimeout(() => {
        viewportHeightRef.current = document.documentElement.clientHeight;
        outerContainerRef.current.style.height = `${viewportHeightRef.current}px`;
      }, 10);
    };
    const handleResizeOuterContainer = () => {
      viewportHeightRef.current = document.documentElement.clientHeight;
      outerContainerRef.current.style.height = `${viewportHeightRef.current}px`;
    };

    initResizeOuterContainer();
    window.addEventListener("resize", handleResizeOuterContainer);
    return () => {
      window.removeEventListener("resize", handleResizeOuterContainer);
    };
  }, []);

  const dataConsumption = async (dataSent, isEndCall) => {
    const wastIntervalData = dataSent - wastIntervalDataSent;
    const consIntervalData = consDataReceived - consIntervalDataReceived;

    const params = {
      sessionId: sessionIdRef.current,
      subscriptionId: 0,
      userId: 0,
      roleId: 0,
      dataSent: parseFloat(wastIntervalData.toFixed(2)),
      dataReceived: parseFloat(consIntervalData.toFixed(2)),
      isConferenceCall: true,
      isEndCall,
    };

    try {
      const resp = await apis.instance.post(
        `${webAssistantSubscriptionDataUsage}`,
        params
      );
      if (resp.data.success) {
        wastIntervalDataSent = dataSent;
        consIntervalDataReceived = consDataReceived;
      } else {
        const errorMessage = resp.data.error
          ? t(resp.data.error)
          : t("responseMsg.failure");
        toast.error(errorMessage);
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? t(error.response.data.message)
          : t("responseMsg.error");
      toast.error(errorMessage);
    }
  };

  const changeMediaStream = async () => {
    setIsFrontCamera((prevState) => !prevState);
    const constraints = {
      video: { facingMode: isFrontCamera ? "environment" : "user" },
    };
    try {
      const newStream = await navigator.mediaDevices.getUserMedia(constraints);
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = newStream;
        setLocalVideoStream(newStream);
      }

      // Replace the video track in Janus
      const videoTrack = newStream.getVideoTracks()[0];
      const sender = sfutest?.webrtcStuff.pc
        .getSenders()
        .find((s) => s.track.kind === "video");
      if (sender) {
        sender.replaceTrack(videoTrack);
      } else {
        sfutest.createOffer({
          media: { video: true, replaceVideo: true },
          success: (jsep) => {
            sfutest.send({ message: { request: "configure" }, jsep: jsep });
          },
          error: (error) => {
            console.error("Error creating offer:", error);
          },
        });
      }
    } catch (error) {
      console.error("Error accessing media devices:", error);
    }
  };

  return (
    <>
      {navigationBackPopup && (
        <Dialog
          open={navigationBackPopup && !endCallDialog}
          onClose={() => {
            return null;
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box
            backgroundColor={"rgba(233, 57, 79, 0.2)"}
            color={"rgba(233, 57, 79, 1)"}
            padding={"8px 20px"}
          >
            <Typography variant="body2" fontWeight={"normal"}>
              <Typography
                display={"inline"}
                fontWeight={"bold"}
                variant="body2"
              >
                Note:&nbsp;
              </Typography>
              {t("lbl_confirmLeaveCallWarning")}
            </Typography>
          </Box>
          <DialogTitle id="alert-dialog-title">
            {t("lbl_confirmation")}
          </DialogTitle>
          <DialogContent>{t("lbl_confirmLeaveCall")}</DialogContent>
          <DialogActions>
            <CadisButton
              variant="contained"
              color="error"
              onClick={handleLeaveCall}
            >
              {t("lbl_yes")}
            </CadisButton>
            <CadisButton
              variant="contained"
              color="success"
              onClick={handleStayInCall}
            >
              {t("lbl_no")}
            </CadisButton>
          </DialogActions>
        </Dialog>
      )}

      {/* User left alert dialog */}
      {userLeftAlert && (
        <CadisDialog
          open={userLeftAlert}
          title={`${state?.assistantName} left. You want to Stay in call ?`}
          DialogActions={
            <>
              <Button variant="contained" color="error" onClick={handleEndCall}>
                End Call
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleStayInCall}
              >
                Stay
              </Button>
            </>
          }
        />
      )}

      {/* Open Invite Dialog */}
      {openInviteDialog()}
      {/* Open Reconnect Dialog */}
      {openReconnectDialog()}
      {/* Open End Call Popup */}
      {openEndCallPopup()}

      {/* Main container */}
      <Box
        pl={2}
        pr={2}
        boxSizing="border-box"
        backgroundColor="#222222"
        className="webAst-call-screen"
        display="block"
        width="100vw"
        ref={outerContainerRef}
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          className="call_container"
          boxSizing="border-box"
        >
          {/* <> */}
          <Box
            sx={{
              minHeight: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              color: "#ffffff",
            }}
          >
            <Grid
              container
              direction={"row"}
              spacing={0}
              border={"solid 0 yellow"}
              width={"100%"}
              height={"100%"}
              margin={"0"}
              alignItems={"center"}
            >
              <Grid item xs>
                <Typography
                  variant="h5"
                  visibility={isRemoteVideo ? "visible" : "hidden"}
                  sx={{
                    maxWidth: "95%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {isRemoteVideo ? <>{roomData?.consultantName}</> : null}
                </Typography>
              </Grid>
              <Grid item xs={"auto"}>
                {!isRemoteVideo || failedPong > 2 ? (
                  <Typography>Connecting...</Typography>
                ) : null}
              </Grid>

              <Grid
                item
                xs
                textAlign={"right"}
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                {recordingStarted && (
                  <Box display={"inline-block"}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      className="recording-started-border"
                      paddingX={0.6}
                      paddingY={0}
                      lineHeight="0"
                    >
                      <Box component={"span"} sx={{ color: "error.main" }}>
                        <FiberManualRecordIcon />
                      </Box>
                      <Box component={"span"}>
                        <Typography color="#ffffff">REC</Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
                <Typography
                  display={"inline-block"}
                  width={"100px"}
                  variant="h5"
                  fontSize={"large"}
                  component={"h6"}
                  sx={{ color: "#F2AF4C" }}
                >
                  {hours < 10 ? "0" + hours : hours}:
                  {minutes < 10 ? "0" + minutes : minutes}:
                  {seconds < 10 ? "0" + seconds : seconds}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* Video container */}
          <Box
            display={"flex"}
            position={"relative"}
            height={"calc(100% - 84px)"}
          >
            <div className="video_container primary-video-container web-user-area">
              <audio
                id="consultantAudio"
                ref={remoteAudioRef}
                muted={false}
                videoOffState={false}
                autoPlay={true}
              />
              <div id="container1" className="primary-video web-user-area">
                <video
                  id="video4"
                  ref={remoteVideoRef}
                  muted={true}
                  videoOffState={true}
                  playsInline
                  autoPlay
                  className="glass_video"
                />
              </div>

              {isMuteConsultant && !showMuteIcon ? (
                <CadisUnmuteIcon top="10px" left="49%" sx={{}} />
              ) : (
                ""
              )}
              <Box position={"absolute"} bottom={"0"} right={"0"}>
                <Box
                  sx={{
                    position: "relative",
                    padding: "1vmax",
                    boxSizing: "border-box",
                  }}
                  className={`secondary-video-container picture-in-picture-video-container ${isSelfSecondary ? "self-view" : "user-view"
                    }`}
                >
                  <div id="container2" className="secondary-video">
                    <video
                      id="video5"
                      ref={localVideoRef}
                      muted={false}
                      videoOffState={false}
                      playsInline
                      autoPlay
                      backgroundColor={"red"}
                      className="local_video"
                    />
                  </div>
                  {isMuteConsultant && showMuteIcon ? (
                    <CadisUnmuteIcon
                      bottom="calc(1vmax + 2px)"
                      left="calc(1vmax + 2px)"
                    />
                  ) : null}

                  <Box
                    sx={{
                      position: "absolute",
                      top: "calc(1vmax + 2px)",
                      right: "calc(1vmax + 2px)",
                      zIndex: "1000",
                    }}
                  >
                    <Tooltip title={"Shuffle Video View"}>
                      <IconButton
                        aria-label="shuffle-video-icon"
                        size="small"
                        className="custom-default shuffle-video"
                        color={"default"}
                        disableRipple={true}
                        onClick={swapVideos}
                        sx={{ cursor: "pointer" }}
                      >
                        <span className="cad-shuffle-video rotate-temp"></span>
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </div>
          </Box>

          {/* Bottom section */}
          <Grid
            container
            alignItems="center"
            minHeight={"42px"}
            height={"42px"}
            justifyContent="space-between"
          >
            <Box sx={{ position: "relative" }} display={{ xs: "none", lg: "inline-block" }} >
              {/* empty box left */}
            </Box>

            {/* Mute/Unmute and video on/off button */}
            <Box display="inline-block" paddingX={{ lg: 1 }}>
              <ToggleComponent
                sfutest={sfutest}
                localVideoStream={localVideoStream}
                isVideo={true}
                socketRef={socketRef}
                recordingMute={recordingMute}
                setWebAsstSelfMute={setWebAsstSelfMute}
              />{" "}
              {/* Video toggle */}
              <ToggleComponent
                sfutest={sfutest}
                isVideo={false}
                socketRef={socketRef}
                recordingMute={recordingMute}
                setWebAsstSelfMute={setWebAsstSelfMute}
              />{" "}
              {/* Audio toggle */}
            </Box>

            {isMobileDevice ? (
              <IconButton
                aria-label={"Camera Flip"}
                size="small"
                onClick={changeMediaStream}
                className="custom-default"
                color="default"
              >
                <span className="cad-switch-camera"></span>
              </IconButton>
            ) : (
              ""
            )}

            {/* Leave call button */}
            <CadisButton
              size="small"
              variant="contained"
              color="error"
              onClick={handleLeaveCall}
              sx={{ float: "right" }}
            >
              {t("lbl_leaveCall")}
            </CadisButton>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default WebAssistantCallScreen;