import { Avatar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, ListItem, ListItemAvatar, ListItemText, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState, useRef } from "react";
import { isAuthenticated, GetNonDeletedUser, searchFilterFunction, formatPhoneNumber, stringAvatar, GetGridNoRecordMessage,statusSortComparator } from '../../Common/helperFunction';
import { ActiveStatus, ActiveStatusID, ButtonActionAdd, ButtonActionDelete, ButtonActionEdit, ButtonActionSend, countries, currentHost, DefaultCadisAdmin, DefaultGridRowSize, FormAddMode, FormUpdateMode, GridRowsPerPageOptions, InActiveStatus, InActiveStatusID, FormViewMode, ButtonReactivate } from "../../Common/helper";
import apis from "../../HttpConfig/Api";
import { auto } from '@popperjs/core';
import { AllUserListByRoleRoute, DeleteUserRoute, ResetPassword } from "../../Common/api.routes";
import { ROLE, ROLENAMES } from "../../Interfaces/IConstants";
import { IUser } from "../../Interfaces/IUser";
import { GridStyling } from "../../Common/sharedStyling";
import CadisButton from "../../Components/Input/CadisButton";
import { toast } from "react-toastify";
import CadisLoader from "../../Components/Loading/CadisLoader";
import AddUpdateOrganizationAdmin from "../OrganizationAdmin/AddUpdateOrganizationAdmin";
import { useUserRoleContext } from "../../context/UserRoleContext";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const CadisAdminList = () => {
    const { handleToggleLoader } = useUserRoleContext();
    const { t } = useTranslation();
    const user = isAuthenticated();
    const userRole = ROLENAMES.CadisAdmin;
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState<number>(DefaultGridRowSize);
    const [cadisAdminGridData, setCadisAdminGridData] = useState([]);
    const [addCadisAdminDrawer, setAddCadisAdminDrawer] = useState<boolean>(false);
    const [cadisAdminCreationMode, setCadisAdminCreationMode] = useState(FormViewMode);
    const [cadisAdminListFlow, setcadisAdminListFlow] = useState('cadisAdminFlow');
    const [currentCadisAdminData, setCurrentCadisAdminData] = useState<IUser>(DefaultCadisAdmin);
    const [openSendEmailDialog, setOpenSendEmailDialog] = useState({ 'open': false, 'user_id': '' });
    const [consultantSpecialities, setconsultantSpecialities] = useState([{ 'speciality_id': 0, 'speciality': '' }])
    const [searchQuery, setSearchQuery] = useState();
    const [data, setData] = useState([]);

    const [showNonDeletedUserData, setShowNonDeletedUserData] = useState([]);
    const [openReactivateUserDialog, setOpenReactivateUserDialog] = useState({
        'open': false,
        'UserID': null,
        'UserName': ""
    });

    const [openDeleteDialog, setOpenDeleteDialog] = useState({
        'open': false,
        'UserID': null,
        'AdminName': ""
    });

    const handleSendPasswordPopup = (userId: string) => {
        setOpenSendEmailDialog({
            'open': true,
            'user_id': userId
        });
    }

    const handleCloseSendEmailDialog = () => {
        setOpenSendEmailDialog({
            'open': false,
            'user_id': ''
        });
    }

    const handleSendPassword = async (userId: string) => {

        setLoading(true);
        const data = {
            'user_name': userId,
            'url': currentHost + "/setNewPassword"
        }
        try {
            await apis.instance.post(`${ResetPassword}`, data)
                .then((resp: any) => {
                    if (resp.data.success) {
                        handleCloseSendEmailDialog();
                        toast.success("A link to reset password has been sent to registered email id.");
                        setLoading(false);
                    } else {
                        toast.error(resp.data.error);
                    }
                })
                .finally(() => setLoading(false));
        } catch (error: any) {
            setLoading(false);
            toast.error("Something went wrong");
            console.log(error.message);
        }
    }

    const columns: GridColDef[] = [
        { field: 'user_id', width: 0, hide: true, },
        { field: 'user_title', width: 0, hide: true, },
        { field: 'user_fname', width: 0, hide: true, },
        { field: 'user_lname', width: 0, hide: true, },
        {
            field: 'userFullName', headerName: 'Name', minWidth: 180, flex: 1, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: true,
            renderCell: ({ row }: any) => {
                return <>
                    <ListItem disablePadding>
                        <ListItemAvatar>
                            <Avatar {...stringAvatar(row.full_name)} src={row.user_profile_img} />

                        </ListItemAvatar>
                        <Tooltip title={`${row.full_name}`} >
                            <ListItemText sx={{ textTransform: 'capitalize', }} className="long-col-name-ellipsis"
                                primary={`${row.full_name}`}
                                secondary=""
                            /></Tooltip>
                    </ListItem>
                </>
            },
        },
        {
            field: 'user_email', headerName: 'Email', minWidth: 300, flex: 1, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: false,
            renderCell: ({ row }: any) => (
                <Tooltip title={row.user_email} >
                    <span className="table-cell-trucate">{row.user_email}</span>
                </Tooltip>
            ),
        },
        {
            field: 'phone_number_m', headerName: 'Phone', minWidth: 130, flex: 1, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: false, renderCell: ({ row }: any) => {
                const phoneNumber = row.phone_number_m;
                const phoneNumberTwo = row.phone_number_nm;
                return <Stack direction="column" textAlign={'left'}>
                    <Box>{formatPhoneNumber(phoneNumber)}</Box>
                    <Box>{formatPhoneNumber(phoneNumberTwo)}</Box>
                </Stack>
            }
        },
        {
            field: 'user_status', flex: 1, headerName: 'Status', minWidth: 100, headerClassName: 'app-theme-header',
            valueGetter: (params) => params.row.status,
            renderCell: ({ row }: any) => {
                return <>
                    <Chip size="small" label={row.status} color={row.user_password == "false" && row.user_status === 0 ? 'warning' : row.user_status === 1 ? 'success' : 'error'}></Chip>
                </>
            },
            sortComparator: statusSortComparator
        },
        {
            field: 'actions', headerName: 'Actions',
            minWidth: 180, headerClassName: 'app-theme-header bg-action',
            headerAlign: "center", align: "center",
            cellClassName: 'app-theme-cell bg-action', sortable: false,
            renderCell: (params: any) => {
                return <Stack direction="row" spacing={0}>
                    <Box className='grid-act-btn'>
                        <Tooltip title="Resend Password Link" placement="left">
                            <IconButton aria-label="AdminIcon" id={ButtonActionSend} onClick={(e) => {
                                onClick(e, params)
                            }} color="primary">
                                <span className='cad-send'></span>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {/* left empty box for adding space between edit and other left actions */}
                    <Box className='grid-act-btn'></Box>
                    <Box className='grid-act-btn'>
                        <Tooltip title="Edit CADIS SA" placement="bottom-end">
                            <IconButton aria-label="AdminIcon" id={ButtonActionEdit} onClick={(e) => {
                                onClick(e, params)
                            }} color="primary"><span className='cad-edit'></span>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box className='grid-act-btn'>
                        {
                            (
                                params.row.user_status === InActiveStatusID &&
                                params.row.user_id !== user.userId && params.row.user_password == "true"
                            ) ?
                                <Tooltip title={t('lbl_hideCadisSA')} placement="bottom-end">
                                    <IconButton aria-label="AdminIcon" id={ButtonActionDelete} color="error" onClick={(e) => {
                                        onClick(e, params)
                                    }}><span className='cad-archive'></span></IconButton>
                                </Tooltip>
                                : null
                        }
                    </Box>
                </Stack>
            }
        }
    ]
    // filtering the search filed
    const handleSearchInputChange = (event: any) => {
        let value = event.target.value;
        setSearchQuery(value);

        if (event.target.value !== "") {
            let filterData: any = searchFilterFunction(data, value)
            setCadisAdminGridData(filterData)
        }

    }

    const settingData = (data: any) => {

        const nonDeletedCadisAdmins = GetNonDeletedUser(data, user.userId);
        // mapping status key based on condition 
        const FormattedArrayWithStatus: any = nonDeletedCadisAdmins.map((i: any) => {
            return { ...i, status: i.user_password == "false" ? "Verification Pending" : i.user_status === ActiveStatusID ? ActiveStatus : InActiveStatus }
        })
        const sortedDataByFullnameThenByStatus = SortDataByIntialThenBySecondaryKeys(FormattedArrayWithStatus, "userFullName", "user_status");
        setCadisAdminGridData(FormattedArrayWithStatus);
        setData(sortedDataByFullnameThenByStatus);
        setShowNonDeletedUserData(sortedDataByFullnameThenByStatus);
    }

    const SortDataByIntialThenBySecondaryKeys = (data: any[], initialSortKey: string, secondarySortKey: string) => {
        const updatedData = data.map(obj => ({ ...obj, userFullName: `${obj.user_fname} ${obj.user_lname}` }))
        const sortedData: any = updatedData.sort(function (a, b) {
            if (a.userFullName < b.userFullName) { return -1; }
            if (a.userFullName > b.userFullName) { return 1; }
            return 0;
        }).sort(function (x, y) {
            if (x.user_status > y.user_status) { return -1; }
            if (x.user_status < y.user_status) { return 1; }
            return 0;
        }).sort(function (p, q) {
            if (p.user_is_deleted < q.user_is_deleted) { return -1; }
            if (p.user_is_deleted > q.user_is_deleted) { return 1; }
            return 0;
        }).sort(function (m, n) {
            if (n.user_password === "false" && n.user_status === 0) { return 1; }
            if (m.user_password === "false" && m.user_status === 0) { return -1; }
            return 0;
        });
        return sortedData;
    }

    const onClick = (e: any, params: any) => {
        e.stopPropagation(); // don't select this row after 
        OnActionClick(e.currentTarget.id, params?.row);
    };

    //#region Action Buttons Click
    const OnActionClick = (actionId: string, row: any) => {
        switch (actionId) {
            case ButtonActionAdd:
                DefaultCadisAdmin.org_id = 1;
                setCadisAdminCreationMode(FormAddMode);
                setCurrentCadisAdminData(DefaultCadisAdmin);
                setAddCadisAdminDrawer(true);
                break;
            case ButtonActionEdit:
                setCadisAdminCreationMode(FormUpdateMode);
                const cadisAdminToEdit = {
                    'org_id': row.org_id,
                    'org_name': "",
                    'user_id': row.user_id,
                    'org_admin_title': row.user_title,
                    'org_admin_fname': row.user_fname ?? "",
                    'org_admin_lname': row.user_lname ?? "",
                    'org_admin_email': row.user_email ?? "",
                    'org_admin_username': row.user_name ?? "",
                    'org_admin_phone_m': row.phone_number_m,
                    'org_admin_phone_nm': row.phone_number_nm ?? "",
                    'org_admin_status': row.user_status,
                    'orgAdminSendDetailsInEmail': true,
                    'specialities': [],
                    'profile_image': row.user_profile_img ?? "",
                    "country_code": row.country_code ?? countries[0].code,
                    "set_password_url": "",
                    "assign_role_to_user": false,
                    "newAssignedRole": 0,
                    "isPasswordSet": row.user_password === "true" ? true : false
                }
                setCurrentCadisAdminData(cadisAdminToEdit);
                setAddCadisAdminDrawer(true);
                break;
            case ButtonActionDelete:
                setOpenDeleteDialog({
                    'open': true,
                    'UserID': row.user_id,
                    'AdminName': row.user_fname + " " + row.user_lname
                });
                break;
            case ButtonActionSend:
                handleSendPasswordPopup(row.user_name);
                break;
            case ButtonReactivate:
                setOpenReactivateUserDialog({
                    'open': true,
                    'UserID': row.user_id,
                    'UserName': row.user_fname + " " + row.user_lname ?? ''
                });
                break;
            default:
                break;
        }
    }
    //#endregion

    // dynamic scrollbar for grid view start
    const PageTitleRef: any = useRef();
    const tableGridRef: any = useRef();
    const [tableHeight, setTableHeight] = useState<any>(
    );

    const getPageTitleHeight = () => {
        let pageTitleHeight: any = 0;
        if (PageTitleRef && PageTitleRef.current) {
            pageTitleHeight = PageTitleRef.current.clientHeight;
        }
        setTableHeight(window.innerHeight - pageTitleHeight - 82);
    };

    window.addEventListener("resize", getPageTitleHeight);

    // dynamic scrollbar for grid view end

    const DeleteCadisAdmin = async () => {
        const cadisAdminData = {
            'userID': openDeleteDialog.UserID
        }
        try {
            await apis.instance.put(`${DeleteUserRoute}`, cadisAdminData)
                .then((resp: any) => {
                    if (resp.data.success) {
                        handleCloseDelete();
                        GetAllCadisAdmins();
                        toast.success(t('responseMsg.msg_hidecadisSuperAdmin'));
                    } else {
                        toast.error(resp.data.error);
                    }
                })
                .finally();
        } catch (error: any) {
            toast.error("Something went wrong");
            console.log(error.message);
        }
    }

    const handleCloseDelete = () => {
        setOpenDeleteDialog({
            'open': false,
            'UserID': null,
            'AdminName': ""
        });
    }

    //#region GET CADIS ADMIN LIST
    const GetAllCadisAdmins = async () => {
        handleToggleLoader(true);
        const params = {
            'orgID': 1,
            'role': ROLE.CadisAdmin
        }

        await apis.instance.get(`${AllUserListByRoleRoute}`, { params })
            .then((resp: any) => {

                let response = resp?.data?.data.map((item: any) => {
                    return {
                        ...item,
                        full_name: item.user_title === "None" ? `${item.user_fname} ${item.user_lname ?? ""}` : `${item.user_title} ${item.user_fname} ${item.user_lname ?? ""}`


                    }
                })

                handleToggleLoader(false);
                settingData(response)
            })
            .catch(err => {
                handleToggleLoader(false);
                if (err?.response.status !== 401) toast.error(err.response?.data?.message)
                console.log(err)
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        GetAllCadisAdmins();
        getPageTitleHeight();
    }, []);
    //#endregion

    return (
        <div>
            <Box ref={PageTitleRef} className="page-title-row pb-3">
                <Grid container alignItems={'center'} textAlign={'left'} rowSpacing={2} columnSpacing={{ xs: 2, md: 0 }}>
                    <Grid item xs={12} md={auto} mr={auto} sm className="page-title-box" padding={0}>
                        <Typography className='page-title mt-0 mb-0' sx={(theme) => theme.typography.h5}>{t('lbl_activeInactiveCadisSA')}</Typography>
                    </Grid>
                    <Grid item xs={12} md={auto} padding={0}>
                        <Button id={ButtonActionAdd}
                            onClick={(e) => {
                                onClick(e, null)
                            }} startIcon={<span className='cad-plus'></span>} variant="contained" sx={{ my: .5, mr: 1 }}>
                            {t('lbl_create')}<Typography sx={{ font: 'inherit' }} display={{ xs: 'none', md: 'inline' }}>&nbsp;CADIS Super Admin</Typography>
                        </Button>

                        <TextField className="cadis-search"
                            id="outlined-end-adornment"
                            size="small"
                            type="search" placeholder="Search"
                            sx={{ width: '18ch', my: .5 }}
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><span className="cad-search"></span></InputAdornment>,
                            }}
                        />

                    </Grid>
                </Grid>
            </Box>
            <Box sx={GridStyling} ref={tableGridRef} height={tableHeight}>
                <DataGrid
                    components={{
                        NoRowsOverlay: GetGridNoRecordMessage,
                    }}
                    rows={cadisAdminGridData}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                    rowsPerPageOptions={GridRowsPerPageOptions}
                    getRowId={(row: any) => row.user_id}
                    autoHeight={false}
                    autoPageSize={false}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    disableSelectionOnClick={true}
                />
            </Box>
            {cadisAdminCreationMode !== FormViewMode ? <AddUpdateOrganizationAdmin
                isOpenAddUpdateAdmin={addCadisAdminDrawer}
                setIsOpenAddUpdateAdminExpandAdminData={setAddCadisAdminDrawer}
                mode={cadisAdminCreationMode}
                currentOrgAdminData={currentCadisAdminData}
                getOrgUserList={GetAllCadisAdmins}
                userRole={userRole}
                consultantSpecialities={consultantSpecialities}
                userFlow={cadisAdminListFlow}
            /> : null}
            <Dialog
                open={openDeleteDialog.open}
                onClose={() => { return null }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('lbl_confirmation')}
                </DialogTitle>
                <DialogContent>
                    {t('lbl_confirmationForHide')} {openDeleteDialog.AdminName}?
                </DialogContent>
                <DialogActions>
                    <CadisButton onClick={DeleteCadisAdmin} variant="contained" color='error'>
                        {t('lbl_yes')}
                    </CadisButton>
                    <CadisButton onClick={handleCloseDelete} variant="contained" color='success'>
                        {t('lbl_no')}
                    </CadisButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openSendEmailDialog.open}
                onClose={() => { return null }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirmation
                </DialogTitle>
                <DialogContent>
                    Do you want to send a link to set a new password
                </DialogContent>
                <DialogActions>
                    <CadisButton variant="contained" color='primary' onClick={() => handleSendPassword(openSendEmailDialog.user_id)} disabled={loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                        Yes
                    </CadisButton>
                    <CadisButton variant="contained" color='error' onClick={handleCloseSendEmailDialog} disabled={loading}>
                        No
                    </CadisButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CadisAdminList;