import { Dialog, DialogContent, DialogTitle, Grid,} from '@mui/material';
import { useTranslation } from 'react-i18next';

const DuplicateTab = () => {
   const { t } = useTranslation();
  return (
        <Dialog
        open
        onClose={() => { return null }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title"  color= "error.main">
        {t('lbl_alert')}
        </DialogTitle>
        <DialogContent>
        {(t('responseMsg.msg_duplicateTab'))}
        </DialogContent>
      </Dialog>
  )
} 

export default DuplicateTab