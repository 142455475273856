import React from "react";

export interface IRoles {
    value: string | number,
    label: React.ReactNode
}

export enum ROLE {
    CadisAdmin = 1,
    OrgAdmin = 2,
    Consultant = 3,
    Assistant = 4
}

export interface User {
    id: number,
    name: string,
    orgId: number,
    roles: number[],
    isCadisAdmin: boolean,
    isOrgAdmin: boolean,
    isConsultant: boolean,
    userId: number,
    username: string,
    userEmail: string,
}

export interface IUserOtp {
    user_id: number,
    otp: string
};

export interface Iroutes {
    roles: ROLE[],
    children: JSX.Element,
    path?: string,
}

export interface ICountry {
    code: string,
    name: string
}

export interface ISpeciality {
    speciality_id: number,
    speciality: string
}

export enum ROLENAMES {
    CadisAdmin = "CADIS Super Admin",
    OrgAdmin = "Organization Admin",
    Consultant = "Consultant",
    Assistant = "Assistant"
}
