import { useState } from 'react';
import { Avatar, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from '@mui/material';
import { IUser } from '../../Interfaces/IUser';
import CadisButton from '../../Components/Input/CadisButton';
import { stringAvatar } from '../../Common/helperFunction';
import { ResetPassword, SendQRCodeToAssistantRoute } from '../../Common/api.routes';
import apis from '../../HttpConfig/Api';
import { toast } from 'react-toastify';
import CadisLoader from '../../Components/Loading/CadisLoader';
import { ROLENAMES } from '../../Interfaces/IConstants';
import { useTranslation } from 'react-i18next';

const UserDetails = (
    { currentOrgAdminData, userRole, setshowOrgConsultantList }: {
        currentOrgAdminData: IUser, userRole: string, setshowOrgConsultantList: any
    }) => {
    const [loading, setLoading] = useState(false);
    const [openSendEmailDialog, setOpenSendEmailDialog] = useState({ 'open': false, 'user_id': 0 });
    const currentHost = `${window.location.protocol + '//' + window.location.hostname + ':' + window.location.port}`;
    const [openSendQRCodeDialog, setOpenSendQRCodeDialog] = useState({ 'open': false, 'user_id': 0 });
    const { t } = useTranslation();
    const handleSendPassword = async () => {
        setLoading(true);
        const data = {
            'user_name': currentOrgAdminData.org_admin_username,
            'url': currentHost + "/setNewPassword"
        }
        try {
            await apis.instance.post(`${ResetPassword}`, data)
                .then((resp: any) => {
                    if (resp.data.success) {
                        handleCloseSendEmailDialog();
                        toast.success(t('responseMsg.msg_linkToResetPassword'));
                        setshowOrgConsultantList(false);
                        setLoading(false);
                    } else {
                        toast.error(resp.data.error);
                    }
                })
                .finally(() => setLoading(false));
        } catch (error: any) {
            setLoading(false);
            toast.error(t('responseMsg.msg_wrongEntry'));
            console.log(error.message);
        }
    }

    const handleSendPasswordPopup = () => {
        setOpenSendEmailDialog({
            'open': true,
            'user_id': currentOrgAdminData.user_id
        });
    }

    const handleCloseSendEmailDialog = () => {
        setOpenSendEmailDialog({
            'open': false,
            'user_id': 0
        });
    }

    const handleSendQRCodePopup = () => {
        setOpenSendQRCodeDialog({
            'open': true,
            'user_id': currentOrgAdminData.user_id
        });
    }
    const handleCloseSendQRCodeDialog = () => {
        setOpenSendQRCodeDialog({
            'open': false,
            'user_id': 0
        });
    }
    const handleSendQRCode = async () => {
        setLoading(true)
        const data = {
            'user_name': currentOrgAdminData.org_admin_username,
            'isConferenceCall': false,
            'sessionId': 0
        }
        try {
            await apis.instance.post(`${SendQRCodeToAssistantRoute}`, data)
                .then((resp: any) => {
                    if (resp.data.success) {
                        handleCloseSendQRCodeDialog();
                        toast.success(t('responseMsg.msg_qrCode'));
                        setshowOrgConsultantList(false);
                        setLoading(false);
                    } else {
                        toast.error(resp.data.error);
                    }
                })
                .finally(() => setLoading(false));
        } catch (error: any) {
            setLoading(false);
            toast.error(t('responseMsg.msg_wrongEntry'));
            console.log(error.message);
        }
    }

    return (
        <>
            <div>
                <Box>
                    <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                        <div>
                            <Avatar src={currentOrgAdminData.profile_image} {...stringAvatar(currentOrgAdminData.org_admin_fname + " " + currentOrgAdminData.org_admin_lname)} />
                        </div>
                        <div>
                            <label id="userTitleLabelID" className='form-label '>{t('lbl_name')}</label>
                            <Typography color='grey-black'>
                            {userRole === ROLENAMES.Assistant
                             ? currentOrgAdminData.org_admin_fname + " " + currentOrgAdminData.org_admin_lname
                            : currentOrgAdminData.org_admin_title !== "None"
                            ? currentOrgAdminData.org_admin_title + " " + currentOrgAdminData.org_admin_fname + " " + currentOrgAdminData.org_admin_lname
                            : currentOrgAdminData.org_admin_fname + " " + currentOrgAdminData.org_admin_lname
}
                            </Typography>
                        </div>
                    </Stack>
                    <Stack direction="column" spacing={1}>
                        <div>
                            <label id="userTitleLabelID" className='form-label '>{t('lbl_email')}</label>
                            <Typography color='grey-black'>
                                {currentOrgAdminData.org_admin_email}
                            </Typography>
                        </div>
                        <div>
                            <label id="userTitleLabelID" className='form-label '>{t('lbl_userId')}</label>
                            <Typography color='grey-black'>
                                {currentOrgAdminData.org_admin_username}
                            </Typography>
                        </div>
                        <div>
                            <label id="userTitleLabelID" className='form-label '>
                                {t('lbl_phone')}
                            </label>
                            <Typography color='grey-black'>
                                {currentOrgAdminData.org_admin_phone_m}
                            </Typography>
                            <Typography color='grey-black'>
                                {currentOrgAdminData.org_admin_phone_nm}
                            </Typography>
                        </div>

                        {(userRole === ROLENAMES.Consultant && currentOrgAdminData.specialities.length > 0) && <div>
                            <label className='form-label '>
                                {t('lbl_speciality')}
                            </label>
                            <Typography color='grey-black'>
                                {currentOrgAdminData.specialities[0].speciality}
                                { //CODE COmmented for FUTURE REFERENCE 
                                /* {currentOrgAdminData.specialities?.map((item: any) =>
                                    <Chip key={item.speciality_id} label={item.speciality} sx={{ mr:1 }} /> )} */}
                            </Typography>
                        </div>}
                        <div>
                            <label className='form-label '>
                                {t('lbl_permission')}
                            </label>
                            <Typography color={currentOrgAdminData.org_admin_status ? 'success.main' : 'error'}>
                                {currentOrgAdminData.org_admin_status ? t('lbl_enable') : t('lbl_disable')}
                                {(!currentOrgAdminData.isPasswordSet && userRole !== ROLENAMES.Assistant) && <Typography color='warning.main' sx={{display: 'inline'}}>(Verification Pending)</Typography> }
                            </Typography>
                        </div>
                        <div>
                            {userRole === "Consultant" || userRole === "Organization Admin" ?
                                <CadisButton type='submit' variant="contained" color='primary' startIcon={<span className='cad-send'></span>} onClick={handleSendPasswordPopup}>
                                    {t('lbl_linkForNewPassword')}
                                </CadisButton> : <CadisButton type='submit' variant="contained" color='primary' startIcon={<span className='cad-barcode'></span>} onClick={handleSendQRCodePopup}>
                                    {t('lbl_newQrCode')}
                                </CadisButton>
                            }
                        </div>
                        <Dialog
                            open={openSendEmailDialog.open}
                            onClose={() => { return null }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {t('lbl_confirmation')}
                            </DialogTitle>
                            <DialogContent>
                                {t('lbl_askForNewLinkToSetNewPassword')}
                            </DialogContent>
                            <DialogActions>
                                <CadisButton variant="contained" color='primary' onClick={handleSendPassword} disabled={loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                                    {t('lbl_yes')}
                                </CadisButton>
                                <CadisButton variant="contained" color='error' onClick={handleCloseSendEmailDialog} disabled={loading}>
                                    {t('lbl_no')}
                                </CadisButton>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={openSendQRCodeDialog.open}
                            onClose={() => { return null }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {t('lbl_confirmation')}
                            </DialogTitle>
                            <DialogContent>
                                {t('lbl_newQrForAssistant')}
                            </DialogContent>
                            <DialogActions>
                                <CadisButton variant="contained" color='primary' onClick={handleSendQRCode} disabled={loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                                    {t('lbl_yes')}
                                </CadisButton>
                                <CadisButton variant="contained" color='error' onClick={handleCloseSendQRCodeDialog} disabled={loading}>
                                    {t('lbl_no')}
                                </CadisButton>
                            </DialogActions>
                        </Dialog>
                    </Stack>
                </Box>
            </div>
        </>
    )
}

export default UserDetails