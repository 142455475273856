import { Box, Collapse, Container, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { GetSpecialityList, ResetPassword, SendQRCodeToAssistantRoute } from "../../Common/api.routes";
import { DefaultAssistant, FormAddMode, FormUpdateMode, FormViewMode, UserTitles, countries, currentHost } from "../../Common/helper";
import { checkUserCreationCount, isAuthenticated, } from "../../Common/helperFunction";
import CadisButton from "../../Components/Input/CadisButton";
import CadisLoader from "../../Components/Loading/CadisLoader";
import apis from "../../HttpConfig/Api";
import { ISpeciality, ROLENAMES } from "../../Interfaces/IConstants";
import { IUser } from "../../Interfaces/IUser";
import AddUpdateOrganizationAdmin from "../OrganizationAdmin/AddUpdateOrganizationAdmin";
import UserDetails from "../User/UserDetails";

const OraganizationUserList = ({ currentOrgMetaData, setShowOrgAdminForm, showOrgAdminForm, showOrgConsultantList, orgUserGridData, setshowOrgConsultantList, userRole = ROLENAMES.OrgAdmin, getOrgUserList }: any) => {
    const [expandAdminData, setExpandAdminData] = useState<any[]>([]);
    const [isOpenAddUpdateAdmin, setIsOpenAddUpdateAdminExpandAdminData] = useState<boolean>(false);
    const [formMode, setFormMode] = useState(FormViewMode);
    const [loading, setLoading] = useState(false);
    const [openSendEmailDialogForAdmin, setOpenSendEmailDialogForAdmin] = useState({ 'open': false, 'user_id': '' });
    const user = isAuthenticated();
    const [consultantSpecialities, setConsultantSpecialities] = useState<ISpeciality[]>([]);
    const [orgListFlow, setOrgListFlow] = useState('orgFlow');
    const [openSendQRCode, setOpenSendQRCode] = useState({ 'open': false, 'user_id': '' })
    const { t } = useTranslation();
    const localRole = Number(sessionStorage.getItem("role"));

    const handleSendPasswordPopup = (userId: string) => {
        setOpenSendEmailDialogForAdmin({
            'open': true,
            'user_id': userId
        });
    }

    const handleCloseSendEmailDialog = () => {
        setOpenSendEmailDialogForAdmin({
            'open': false,
            'user_id': ''
        });
    }

    const handleSendPassword = async (userId: string) => {
        setLoading(true);
        const data = {
            'user_name': userId,
            'url': currentHost + "/setNewPassword"
        }
        try {
            await apis.instance.post(`${ResetPassword}`, data)
                .then((resp: any) => {
                    if (resp.data.success) {
                        handleCloseSendEmailDialog();
                        toast.success(t('responseMsg.msg_resetPasswordLink'));
                        setLoading(false);
                    } else {
                        toast.error(resp.data.error);
                    }
                })
                .finally(() => setLoading(false));
        } catch (error: any) {
            setLoading(false);
            toast.error(t('responseMsg.msg_wrongEntry'));
            console.log(error.message);
        }
    }
    //send QR Code
    const handleSendQRCodePopup = (userId: string) => {
        setOpenSendQRCode({
            'open': true,
            'user_id': userId
        });
    }

    const handleCloseSendQRCodeDialog = () => {
        setOpenSendQRCode({
            'open': false,
            'user_id': ''
        });
    }

    const handleSendQRCode = async (userId: string) => {
        setLoading(true);
        const data = {
            'user_name': userId,
            'isConferenceCall': false,
            'sessionId': 0,
        }
        try {
            await apis.instance.post(`${SendQRCodeToAssistantRoute}`, data)
                .then((resp: any) => {
                    if (resp.data.success) {
                        handleCloseSendQRCodeDialog();
                        toast.success(t('responseMsg.msg_qrCode'));
                        setLoading(false);
                    } else {
                        toast.error(resp.data.error);
                    }
                })
                .finally(() => setLoading(false));
        } catch (error: any) {
            setLoading(false);
            toast.error(t('responseMsg.msg_wrongEntry'));
            console.log(error.message);
        }
    }

    useEffect(() => {
        getSpecialities();
        if (showOrgAdminForm) {
            setCurrentOrgAdminData(
                {
                    ...DefaultAssistant,
                    'org_id': currentOrgMetaData.orgID,
                    'org_name': currentOrgMetaData.orgName
                })
        };
        return () => {
            setCurrentOrgAdminData(DefaultAssistant);
        }
    }, [currentOrgMetaData]);

    const getSpecialities = async () => {
        await apis.instance.get(`${GetSpecialityList}`)
            .then((resp: any) => setSpecialityData(resp.data))
            .catch(err => {
                if (err?.response.status !== 401) toast.error(err.response?.data?.message)
            })
            .finally(() => setLoading(false));
    }

    const setSpecialityData = ({ data }: any) => {
        setConsultantSpecialities(data);
    }

    function handleExpand(user_id: any): void {
        setFormMode(FormViewMode);
        setExpandAdminData((prevState) => ({
            ...prevState, [user_id]: {
                'isExpanded': prevState[user_id] != null ? (prevState[user_id]['isExpanded'] === true ? false : true) : true
            }
        }));
    };

    const [currentOrgAdminData, setCurrentOrgAdminData] = useState<IUser>({
        'org_id': currentOrgMetaData.orgID,
        'org_name': currentOrgMetaData.orgName,
        'user_id': 0,
        'org_admin_title': UserTitles[0],
        'org_admin_fname': "",
        'org_admin_lname': "",
        'org_admin_email': "",
        'org_admin_username': "",
        'org_admin_phone_m': "",
        'org_admin_phone_nm': "",
        'org_admin_status': true,
        'orgAdminSendDetailsInEmail': true,
        'specialities': [],
        'profile_image': "",
        "country_code": countries[0].code,
        "set_password_url": "",
        'assign_role_to_user': false,
        'newAssignedRole': 0,
        'isPasswordSet': false
    });

    const onAddEditOrgAdmin = async (user_id: number) => {
        if (user_id !== 0) {
            let currentAdminToEdit = orgUserGridData.find((x: { user_id: number; }) => x.user_id === user_id);
            if (currentAdminToEdit) {
                if (currentAdminToEdit.specialities === undefined || currentAdminToEdit.specialitieslength === 0)
                    currentAdminToEdit.specialities = [{ 'speciality_id': '', 'speciality': '' }];

                currentAdminToEdit.country_code = currentAdminToEdit.country_code ?? '';
                currentAdminToEdit.org_admin_phone_nm = currentAdminToEdit.org_admin_phone_nm ?? '';
                currentAdminToEdit.profile_image = currentAdminToEdit.profile_image ?? '';

                setCurrentOrgAdminData(currentAdminToEdit);
                setFormMode(FormUpdateMode);
            } else {
                toast.error(t('responseMsg.msg_noUserDetailsFound'));
            }
            setIsOpenAddUpdateAdminExpandAdminData(true);
        }
        else {
            if (userRole === ROLENAMES.Consultant || userRole === ROLENAMES.Assistant) {
                const result = await checkUserCreationCount(currentOrgMetaData.orgID, localRole);
                if (result) {
                    setCurrentOrgAdminData(
                        {
                            'org_id': currentOrgMetaData.orgID,
                            'org_name': currentOrgMetaData.orgName,
                            'user_id': 0,
                            'org_admin_title': UserTitles[0],
                            'org_admin_fname': "",
                            'org_admin_lname': "",
                            'org_admin_email': "",
                            'org_admin_username': "",
                            'org_admin_phone_m': "",
                            'org_admin_phone_nm': "",
                            'org_admin_status': true,
                            'orgAdminSendDetailsInEmail': true,
                            'specialities': [],
                            'profile_image': "",
                            "country_code": countries[0].code,
                            "set_password_url": "",
                            "assign_role_to_user": false,
                            "newAssignedRole": 0,
                            "isPasswordSet": false
                        }
                    );
                    setFormMode(FormAddMode);
                    setIsOpenAddUpdateAdminExpandAdminData(true);
                }
            }
            else {
                setCurrentOrgAdminData(
                    {
                        'org_id': currentOrgMetaData.orgID,
                        'org_name': currentOrgMetaData.orgName,
                        'user_id': 0,
                        'org_admin_title': UserTitles[0],
                        'org_admin_fname': "",
                        'org_admin_lname': "",
                        'org_admin_email': "",
                        'org_admin_username': "",
                        'org_admin_phone_m': "",
                        'org_admin_phone_nm': "",
                        'org_admin_status': true,
                        'orgAdminSendDetailsInEmail': true,
                        'specialities': [],
                        'profile_image': "",
                        "country_code": countries[0].code,
                        "set_password_url": "",
                        "assign_role_to_user": false,
                        "newAssignedRole": 0,
                        "isPasswordSet": false
                    }
                );
                setFormMode(FormAddMode);
                setIsOpenAddUpdateAdminExpandAdminData(true);
            }
        }
    }

    return (
        <>
            <Drawer
                anchor='right'
                open={showOrgConsultantList}
                onClose={() => { return null }}>
                <Box role="presentation">
                    <Box sx={{ pt: 1.5, position: 'sticky', top: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }} >
                        <Container sx={{ alignItems: 'center', display: 'flex' }} disableGutters>
                            <Box sx={{ width: '40px', height: '40px', flexGrow: 1, display: { xs: 'flex', pr: 2 } }}>
                                <IconButton onClick={() => {
                                    setExpandAdminData([])
                                    setshowOrgConsultantList(false)
                                }} sx={{}} className='c-pointer'>
                                    <span className='cad-close'></span>
                                </IconButton>
                            </Box>
                            <Box>
                                <CadisButton type='reset' variant="contained" color='primary'
                                    onClick={
                                        () => {
                                            onAddEditOrgAdmin(0);
                                        }} startIcon={<span className='cad-plus'></span>}>
                                    {t('lbl_create')} {userRole}
                                </CadisButton>
                            </Box>
                        </Container>
                        <Box sx={{ mb: 3, pb: 2, mt: 2, display: 'flex' }}>
                            <Typography className="mt-0 mb-0 slide-right-title" sx={(theme) => theme.typography.h5}>{currentOrgMetaData.orgName}</Typography>
                        </Box>
                    </Box>
                    <List
                        sx={{ width: '100%', bgcolor: 'background.paper', mt: 2, paddingX: '20px' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <Typography id="nested-list-subheader" color="secondary" className="pb-3 pl-0" sx={(theme) => theme.typography.h6}>
                                {t('lbl_listOf')} {userRole}{t('lbl_ofThisOrg')}
                            </Typography>}>
                        <Box>
                            {orgUserGridData.map((consultant: any) =>
                                <div key={consultant.user_id} className={expandAdminData[consultant.user_id]?.isExpanded ? 'user-list-item-box bordered' : 'user-list-item-box'}>
                                    <ListItem sx={{}} secondaryAction={
                                        <Stack direction="row" spacing={0}>
                                            {(!expandAdminData[consultant.user_id]?.isExpanded && (userRole === ROLENAMES.OrgAdmin || userRole === ROLENAMES.Consultant)) && <Box className='user-details-act-btn'>
                                                <Tooltip title={t('lbl_linkForNewPassword')} placement="left">
                                                    <IconButton aria-label="sendPasswordEmail" onClick={() => {
                                                        handleSendPasswordPopup(consultant.org_admin_username);
                                                    }} color="primary">
                                                        <span className='cad-send'></span>
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>}

                                            {(!expandAdminData[consultant.user_id]?.isExpanded && userRole === ROLENAMES.Assistant) && <Box className='user-details-act-btn'>
                                                <Tooltip title={t('lbl_newQrCode')} placement="left">
                                                    <IconButton aria-label="sendQR" color="primary" onClick={() => {
                                                        handleSendQRCodePopup(consultant.org_admin_username);
                                                    }} >
                                                        <span className='cad-barcode'></span>
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>}
                                            <Box className='user-details-act-btn'>
                                                <Tooltip title={t('lbl_edit')} placement="bottom-start">
                                                    <IconButton color="primary" edge="end" aria-label="edit" onClick={() => {
                                                        onAddEditOrgAdmin(consultant.user_id);
                                                    }}>
                                                        <span className='cad-edit'></span>
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Stack>
                                    }
                                        disablePadding >
                                        <ListItemButton onClick={() => handleExpand(consultant.user_id)} className="username-box">
                                            <ListItemIcon >
                                                {/* <AddIcon /> */}
                                                {expandAdminData[consultant.user_id]?.isExpanded ? <span className='cad-minus-circle'></span> : <span className='cad-plus-circle'></span>}
                                            </ListItemIcon>
                                            <ListItemText sx={{ textTransform: 'capitalize' }}
                                                primary={
                                                    userRole === ROLENAMES.Assistant
                                                        ? consultant.org_admin_fname
                                                        : consultant.org_admin_lname != null
                                                            ? consultant.org_admin_title !== "None"
                                                                ? consultant.org_admin_title + " " + consultant.org_admin_fname + " " + consultant.org_admin_lname
                                                                : consultant.org_admin_fname + " " + consultant.org_admin_lname
                                                            : consultant.org_admin_title !== "None"
                                                                ? consultant.org_admin_title + " " + consultant.org_admin_fname + " " + consultant.org_admin_lname
                                                                : consultant.org_admin_fname + " " + consultant.org_admin_lname
                                                } />
                                        </ListItemButton>
                                    </ListItem>
                                    <Collapse in={expandAdminData[consultant.user_id]?.isExpanded} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <UserDetails
                                                    currentOrgAdminData={consultant} userRole={userRole}
                                                    setshowOrgConsultantList={setshowOrgConsultantList}
                                                />
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                </div>
                            )}
                        </Box>
                    </List>
                    {/* </Box> */}
                </Box>
            </Drawer>
            {(formMode !== FormViewMode || showOrgAdminForm) ?
                <AddUpdateOrganizationAdmin
                    setShowOrgAdminForm={setShowOrgAdminForm}
                    isOpenAddUpdateAdmin={isOpenAddUpdateAdmin || showOrgAdminForm}
                    setIsOpenAddUpdateAdminExpandAdminData={setIsOpenAddUpdateAdminExpandAdminData}
                    mode={showOrgAdminForm ? FormAddMode : formMode}
                    userRole={showOrgAdminForm ? ROLENAMES.OrgAdmin : userRole}
                    currentOrgAdminData={currentOrgAdminData}
                    getOrgUserList={getOrgUserList}
                    consultantSpecialities={consultantSpecialities}
                    userFlow={orgListFlow}
                />
                : null
            }
            <Dialog
                open={openSendEmailDialogForAdmin.open}
                onClose={() => { return null }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('lbl_confirmation')}
                </DialogTitle>
                <DialogContent>
                    {t('lbl_askForNewLinkToSetNewPassword')}
                </DialogContent>
                <DialogActions>
                    <CadisButton variant="contained" color='primary' onClick={() => handleSendPassword(openSendEmailDialogForAdmin.user_id)} disabled={loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                        {t('lbl_yes')}
                    </CadisButton>
                    <CadisButton variant="contained" color='error' onClick={handleCloseSendEmailDialog} disabled={loading}>
                        {t('lbl_no')}
                    </CadisButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openSendQRCode.open}
                onClose={() => { return null }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('lbl_confirmation')}
                </DialogTitle>
                <DialogContent>
                    {t('lbl_newQrForAssistant')}
                </DialogContent>
                <DialogActions>
                    <CadisButton variant="contained" color='primary' onClick={() => handleSendQRCode(openSendQRCode.user_id)} disabled={loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                        {t('lbl_yes')}
                    </CadisButton>
                    <CadisButton variant="contained" color='error' onClick={handleCloseSendQRCodeDialog} disabled={loading}>
                        {t('lbl_no')}
                    </CadisButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default OraganizationUserList;