import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import AllRoutes from './Utils/AllRoutes';
import { useEffect } from 'react';
import { isAuthenticated } from './Common/helperFunction';
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();
  const navigateToPage = () => {
    window.location.href = '/duplicatetab';
  };

  window.addEventListener('online', handleOnline);
  window.addEventListener('offline', handleOffline);

  function handleOnline() {
    window.location.reload();
    toast.success((t('responseMsg.msg_networkON')));
  }

  function handleOffline() {
    toast.warning((t('responseMsg.msg_networkOFF')));
  }

  useEffect(() => {
    window.addEventListener('beforeunload', function () {
      let counter = Number(sessionStorage.getItem('tabCounter'));
      if (counter) {
        counter--;
        sessionStorage.setItem('tabCounter', counter.toString());
      }
    });

    if (typeof sessionStorage !== 'undefined') {
      let counter = Number(sessionStorage.getItem('tabCounter'));
      if (!counter) {
        counter = 0;
        sessionStorage.setItem('tabCounter', counter.toString());
      }
      counter++;
      sessionStorage.setItem('tabCounter', counter.toString());

      if (counter > 1) {
        const currentUrl = window.location.href;
        if (currentUrl.indexOf("/duplicatetab") == -1) {
          navigateToPage();
        }
      }
    }
  }, [window.location.href]); 

  useEffect(() => {
    const preventNavigation = (event: PopStateEvent) => {
      if ((window.location.pathname === "/login" || window.location.pathname === "/LoginOtp") && isAuthenticated().roles.length) {
        event.preventDefault();
        window.history.go(1);
        toast.info(t("lbl_alreadyLoggedIn"));
      }
    };

    window.addEventListener('popstate', preventNavigation);

    return () => {
      window.removeEventListener('popstate', preventNavigation);
    };
  }, [window.location.href, isAuthenticated()]);

  return (
    <div className="App">
      <ToastContainer
        position='top-right' autoClose={3000} hideProgressBar={true} limit={1} theme="colored" className="cust-toast" />
      {/* <ResponsiveDrawer /> */}
      <AllRoutes />
    </div>
  );
}

export default App;
