import React, { useState } from 'react'
import { Route, Routes, } from "react-router-dom";
import { Iroutes, ROLE } from '../Interfaces/IConstants';
import Login from '../Pages/auth/Login';
import LoginOtp from '../Pages/auth/LoginOtp';
import CallScreen from '../Pages/CallScreen/CallScreen';
import SetNewPassword from '../Pages/auth/SetNewPassword';
import LoginLayout from '../Pages/Layout/LoginLayout';
import DuplicateTab from '../Pages/Layout/DuplicateTab';
import ResponsiveDrawer from '../Pages/Layout/PermanentDrawerLeft';
import PageNotFound from '../Pages/Unauthorized/PageNotFound';
import GetUser from './GetUser';
import PrivateRoute from './PrivateRoutes';
import routes from './routes';
import CallQuality from '../Pages/feedback/CallQuality';
import FeedbackReplay from '../Pages/feedback/FeedbackReplay';
import ForgotPassword from '../Pages/auth/ForgotPassword';
import ResetUserPassword from '../Pages/auth/ResetPassword';
import WebAssistantJoin from '../Pages/CallScreen/WebAssistantJoin';
import WebAssistantCallScreen from '../Pages/CallScreen/WebAssistantCallScreen';

const AllRoutes = () => {
  const [userId, setUserId] = useState<number | null>(null);

  return (
    <Routes>
      <Route path="/" element={<GetUser />} />
      <Route path="/" element={<LoginLayout />}>
        <Route path='/login' element={<Login setUserId={setUserId} />} />
        <Route path="/LoginOtp" element={<LoginOtp userId={userId} />} />
        <Route path="/setNewPassword/:userId" element={<SetNewPassword />} />
        <Route path="/resetPassword/:userId" element={<ResetUserPassword />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/webAssistantJoin/:sessionId" element={<WebAssistantJoin/>} />
      </Route>
      <Route path="/duplicatetab" element={<DuplicateTab />}/>
      <Route path='/webassistantcall' element={<WebAssistantCallScreen />}/>
      <Route path='/call' element={<PrivateRoute roles={[ROLE.Consultant]} ><CallScreen /></PrivateRoute>} />
      <Route path='/callquality' element={<PrivateRoute roles={[ROLE.Consultant]} ><CallQuality /></PrivateRoute>} />
      <Route path='/feedback' element={<PrivateRoute roles={[ROLE.Consultant]} ><FeedbackReplay /></PrivateRoute>} />
      {/* <Route path='/callSessions' element={<CallSessions username="" />} /> */}
      <Route path="/dashboard" element={<ResponsiveDrawer />} >
        {routes.map((route: Iroutes, i) => (
          <Route
            path={route.path}
            key={i}
            element={
              <PrivateRoute roles={route.roles}>
                {route.children}
              </PrivateRoute>
            }
          />
        ))}
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}

export default AllRoutes