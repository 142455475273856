import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { ContextProvider } from './context/SocketContext';
import "typeface-poppins";
import { UserRoleContextProvider } from './context/UserRoleContext';
import { ThemeProvider, createTheme, Theme } from '@mui/material';
import { themeOptions } from './theme/custTheme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme:Theme = createTheme(themeOptions)
root.render(
  // <React.StrictMode>
  <Suspense fallback="loading">
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ContextProvider>
          <UserRoleContextProvider>
            <App />
          </UserRoleContextProvider>
        </ContextProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Suspense>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
