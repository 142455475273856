import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import React from 'react'

const CadisDialog = (props: any) => {
  return (
    <>
      <Dialog
        fullWidth
        maxWidth={'md'}
        open={props.open}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{ sx: { position: "fixed", top: 30, m: 0 } }}>
        
        <DialogContent className='incoming-call-dialog-contents'>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md>
            <Typography fontWeight={"bold"} fontSize={"large"}>
              {props.assistantName}
            </Typography>
            <Typography>{props.title}</Typography>
          </Grid>
          <Grid item xs={12} md="auto">
          {props.DialogActions}
          </Grid>
        </Grid>
        </DialogContent>


      </Dialog>
    </>
  )
}

export default CadisDialog