import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const preventBackNavigation = (targetLocation: string) => {

    const navigate = useNavigate();
    const [navigationBackPopup, setNavigationBackPopup] = useState(false);

    useEffect(() => {

        let historyCurrentLocaltion = "";

        const handlePopState = (event: PopStateEvent) => {
            event.stopPropagation();
            // According to good practice we should not update the state like this, but due to side effects,
            // I am updating the state that is assigned to the variable.
            const confirmNavigation = setNavigationBackPopup(true);
            if (confirmNavigation as any) {
                window.removeEventListener('popstate', handlePopState);
                navigate(targetLocation)
            } else {
                window.history.pushState({ page: "current" }, '', historyCurrentLocaltion);
            }
        };

        historyCurrentLocaltion = window.location.href;
        window.history.pushState({ page: "current" }, '', historyCurrentLocaltion);
        window.removeEventListener('popstate', handlePopState);
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };

    }, []);

    return { navigationBackPopup, setNavigationBackPopup };
};

export default preventBackNavigation;