export const OrganizationListRoute = "/org/allOrganizations/";
export const OrganizationCreateRoute = "/org/addOrganization";
export const OrganizationUpdateRoute = "/org/updateOrganization";
export const OrganizationDeleteRoute = "/org/deleteOrganization";
export const AllUserListByRoleRoute = "/user/getUserList";
export const CreateUserRoute = "/user/createUser";
export const UpdateUserRoute = "/user/updateUser";
export const ResetPassword = "/user/requestPassword";
export const ConfirmPassword = "/user/confirmPassword";
export const ChangeUserPassword = "/user/changePassword";
export const GetSpecialityList = "/user/getSpecialityList";
export const verifyUser = "/auth/verifyUser";
export const verifyOtp = "/auth/verifyOtp";
export const resendOtp = "auth/resendOtp";
export const DeleteUserRoute = "/user/deleteUser";
export const GetUserCallSessionsRoute = "/user/userCallHistory";
export const SendQRCodeToAssistantRoute = "/user/sendAssistantQrCode";
export const GetOrganizationsCallHistoryRoute = "/org/callHistory/";
export const GetUserRoute = "/user/getUser";
export const ReactivateUser = "/user/reactivateUser";
export const LogoutUserRoute = "/user/logoutUser";
export const UserSessionExpireRoute = "/user/sessionExpired";
export const UserSessionStatusRoute = "/user/checkSessionStatus";
export const ResetTokenRoute = "/user/resetToken";
export const GetDeletedUsersByOrg = "/user/getDeletedUsersOfOrg";
export const GetDeletedOrgs = "/org/getDeleteOrganizations/";
export const GetAllFeatures = "/subscription/getAllSubscriptionFeatures";
export const AddSubscriptionPlan = "/subscription/addPlan";
export const UpdateSubscriptionPlan = "/subscription/updatePlan";
export const GetSubscriptionPlan = "/subscription/getPlans";
export const GetAllBillingCycle = "/subscription/getAllBillingCycle";
export const AssignSubscriptionPlanToOrgRoute = "/subscription/addSubscriptionPlanForOrg";
export const UpdateSubscriptionPlanForOrgRoute = "/subscription/updateSubscriptionPlanForOrg";
export const RenewSubscriptionPlanForOrgRoute = "/subscription/renewSubscriptionPlanForOrg";
export const GetOrganizationSubscriptionDetailsRoute = "/subscription/getAllSubscriptionPlanByOrgId";
export const GetAllSubscribedUsesofOrgRoute = "/subscription/getAllUsersOfSubscriptionOfOrg";
export const ApplyDiscountCouponRoute = "/subscription/applyDiscountCheck";
export const GetAllUsersOfOrg = "/subscription/getAllUsersOfOrg";
export const AddUserInSubscription = "/subscription/assignUsersToSubscription";
export const RemoveUserInSubscription = "/subscription/unassignUsersFromSubscription";
export const UpdateReminderDate = "/subscription/updateReminderDate";
export const GetUserCreationCount = "/user/getUserCreationCount";
export const SendRoomInviteLink = "/conference/sendRoomInviteLink";
export const SendRoomInviteLinkByMessage = "/conference/sendRoomInviteLinkByMessage";
export const SendRoomInviteLinkToSelf = "/conference/sendRoomInviteLinkToSelf";
export const SelectedSubscriptionDetails = "/subscription/makePayment";
export const UpdatePaymentStatus = "/subscription/updatePaymentStatus";
export const AllSubscribedUserList = "/user/getSubscribedAssistantList";
export const GetAllDiscountCouponCOde = "/subscription/getAllDiscountCouponCodeList";
export const AddDiscountCouponCOde = "/subscription/addDiscount";
export const UpdateDiscountCouponCOde = "/subscription/updateDiscountByDiscountId";
export const DeleteDiscountCouponCOde = "/subscription/deleteDiscountCode";
export const DeleteSubscriptionPlanRoute = "/subscription/deleteSubscriptionById";
export const AddOnDataPackRoute = "/subscription/addSubscriptionDataAddon";
export const UpdateAddOnDataPackRoute = "/subscription/updateSubscriptionDataAddon";
export const DeleteAddOnDataPackRoute = "/subscription/deleteSubscriptionDataAddon?addonId=";
export const GetAllAddonPackList = "/subscription/getAllSubscriptionDataAddon";
export const BuyDataAddOnPack = "/subscription/buySubscriptionDataAddon";
export const AssignAddonDataToPlan = "/subscription/buyOfflineSubscriptionDataAddon";
export const SubscriptionDataUsage = "/subscription/subscriptionDataUsage";
export const getSubscriptionDataUsage = "/subscription/getSubscriptionDataUsage";
export const getUserSubscriptionStatus = "/user/getUserSubscriptionStatus?userId=";
export const webAssistantSubscriptionDataUsage = "/subscription/webAssistantSubscriptionDataUsage";
export const getExpiredPlanRolledOverData = "/subscription/getRolledOverDataBySubscriptionId";
