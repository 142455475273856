import { Avatar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, ListItem, ListItemAvatar, ListItemText, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import CadisButton from "../../Components/Input/CadisButton";
import { GetGridNoRecordMessage, GetNonDeletedUser, formatPhoneNumber, isAuthenticated, searchFilterFunction, statusSortComparator, stringAvatar } from "../../Common/helperFunction";
import { ActiveStatus, ActiveStatusID, ButtonActionAdd, ButtonActionDelete, ButtonActionEdit, ButtonActionSend, ButtonReactivate, countries, currentHost, DefaultCadisAdmin, DefaultGridRowSize, FormAddMode, FormUpdateMode, FormViewMode, GridRowsPerPageOptions, InActiveStatus, InActiveStatusID } from "../../Common/helper";
import { IUser } from "../../Interfaces/IUser";
import CadisLoader from "../../Components/Loading/CadisLoader";
import { toast } from "react-toastify";
import { AllUserListByRoleRoute, DeleteUserRoute, ResetPassword } from "../../Common/api.routes";
import apis from "../../HttpConfig/Api";
import AddUpdateOrganizationAdmin from "./AddUpdateOrganizationAdmin";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ROLE, ROLENAMES } from "../../Interfaces/IConstants";
import { GridStyling } from "../../Common/sharedStyling";
import { auto } from '@popperjs/core';
import { useUserRoleContext } from "../../context/UserRoleContext";
import { useTranslation } from "react-i18next";

const OraganizationAdminList = () => {
    const { handleToggleLoader } = useUserRoleContext();
    const user = isAuthenticated();
    const userRole = ROLENAMES.OrgAdmin;
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState<number>(DefaultGridRowSize);
    const [orgAdminGridData, setOrgAdminGridData] = useState<any[]>([]);
    const [addOrgAdminDrawer, setAddOrgAdminDrawer] = useState<boolean>(false);
    const [orgAdminCreationMode, setOrgAdminCreationMode] = useState(FormViewMode);
    const [orgAdminListFlow, setOrgAdminListFlow] = useState('orgAdminFlow');
    const [currentOrgAdminData, setCurrentOrgAdminData] = useState<IUser>(DefaultCadisAdmin);
    const [openSendEmailDialog, setOpenSendEmailDialog] = useState({ 'open': false, 'user_id': '' });
    const [consultantSpecialities, setconsultantSpecialities] = useState([{ 'speciality_id': 0, 'speciality': '' }])
    const [searchQuery, setSearchQuery] = useState([]);
    const [data, setData] = useState([]);
    const [showAllOrgAdminData, setShowAllOrgAdminData] = useState([]);
    const [showNonDeletedOrgAdminData, setShowNonDeletedOrgAdminData] = useState([]);
    const { t } = useTranslation();
    const [openReactivateOrgAdminDialog, setOpenReactivateOrgAdminDialog] = useState({
        'open': false,
        'UserID': null,
        'UserName': ""
    });

    const [openDeleteDialog, setOpenDeleteDialog] = useState({
        'open': false,
        'UserID': null,
        'OrgAdminName': ""
    });

    const handleCloseDelete = () => {
        setOpenDeleteDialog({
            'open': false,
            'UserID': null,
            'OrgAdminName': ""
        });
    }

    //#region SEND PASSWORD LINK FUNCTIONALITY
    const handleSendPasswordPopup = (userId: string) => {
        setOpenSendEmailDialog({
            'open': true,
            'user_id': userId
        });
    }

    const handleCloseSendEmailDialog = () => {
        setOpenSendEmailDialog({
            'open': false,
            'user_id': ''
        });
    }

    const handleSendPassword = async (userId: string) => {
        setLoading(true);
        const data = {
            'user_name': userId,
            'url': currentHost + "/setNewPassword"
        }
        try {
            await apis.instance.post(`${ResetPassword}`, data)
                .then((resp: any) => {
                    if (resp.data.success) {
                        handleCloseSendEmailDialog();
                        toast.success("A link to reset password has been sent to registered email id.");
                        setLoading(false);
                    } else {
                        toast.error(resp.data.error);
                    }
                })
                .finally(() => setLoading(false));
        } catch (error: any) {
            setLoading(false);
            toast.error(t('responseMsg.msg_wrongEntry'));
            console.log(error.message);
        }
    }
    //#endregion 

    const columns: GridColDef[] = [
        { field: 'user_id', width: 1, hide: true, },
        { field: 'user_title', width: 1, hide: true, },
        { field: 'user_fname', width: 1, hide: true, },
        { field: 'user_lname', width: 1, hide: true, },
        {
            field: 'full_name', headerName: 'Name', minWidth: 200, flex: 1, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: true,
            renderCell: ({ row }: any) => {
                return <>
                    <ListItem disablePadding>
                        <ListItemAvatar>
                            <Avatar {...stringAvatar(row.full_name)} src={row.user_profile_img} />
                        </ListItemAvatar>
                        <Tooltip title={`${row.full_name}`} >
                            <ListItemText sx={{ textTransform: 'capitalize', }} className="long-col-name-ellipsis"
                                primary={`${row.full_name}`}
                                secondary=""
                            />
                        </Tooltip>
                    </ListItem>
                </>
            },
        },
        {
            field: 'user_email', headerName: 'Email', minWidth: 300, flex: 1, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: false,
            renderCell: ({ row }: any) => (
                <Tooltip title={row.user_email} >
                    <span className="table-cell-trucate">{row.user_email}</span>
                </Tooltip>
            ),
        },
        {
            field: 'phone_number_m', headerName: 'Phone', minWidth: 130, flex: 1, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: false, renderCell: ({ row }: any) => {
                const phoneNumber = row.phone_number_m;
                const phoneNumberTwo = row.phone_number_nm;
                return <Stack direction="column" textAlign={'left'}>
                    <Box>{formatPhoneNumber(phoneNumber)}</Box>
                    <Box>{formatPhoneNumber(phoneNumberTwo)}</Box>
                </Stack>
            }
        },
        {
            field: 'user_status', headerName: 'Status', minWidth: 100, flex: 1, headerClassName: 'app-theme-header', renderCell: ({ row }: any) => {
                return <>
                    <Chip size="small" label={row.status}
                        color={row.user_password == "false" && row.user_status === 0 ? 'warning' : row.user_status === 1 ? 'success' : 'error'}></Chip>
                </>
            }, valueGetter: (params) => params.row.status, sortComparator: statusSortComparator
        },
        {
            field: 'actions', headerName: 'Actions',
            minWidth: 180, headerClassName: 'app-theme-header bg-action',
            flex: 1, cellClassName: 'app-theme-cell bg-action', sortable: false,
            headerAlign: 'center', align: 'center', renderCell: (params: any) => {
                return <Stack direction="row" spacing={0}>
                    {/* {params.row.user_is_deleted === 0 ? <> */}

                    <Box className='grid-act-btn'>
                        <Tooltip title={t('lbl_resendPasswordLink')} placement="left">
                            <IconButton aria-label="AdminIcon" id={ButtonActionSend} onClick={(e) => {
                                onClick(e, params)
                            }} color="primary">
                                <span className='cad-send'></span>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {/* left empty box for adding space between org user and other actions */}
                    <Box className='grid-act-btn'></Box>
                    <Box className='grid-act-btn'>
                        <Tooltip title={t('lbl_editOrgAdmin')} placement="bottom-end">
                            <IconButton aria-label="AdminIcon" id={ButtonActionEdit} onClick={(e) => {
                                onClick(e, params)
                            }} color="primary"><span className='cad-edit'></span>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box className='grid-act-btn'>
                        {
                            (
                                params.row.user_status === InActiveStatusID &&
                                params.row.user_id !== user.userId && params.row.user_password == "true"
                            ) ?
                                <Tooltip title={t('lbl_hideOrgAdmin')} placement="bottom-end">
                                    <IconButton aria-label="DeleteOrgIcon"
                                        id={ButtonActionDelete} onClick={(e) => {
                                            onClick(e, params)
                                        }} color="error">
                                        <span className='cad-archive'></span>
                                    </IconButton>
                                </Tooltip>
                                : null
                        }
                    </Box>
                </Stack>
            }
        }
    ]

    const settingData = (data: any) => {
        const nonDeletedOrgAdmin = GetNonDeletedUser(data, user.userId);
        // mapping status key based on condition 
        const NewStatusUpdatedArray = nonDeletedOrgAdmin.map((row: any) => {
            return { ...row, status: row.user_password == "false" ? "Verification Pending" : row.user_status === ActiveStatusID ? ActiveStatus : InActiveStatus }
        })
        const sortedDataByFullnameThenByStatus = SortDataByIntialThenBySecondaryKeys(NewStatusUpdatedArray, "userFullName", "user_status");
        setShowNonDeletedOrgAdminData(sortedDataByFullnameThenByStatus);
        setOrgAdminGridData(NewStatusUpdatedArray);
        setData(sortedDataByFullnameThenByStatus);
    }

    const SortDataByIntialThenBySecondaryKeys = (data: any[], initialSortKey: string, secondarySortKey: string) => {
        const updatedData = data.map(obj => ({ ...obj, userFullName: `${obj.user_fname} ${obj.user_lname}` }))
        const sortedData: any = updatedData.sort(function (a, b) {
            if (a.userFullName < b.userFullName) { return -1; }
            if (a.userFullName > b.userFullName) { return 1; }
            return 0;
        }).sort(function (x, y) {
            if (x.user_status > y.user_status) { return -1; }
            if (x.user_status < y.user_status) { return 1; }
            return 0;
        }).sort(function (p, q) {
            if (p.user_is_deleted < q.user_is_deleted) { return -1; }
            if (p.user_is_deleted > q.user_is_deleted) { return 1; }
            return 0;
        }).sort(function (m, n) {
            if (n.user_password === "false" && n.user_status === 0) { return 1; }
            if (m.user_password === "false" && m.user_status === 0) { return -1; }
            return 0;
        });
        return sortedData;
    }

    const onClick = (e: any, params: any) => {
        e.stopPropagation(); // don't select this row after 
        OnActionClick(e.currentTarget.id, params?.row);
    };

    //#region Action Buttons Click
    const OnActionClick = (actionId: string, row: any) => {
        switch (actionId) {
            case ButtonActionAdd:
                DefaultCadisAdmin.org_id = user.orgId;
                setOrgAdminCreationMode(FormAddMode);
                setCurrentOrgAdminData(DefaultCadisAdmin);
                setAddOrgAdminDrawer(true);
                break;
            case ButtonActionEdit:
                setOrgAdminCreationMode(FormUpdateMode);
                const cadisAdminToEdit = {
                    'org_id': row.org_id,
                    'org_name': "",
                    'user_id': row.user_id,
                    'org_admin_title': row.user_title,
                    'org_admin_fname': row.user_fname ?? "",
                    'org_admin_lname': row.user_lname ?? "",
                    'org_admin_email': row.user_email ?? "",
                    'org_admin_username': row.user_name ?? "",
                    'org_admin_phone_m': row.phone_number_m,
                    'org_admin_phone_nm': row.phone_number_nm ?? "",
                    'org_admin_status': row.user_status,
                    'orgAdminSendDetailsInEmail': true,
                    'specialities': [],
                    'profile_image': row.user_profile_img ?? "",
                    "country_code": row.country_code ?? countries[0].code,
                    "set_password_url": "",
                    "assign_role_to_user": row.Roles.length > 1,
                    "newAssignedRole": row.Roles.length > 1 ? row.Roles[1] : ROLE.Consultant,
                    "isPasswordSet": row.user_password === "true" ? true : false
                }
                setCurrentOrgAdminData(cadisAdminToEdit);
                setAddOrgAdminDrawer(true);
                break;
            case ButtonActionSend:
                handleSendPasswordPopup(row.user_name);
                break;
            case ButtonActionDelete:
                setOpenDeleteDialog({
                    'open': true,
                    'UserID': row.user_id,
                    'OrgAdminName': row.user_fname + " " + row.user_lname
                });
                break;
            case ButtonReactivate:
                setOpenReactivateOrgAdminDialog({
                    'open': true,
                    'UserID': row.user_id,
                    'UserName': row.user_fname + " " + row.user_lname ?? ''
                });

                break;
            default:
                break;
        }
    }
    //#endregion

    // dynamic scrollbar for grid view start
    const PageTitleRef: any = useRef();
    const tableGridRef: any = useRef();
    const [tableHeight, setTableHeight] = useState<any>(
    );

    const getPageTitleHeight = () => {
        let pageTitleHeight: any = 0;
        if (PageTitleRef && PageTitleRef.current) {
            pageTitleHeight = PageTitleRef.current.clientHeight;
        }
        setTableHeight(window.innerHeight - pageTitleHeight - 82);
    };

    window.addEventListener("resize", getPageTitleHeight);

    //#region GET CADIS ADMIN LIST
    const GetAllOrgAdmins = async () => {
        handleToggleLoader(true);
        const params = {
            'orgID': user.orgId,
            'role': ROLE.OrgAdmin
        }
        await apis.instance.get(`${AllUserListByRoleRoute}`, { params })
            .then((resp: any) => {

                let response = resp?.data?.data.map((item: any) => {
                    return {
                        ...item,
                        full_name: item.user_title === "None" ? `${item.user_fname} ${item.user_lname ?? ""}` : `${item.user_title} ${item.user_fname} ${item.user_lname ?? ""}`
                    }
                })
                settingData(response)
                handleToggleLoader(false);
            })
            .catch(err => {
                handleToggleLoader(false);
                if (err?.response.status !== 401) toast.error(err.response?.data?.message)
                console.log(err)
            })
            .finally(() => setLoading(false));
    }
    //#endregion

    useEffect(() => {
        GetAllOrgAdmins();
        getPageTitleHeight();
    }, []);

    const DeleteOrgAdmin = async () => {
        const orgAdminData = {
            'userID': openDeleteDialog.UserID
        }
        try {
            await apis.instance.put(`${DeleteUserRoute}`, orgAdminData)
                .then((resp: any) => {
                    if (resp.data.success) {
                        handleCloseDelete();
                        GetAllOrgAdmins()
                        toast.success(t('responseMsg.msg_orgAdminDeleted'));
                    } else {
                        toast.error(resp.data.error);
                    }
                })
                .finally();
        } catch (error: any) {
            toast.error(t('responseMsg.msg_wrongEntry'));
            console.log(error.message);
        }
    }
    // for filtering the DataGrid table by search 
    const handleSearchInputChange = (event: any) => {
        let value = event.target.value;
        setSearchQuery(value);

        if (event.target.value !== "") {
            let filterData: any = searchFilterFunction(data, value)
            setOrgAdminGridData(filterData)
        }
        else if (event.target.value === "") {
            setOrgAdminGridData(data)
        }
    }

    return (
        <div>
            <Box ref={PageTitleRef} className="page-title-row pb-3">
                <Grid container alignItems={'center'} textAlign={'left'} rowSpacing={2} columnSpacing={{ xs: 2, md: 0 }}>
                    <Grid item xs={12} md={auto} mr={auto} sm padding={0} className="page-title-box">
                        <Typography className='page-title mt-0 mb-0' sx={(theme) => theme.typography.h5}>{t('lbl_titleOrgAdministrators')}</Typography>
                    </Grid>
                    <Grid item xs={12} md={auto} padding={0}>
                        <Button id={ButtonActionAdd}
                            onClick={(e) => {
                                onClick(e, null)
                            }} startIcon={<span className='cad-plus'></span>}
                            variant="contained" sx={{ my: .5, mr: 1 }}>{t('lbl_createOrgAdmin')}</Button>

                        <TextField className="cadis-search"
                            id="outlined-end-adornment"
                            size="small"
                            type="search" placeholder="Search"
                            sx={{ width: '18ch', my: .5 }}
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><span className="cad-search"></span></InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={GridStyling} ref={tableGridRef} height={tableHeight}>
                <DataGrid
                    components={{
                        NoRowsOverlay: GetGridNoRecordMessage,
                    }}
                    rows={orgAdminGridData}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                    rowsPerPageOptions={GridRowsPerPageOptions}
                    getRowId={(row: any) => row.user_id}
                    autoHeight={false}
                    autoPageSize={false}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    disableSelectionOnClick={true}
                />
            </Box>
            {orgAdminCreationMode !== FormViewMode ? <AddUpdateOrganizationAdmin
                isOpenAddUpdateAdmin={addOrgAdminDrawer}
                setIsOpenAddUpdateAdminExpandAdminData={setAddOrgAdminDrawer}
                mode={orgAdminCreationMode}
                currentOrgAdminData={currentOrgAdminData}
                getOrgUserList={GetAllOrgAdmins}
                userRole={userRole}
                consultantSpecialities={consultantSpecialities}
                userFlow={orgAdminListFlow}
            /> : null}

            <Dialog
                open={openDeleteDialog.open}
                onClose={() => { return null }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('lbl_confirmation')}
                </DialogTitle>
                <DialogContent>
                    {t('lbl_confirmationForHide')} {openDeleteDialog.OrgAdminName}?
                </DialogContent>
                <DialogActions>
                    <CadisButton onClick={DeleteOrgAdmin} variant="contained" color='error'>
                        {t('lbl_yes')}
                    </CadisButton>
                    <CadisButton onClick={handleCloseDelete} variant="contained" color='success'>
                        {t('lbl_no')}
                    </CadisButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openSendEmailDialog.open}
                onClose={() => { return null }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('lbl_confirmation')}
                </DialogTitle>
                <DialogContent>
                    {t('lbl_askForNewLinkToSetNewPassword')}
                </DialogContent>
                <DialogActions>
                    <CadisButton variant="contained" color='primary' onClick={() => handleSendPassword(openSendEmailDialog.user_id)} disabled={loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                        {t('lbl_yes')}
                    </CadisButton>
                    <CadisButton variant="contained" color='error' onClick={handleCloseSendEmailDialog} disabled={loading}>
                        {t('lbl_no')}
                    </CadisButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default OraganizationAdminList