import { Grid, Paper } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useEffect, useRef, useState } from "react";
import { Outlet } from 'react-router-dom';
import CadisLogoVertical from '../../Assets/Images/Logo/CadisLogoVertical.svg';
import { auto } from '@popperjs/core';

const logoBoxHeight = 90;
const LoginLayout = () => {
  // logo container height
  const preLoginLogoContainerRef = useRef<HTMLDivElement>(null);
  let logoBoxHeight = 90;
  useEffect(() => {
    if (preLoginLogoContainerRef.current) {
      logoBoxHeight = preLoginLogoContainerRef.current.clientHeight;
    }
  }, []);

  return (
    <Box bgcolor='#f2f2f2' className='prelogin-background' height={'100%'}>
      <Grid container direction={{ xs: 'column', md: 'row' }} height={'inherit'}>
        <Grid item xs={auto} md={auto} display={{ xs: 'flex', md: 'block' }} justifyContent={{ xs: 'center', md: 'start' }} ref={preLoginLogoContainerRef}>
          <Stack direction={'column'}>
            <Box sx={{ pl: { md: 3 } }} alignItems="center"
              className='app-logo-prelogin'>
              <img src={CadisLogoVertical} />
            </Box>
          </Stack>
        </Grid>

        <Grid item xs className='prelogin-form-grid' height={{ xs: `calc(100% - ${logoBoxHeight}px)`, md: '100%' }}  >
          <Grid container direction={{ xs: 'column', md: 'row' }}
            display={'flex'} alignItems="center" spacing={0}
            sx={{ flexGrow: 1, height: '100%' }}>

            <Grid container justifyContent={{ xs: 'center', md: 'start' }} alignItems="center" sx={{ maxHeight: '100%' }} height='auto' padding={1}>

              <Grid item xs={10} sm={7} md={6} lg={4} xl={4} height='100%' sx={{ maxHeight: '100%' }}>
                <Paper elevation={4} sx={{ px: 3, py: 4, my: 0, textAlign: 'left', maxHeight: 'inherit', overflowY: auto, boxSizing: 'border-box', opacity: 0.9 }}>
                  <Outlet />
                </Paper>
              </Grid>

            </Grid>

          </Grid>
        </Grid>

      </Grid>
    </Box>
  )
}

export default LoginLayout