import { Box, Container, Drawer, FormControl, Grid, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ChangeUserPassword } from "../../Common/api.routes";
import { ChangePasswordValidationSchema } from "../../Common/validationSchemas";
import CadisButton from "../../Components/Input/CadisButton";
import CadisTextField from "../../Components/Input/CadisTextField";
import CadisLoader from "../../Components/Loading/CadisLoader";
import apis from "../../HttpConfig/Api";

const ChangePassword = ({ openChangePasswordDrawer, setOpenChangePasswordDrawer }: { openChangePasswordDrawer: boolean, setOpenChangePasswordDrawer: any }) => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setshowConfirmPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setshowConfirmPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    //useFormik
    const { values, handleChange, handleSubmit, errors, touched, handleReset, resetForm } = useFormik({
        initialValues: {
            currentpassword: '',
            password: '',
            confirmpassword: ''
        },
        onSubmit: async (values, props) => {
            setLoading(true);
            setPasswordCall();
        },
        validationSchema: ChangePasswordValidationSchema
    });

    const setPasswordCall = async () => {
        const data = {
            oldPassword: values.currentpassword,
            newPassword: values.password
        }
        try {
            const response = await apis.instance.post(`${ChangeUserPassword}`, data)

            if (response.data?.success) {
                handleReset(values);
                setOpenChangePasswordDrawer(false);
                toast.success(t('responseMsg.msg_changePasswordSuccessful'))
                setLoading(false)
            } else {
                if (response.data.error.toLowerCase() === "old password does not match")
                    toast.warning(t('validation.msg_invalidCurrentPassword'));
                else {
                    toast.error(response.data.error);
                }
                setLoading(false);
            }
        } catch (error: any) {
            toast.error(error.message);
            setLoading(false);
        }
    }
    
    const handleCharacterLength = (e: any) => {
        if (e.target.value.length <= 15) {
            handleChange(e)
        }
    }

    return (
        <>
            <Drawer
                anchor='right'
                open={openChangePasswordDrawer}
                onClose={() => { return null }} sx={{}}
            >
                <Box role="presentation">
                    <Box sx={{ pt: 1.5, position: 'sticky', top: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }} >
                        <Container sx={{ alignItems: 'center', display: 'flex' }} disableGutters>
                            <Box sx={{ width: '40px', height: '40px', flexGrow: 1, display: { xs: 'flex', pr: 2 } }}>
                                <IconButton
                                    onClick={() => {
                                        resetForm()
                                        setOpenChangePasswordDrawer(false)
                                    }}
                                    sx={{}} className='c-pointer'>
                                    <span className='cad-close'></span>
                                </IconButton>
                            </Box>
                        </Container>
                        <Box sx={{ mb: 5, mt: 2, display: 'flex' }}>
                            <Typography className="mt-0 mb-0 slide-right-title" sx={(theme) => theme.typography.h5}>
                                {t('lbl_change_password')}
                            </Typography>
                        </Box>
                    </Box>
                    <Grid container width={'100%'}>
                        <form onSubmit={handleSubmit} onReset={handleReset} style={{ width: '100%' }}>
                            <Stack spacing={2} direction={'column'} paddingX={'20px'}>
                                <FormControl variant="outlined">
                                    <label className='form-label '>
                                        {t('lbl_current_password')}
                                        <span className='mandatory-star'></span>
                                    </label>
                                    <CadisTextField
                                        autoComplete="off"
                                        fullWidth
                                        id="currentpassword"
                                        name="currentpassword"
                                        type={showPassword ? 'text' : 'password'}
                                        value={values.currentpassword.trim()}
                                        onChange={(e) => { handleCharacterLength(e) }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <span className='cad-eye'></span> : <span className='cad-eye-off'></span>}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        error={touched.currentpassword && Boolean(errors.currentpassword)}
                                        helperText={touched.currentpassword && t(`${errors.currentpassword ? errors.currentpassword : ''}`)}
                                    />
                                </FormControl>

                                <FormControl variant="outlined">
                                    <label className='form-label '>
                                        {t('lbl_new_password')}
                                        <span className='mandatory-star'></span>
                                    </label>
                                    <CadisTextField
                                        autoComplete="off"
                                        id="password"
                                        name="password"
                                        type={showNewPassword ? 'text' : 'password'}
                                        value={values.password.trim()}
                                        onChange={(e) => { handleCharacterLength(e) }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowNewPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showNewPassword ? <span className='cad-eye'></span> : <span className='cad-eye-off'></span>}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        error={touched.password && Boolean(errors.password)}
                                        helperText={touched.password && t(`${errors.password ? errors.password : ''}`)}
                                    />
                                </FormControl>
                                <FormControl variant="outlined">
                                    <label className='form-label '>
                                        {t('lbl_confirm_password')}
                                        <span className='mandatory-star'></span>
                                    </label>
                                    <CadisTextField
                                        autoComplete="off"
                                        id="confirmpassword"
                                        name="confirmpassword"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        value={values.confirmpassword.trim()}
                                        onChange={(e) => { handleCharacterLength(e) }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowConfirmPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showConfirmPassword ? <span className='cad-eye'></span> : <span className='cad-eye-off'></span>}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        error={touched.confirmpassword && Boolean(errors.confirmpassword)}
                                        helperText={touched.confirmpassword && t(`${errors.confirmpassword ? errors.confirmpassword : ''}`)}
                                    />
                                </FormControl>
                            </Stack>
                            <Box sx={{ pt: 2, pb: 2, position: 'sticky', bottom: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }}>
                                <Grid container spacing={0} alignItems="center"  >
                                    <Grid item xs="auto" >
                                        <CadisButton type='submit' variant="contained" color='primary' disabled={loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                                            {t('lbl_save')}
                                        </CadisButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    </Grid>
                </Box>
            </Drawer>
        </>
    )
}

export default ChangePassword