import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, ListItem, ListItemText, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { auto } from '@popperjs/core';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AllUserListByRoleRoute, OrganizationDeleteRoute, OrganizationListRoute } from '../../Common/api.routes';
import { ActiveStatus, ActiveStatusID, ButtonActionAdd, ButtonActionDelete, ButtonActionEdit, ButtonActionList, ButtonActionShow, ButtonActionView, ButtonSubscriptionDetails, DefaultGridRowSize, FormAddMode, GridRowsPerPageOptions, InActiveStatus, InActiveStatusID, navigateToSubscriptionDetails } from '../../Common/helper';
import { GetNonDeletedOrganization, GetNonDeletedUser, formatPhoneNumber, isAuthenticated } from "../../Common/helperFunction";
import { GridStyling } from '../../Common/sharedStyling';
import CadisButton from '../../Components/Input/CadisButton';
import apis from '../../HttpConfig/Api';
import { ROLE, ROLENAMES } from '../../Interfaces/IConstants';
import { IOrganization } from '../../Interfaces/IOrganization';
import { IUser } from '../../Interfaces/IUser';
import { useUserRoleContext } from '../../context/UserRoleContext';
import AddUpdateOrganization from './AddUpdateOrganization';
import OraganizationUserList from './OrganizationUserList';

const OraganizationList = () => {
  const { handleToggleLoader } = useUserRoleContext();
  const { userId } = isAuthenticated();
  const [loading, setLoading] = useState(false);
  const [addOrgDrawer, setAddOrgDrawer] = useState(false);
  const [orgCreationMode, setOrgCreationMode] = useState(FormAddMode);
  const [pageSize, setPageSize] = useState<number>(DefaultGridRowSize);
  const [orgGridData, setOrgGridData] = useState([]);
  const [data, setData] = useState([]);
  const [userRole, setUserRole] = useState<string>();
  const [showAllOrgData, setShowAllOrgData] = useState([]);
  const [showNonDeletedOrgData, setShowNonDeletedOrgData] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [currentOrganization, setcurrentOrganization] = useState<IOrganization>({
    'org_id': "",
    'org_name': " ",
    'org_email': " ",
    'org_phone_m': " ",
    'org_phone_nm': " ",
    'org_status': InActiveStatusID,
    'org_is_deleted': InActiveStatusID
  });

  const [openDeleteOrgDialog, setOpenDeleteOrgDialog] = useState({
    'open': false,
    'org_id': null,
    'org_name': ""
  });

  const [orgUserGridData, setOrgConsultantGridData] = useState<IUser[]>([]);
  const [showOrgConsultantList, setshowOrgConsultantList] = useState(false);
  const [showOrgAdminForm, setShowOrgAdminForm] = useState(false);
  const [currentOrgMetaData, setCurrentOrgMetaData] = useState({
    "orgName": "",
    "orgID": 0
  });

  //#region Handle delete org
  const handleDeleteOrg = async () => {
    const orgdata = {
      'org_id': openDeleteOrgDialog.org_id
    }
    try {
      await apis.instance.post(`${OrganizationDeleteRoute}`, orgdata)
        .then((resp: any) => {
          if (resp.data.success) {
            handleCloseDeleteOrg();
            GetAllOrganizations();
            toast.success(t('responseMsg.msg_hideOrg'));
          } else {
            toast.error(resp.data.error);
          }
        })
        .finally(() => setLoading(false));
    } catch (error: any) {
      setLoading(false);
      toast.error(t('responseMsg.msg_wrongEntry'));
      console.log(error.message);
    }
  }

  const handleCloseDeleteOrg = () => {
    setOpenDeleteOrgDialog({
      'open': false,
      'org_id': null,
      'org_name': ""
    });
  }
  //#endregion

  const setOrgData = ({ data }: any) => {
    const sortedAllDataByNameThenByStatus = SortDataByIntialThenBySecondaryKeys(data, "org_name", "org_status");
    setShowAllOrgData(sortedAllDataByNameThenByStatus);

    const nonDeletedOrganizations = GetNonDeletedOrganization(data);
    const sortedDataByNameThenByStatus = SortDataByIntialThenBySecondaryKeys(nonDeletedOrganizations, "org_name", "org_status");
    setShowNonDeletedOrgData(sortedDataByNameThenByStatus);
    setOrgGridData(sortedDataByNameThenByStatus);
  }

  const handleSearchInputChange = (event: any) => {
    let value = event.target.value;
    setSearchQuery(value);

    if (event.target.value !== "") {
      let filterData: any = data.filter((row: any) => {
        return row.org_name.toLowerCase().includes(value.toLowerCase()) ||
          row.org_email.toLowerCase().includes(value.toLowerCase()) ||
          row.org_phone_m.toLowerCase().includes(value.toLowerCase())

      })
      setOrgGridData(filterData)
    }
    else if (event.target.value == "") {
      setOrgGridData(data)
    }
  }

  const SortDataByIntialThenBySecondaryKeys = (data: any[], initialSortKey: string, secondarySortKey: string) => {
    const sortedData: any = data.sort(function (a, b) {
      if (a.org_name < b.org_name) { return -1; }
      if (a.org_name > b.org_name) { return 1; }
      return 0;
    }).sort(function (x, y) {
      if (x.org_status > y.org_status) { return -1; }
      if (x.org_status < y.org_status) { return 1; }
      return 0;
    }).sort(function (p, q) {
      if (p.org_is_deleted < q.org_is_deleted) { return -1; }
      if (p.org_is_deleted > q.org_is_deleted) { return 1; }
      return 0;
    })
    return sortedData;
  }

  const columns: GridColDef[] = [
    { field: 'org_id', width: 0, hide: true, },
    {
      field: 'org_name', headerName: 'Name', minWidth: 170, flex: 1, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header',
      renderCell: ({ row }: any) => {
        return <>
          <ListItem disablePadding>
            <Tooltip title={`${row.org_name}`} >
              <ListItemText sx={{ textTransform: 'capitalize', }} className="long-col-name-ellipsis"
                primary={`${row.org_name}`}
                secondary=""
              />
            </Tooltip>
          </ListItem>
        </>
      },
    },
    {
      field: 'org_email', headerName: 'Email', minWidth: 270, flex: 1, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: false,
      renderCell: ({ row }: any) => (
        <Tooltip title={row.org_email} >
          <span className="table-cell-trucate">{row.org_email}</span>
        </Tooltip>
      ),
    },
    {
      field: 'org_phone_m', headerName: 'Phone', minWidth: 140, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: false, renderCell: ({ row }: any) => {
        const phoneNumber = row.org_phone_m;
        const phoneNumberTwo = row.org_phone_nm;
        return <Stack direction="column" textAlign={'left'}>
          <Box>{formatPhoneNumber(phoneNumber)}</Box>
          <Box>{formatPhoneNumber(phoneNumberTwo)}</Box>
        </Stack>
      }
    },
    {
      field: 'org_status', headerName: 'Status',
      cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', minWidth: 110, renderCell: ({ row }: any) => {
        return <>
          <Chip size="small" label={row.org_status === ActiveStatusID ? ActiveStatus : InActiveStatus} color={row.org_status === 1 ? 'success' : 'error'} />
        </>
      }
    },
    {
      field: 'actions', headerAlign: 'center',
      headerClassName: 'app-theme-header bg-action',
      cellClassName: 'app-theme-cell bg-action',
      flex: 1,
      headerName: 'Actions', align: 'center',
      minWidth: 320, sortable: false, renderCell: (params: any) => {
        return <Stack direction="row" spacing={0}>
          <Box className='grid-act-btn'>
            <Tooltip title={t('lbl_orgAdministrators')} placement="left">
              <IconButton aria-label="OrgAdminIcon"
                id={ButtonActionList} onClick={(e) => {
                  onClick(e, params)
                }} color="primary">
                <span className='cad-user-setting'></span>
              </IconButton>
            </Tooltip>
          </Box>

          <Box className='grid-act-btn'>
            <Tooltip title={t('lbl_orgConsultants')} placement="bottom-end">
              <IconButton aria-label="ConsultantsIcon"
                id={ButtonActionView} onClick={(e) => {
                  onClick(e, params)
                }} color="primary">
                <span className='cad-statoscope'></span>
              </IconButton>
            </Tooltip>
          </Box>

          <Box className='grid-act-btn'>
            <Tooltip title={t('lbl_orgAssistants')} placement="bottom-end">
              <IconButton aria-label="AssistantIcon"
                id={ButtonActionShow} onClick={(e) => {
                  onClick(e, params)
                }} color="primary">
                <span className='cad-assistant'></span>
              </IconButton>
            </Tooltip>
          </Box>
          <Box className='grid-act-btn'>
            <Tooltip title="View Subscription Details" >
              <IconButton aria-label="OrgAdminIcon"
                id={ButtonSubscriptionDetails} onClick={(e) => {
                  onClick(e, params)
                }} color="primary">
                <span className='cad-subscription-details'></span>
              </IconButton>
            </Tooltip>
          </Box>
          {/* left empty box for adding space between org user and other actions */}
          <Box className='grid-act-btn'></Box>
          <Box className='grid-act-btn'>
            <Tooltip title={t('lbl_editOrg')} placement="bottom-end">
              <IconButton aria-label="EditOrgIcon"
                id={ButtonActionEdit} onClick={(e) => {
                  onClick(e, params)
                }} color="primary">
                <span className='cad-edit'></span>
              </IconButton>
            </Tooltip>
          </Box>

          <Box className='grid-act-btn'>
            {params.row.org_status === InActiveStatusID ?
              <Tooltip title={t('lbl_hideOrg')} placement="bottom-end">
                <IconButton aria-label="DeleteOrgIcon"
                  id={ButtonActionDelete} onClick={(e) => {
                    onClick(e, params)
                  }} color="error">
                  <span className='cad-archive'></span>
                </IconButton>
              </Tooltip> : null
            }
          </Box>

        </Stack>
      }
    },

  ];

  //#region onClick column actions
  const onClick = (e: any, params: any) => {
    e.stopPropagation(); // don't select this row after 
    OnActionClick(e.currentTarget.id, params?.row);
  };
  //#endregion

  //#region Action Buttons Click
  const OnActionClick = (actionId: string, row: any) => {
    switch (actionId) {
      case ButtonActionAdd:
        setOrgCreationMode(FormAddMode);
        setcurrentOrganization({
          'org_id': "",
          'org_name': '',
          'org_email': '',
          'org_phone_m': '',
          'org_phone_nm': '',
          'org_status': InActiveStatusID,
          'org_is_deleted': InActiveStatusID
        });
        setAddOrgDrawer(true);
        break;
      case ButtonActionEdit:
        setOrgCreationMode('update');
        setcurrentOrganization(row);
        setAddOrgDrawer(true);
        break;
      case ButtonActionDelete:
        setOpenDeleteOrgDialog({
          'open': true,
          'org_id': row.org_id,
          'org_name': row.org_name
        });
        break;
      case ButtonActionList:
        setUserRole(ROLENAMES.OrgAdmin);
        GetOrgUserList(row.org_name, row.org_id, ROLENAMES.OrgAdmin)
        break;
      case ButtonActionView:
        setUserRole(ROLENAMES.Consultant);
        GetOrgUserList(row.org_name, row.org_id, ROLENAMES.Consultant);
        break;
      case ButtonActionShow:
        setUserRole(ROLENAMES.Assistant);
        GetOrgUserList(row.org_name, row.org_id, ROLENAMES.Assistant);
        break;
      case ButtonSubscriptionDetails: {
        navigate(navigateToSubscriptionDetails);
        sessionStorage.setItem("orgDetails", JSON.stringify({
          orgId: row.org_id,
          orgName: row.org_name,
        }))
      }
        break;
      default:
        break;
    }
  }


  //#endregion

  const setOrgUserData = (data: any, orgName: string, orgID: number, userRoleToUpdate: number) => {
    let orgUserData = [];
    for (let i = 0; i < data.length; i++) {
      let assignedRole = data[i].Roles.length > 1 ? data[i].Roles[1] : data[i].Roles[0] === ROLE.Consultant ? ROLE.OrgAdmin : ROLE.Consultant;

      orgUserData.push(
        {
          'org_id': data[i].org_id,
          "org_name": orgName,
          'user_id': data[i].user_id,
          'org_admin_title': data[i].user_title,
          'org_admin_fname': data[i].user_fname,
          'org_admin_lname': data[i].user_lname,
          'org_admin_email': data[i].user_email,
          'org_admin_username': data[i].user_name,
          'org_admin_phone_m': data[i].phone_number_m,
          'org_admin_status': data[i].user_status,
          'org_admin_phone_nm': data[i].phone_number_nm,
          'orgAdminSendDetailsInEmail': false,
          'specialities': data[i].speciality,
          'profile_image': data[i].user_profile_img,
          'country_code': data[i].country_code,
          'set_password_url': "",
          'assign_role_to_user': userRoleToUpdate === ROLE.Consultant || userRoleToUpdate === ROLE.OrgAdmin ? data[i].Roles.length > 1 ? true : false : false,
          'newAssignedRole': assignedRole,
          'isPasswordSet': data[i].user_password === "true" ? true : false
        }
      );
    }
    if (orgUserData.length > 0) {
      const dataSortedByFirstName = orgUserData.sort(function (initial: any, next: any) {
        if (`${initial.org_admin_fname}${initial.org_admin_lname}`.toLowerCase() < `${next.org_admin_fname}${next.org_admin_lname}`.toLowerCase()) return -1
        if (`${initial.org_admin_fname}${initial.org_admin_lname}`.toLowerCase() > `${next.org_admin_fname}${next.org_admin_lname}`.toLowerCase()) return 1
        return 0;
      })
      setOrgConsultantGridData(dataSortedByFirstName);
    } else {
      setOrgConsultantGridData(orgUserData);
    }

    setCurrentOrgMetaData({ orgName, orgID });
    setshowOrgConsultantList(true);
  }

  const GetAllOrganizations = async () => {
    handleToggleLoader(true);
    await apis.instance.get(`${OrganizationListRoute}`)
      .then((resp: any) => {
        handleToggleLoader(false);
        setOrgData(resp.data)
        setData(resp.data?.data)
      })
      .catch(err => {
        handleToggleLoader(false);
        if (err?.response.status !== 401) toast.error(err.response?.data?.message)
        console.log(err)
      })
      .finally(() => setLoading(false));
  }

  //#region GET PARTICULAR ORG USER LIST
  const GetOrgUserList = async (orgName: string, orgID: number, user: string) => {
    const userRoleToUpdate = user === ROLENAMES.Consultant ? ROLE.Consultant : user === ROLENAMES.Assistant ? ROLE.Assistant : ROLE.OrgAdmin;
    const params = {
      orgID,
      "role": user === "Consultant" ? ROLE.Consultant : user === "Assistant" ? ROLE.Assistant : ROLE.OrgAdmin
    }
    await apis.instance.get(`${AllUserListByRoleRoute}`, { params })
      .then(({ data }: any) => {
        if (data.success) {
          if (data.data.length > 0) {
            const nonDeletedUsers = GetNonDeletedUser(data.data, userId);
            setOrgUserData(nonDeletedUsers, orgName, orgID, userRoleToUpdate);
          }
          else {
            setOrgUserData([], orgName, orgID, userRoleToUpdate);
          }
        }
      })

      .catch(err => {
        if (err?.response.status !== 401) toast.error(err.response?.data?.message)
        console.log(err)
      })
  }
  //#endregion

  // dynamic scrollbar for grid view start
  const PageTitleRef: any = useRef();
  const tableGridRef: any = useRef();
  const [tableHeight, setTableHeight] = useState<any>(
  );

  const getPageTitleHeight = () => {
    let pageTitleHeight: any = 0;
    if (PageTitleRef && PageTitleRef.current) {
      pageTitleHeight = PageTitleRef.current.clientHeight;
    }
    setTableHeight(window.innerHeight - pageTitleHeight - 82);
  };

  window.addEventListener("resize", getPageTitleHeight);

  useEffect(() => {
    GetAllOrganizations();
    getPageTitleHeight();
  }, []);

  const openAddOrnAdminForm = (orgName: string, orgID: number) => {
    setShowOrgAdminForm(true);
    setCurrentOrgMetaData({ orgName, orgID });
  }
  // dynamic scrollbar for grid view end

  return (
    <div>
      <Box ref={PageTitleRef} className="page-title-row pb-3">
        <Grid container alignItems={'center'} textAlign={'left'} rowSpacing={2} columnSpacing={{ xs: 2, md: 0 }}>
          <Grid item xs={12} md={auto} mr={auto} sm padding={0} className="page-title-box">
            <Typography className="page-title mt-0 mb-0" sx={(theme) => theme.typography.h5}> {t('lbl_activeOrg')} </Typography>
          </Grid>
          <Grid item xs={12} md={auto} padding={0}>
            <Button id={ButtonActionAdd}
              onClick={(e) => { onClick(e, null) }}
              startIcon={<span className='cad-plus'></span>}
              variant="contained" sx={{ my: .5, mr: 1 }}>
              {t('lbl_create')}<Typography sx={{ font: 'inherit' }} display={{ xs: 'none', md: 'inline' }}>&nbsp;{t('lbl_org')}</Typography>
            </Button>

            <TextField className="cadis-search"
              id="outlined-start-adornment"
              size="small"
              type="search" placeholder="Search"
              sx={{ width: '18ch', my: .5 }}
              value={searchQuery}
              onChange={handleSearchInputChange}
              InputProps={{
                endAdornment: <InputAdornment position="end"><span className="cad-search"></span></InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={GridStyling} ref={tableGridRef} height={tableHeight}>
        <DataGrid
          rows={orgGridData}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
          rowsPerPageOptions={GridRowsPerPageOptions}
          getRowId={(row: any) => row.org_id}
          autoHeight={false}
          autoPageSize={false}
          disableColumnFilter={true}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          getRowClassName={(params) => params.row.org_is_deleted && `app-them-row-hidden`}
        />
      </Box>
      <AddUpdateOrganization
        openAddOrnAdminForm={openAddOrnAdminForm}
        isOpen={addOrgDrawer} setIsOpen={setAddOrgDrawer}
        getAllOrganizations={GetAllOrganizations} mode={orgCreationMode}
        currentOrgData={currentOrganization} />
      <Dialog
        open={openDeleteOrgDialog.open}
        onClose={() => { return null }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('lbl_confirmation')}
        </DialogTitle>
        <DialogContent>
          {t('lbl_confirmationForHide')} {openDeleteOrgDialog.org_name}?
        </DialogContent>
        <DialogActions>
          <CadisButton onClick={handleDeleteOrg} variant="contained" color='error'>
            {t('lbl_yes')}
          </CadisButton>
          <CadisButton onClick={handleCloseDeleteOrg} variant="contained" color='success'>
            {t('lbl_no')}
          </CadisButton>
        </DialogActions>
      </Dialog>
      <OraganizationUserList
        showOrgAdminForm={showOrgAdminForm}
        setShowOrgAdminForm={setShowOrgAdminForm}
        currentOrgMetaData={currentOrgMetaData}
        showOrgConsultantList={showOrgConsultantList}
        orgUserGridData={orgUserGridData}
        setshowOrgConsultantList={setshowOrgConsultantList}
        userRole={userRole}
        getOrgUserList={GetOrgUserList}
      />
    </div>
  );
}

export default OraganizationList