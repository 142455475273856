import { Box, Checkbox, Container, Drawer, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isAuthenticated } from "../../Common/helperFunction";
import { useFormik } from "formik";
import { Users } from "../../Common/helper";
import apis from "../../HttpConfig/Api";
import { AddUserInSubscription, GetAllUsersOfOrg } from "../../Common/api.routes";
import CadisButton from "../../Components/Input/CadisButton";
import CadisLoader from "../../Components/Loading/CadisLoader";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { IManageUser } from "../../Interfaces/ISubscription";

const AddRemoveUsers = (
    {
        activateEffect,
        onEffectActivated,
        isOpenAssignRemoveUsersDrawer,
        setOpenAssignRemoveUsersDrawer,
        getAllSubscribedUsersDetails
    }
        :
        {
            activateEffect: boolean
            onEffectActivated: any
            isOpenAssignRemoveUsersDrawer: boolean,
            setOpenAssignRemoveUsersDrawer: any,
            getAllSubscribedUsersDetails: any
        }) => {
    const [loading, setLoading] = useState(false);
    const user = isAuthenticated();
    const { t } = useTranslation();
    const location = useLocation();
    const { orgSubscriptionData } = location.state;
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
    const [allUserOfOrg, setAllUserOfOrg] = useState<IManageUser[]>([]);
    const [filteredData, setFilteredData] = useState<IManageUser[]>([]);
    const [initialSelectedUserIds, setInitialSelectedUserIds] = useState<number[]>([]);

    const { values, handleReset, handleSubmit, handleChange, resetForm } = useFormik({
        initialValues: {
            selectedMode: Users[0],
            username: ''
        },
        enableReinitialize: true,
        onSubmit: async (values: any, props: any) => {
            addUserInSubscriptionApiCall()
            setLoading(true);
        },
    })

    const onFormSubmitComplete = (resp: any) => {
        if (resp.data.success) {
            handleReset(values);
            getAllSubscribedUsersDetails()
            setOpenAssignRemoveUsersDrawer(false);
            setSelectedUserIds([])
            getAllUsersOfOrg()
            toast.success(t(`responseMsg.msg_assignUser`));
            setLoading(false);
            setSearchQuery("")
            const isIdExist = userArray.some(item => item.user_id === user.userId);
            if (isIdExist)
                window.location.reload();
        } else {
            toast.error(resp.data.error);
            setLoading(false);
            setSearchQuery("")
        }
    }

    useEffect(() => {
        getAllUsersOfOrg();
        onEffectActivated();
    }, [activateEffect]);

    const getAllUsersOfOrg = async () => {
        const params = {
            org_id: user.orgId,
        };
        try {
            const response = await apis.instance.get(`${GetAllUsersOfOrg}`, { params });
            if (response.data.success) {
                const responseData: IManageUser[] = response.data.data.map((item: any) => ({
                    user_role_id: item.user_role_id,
                    subscription_id: item.subscription_id,
                    user_id: item.user_id,
                    full_name:
                        item.user_title === 'None'
                            ? `${item.user_fname} ${item.user_lname ?? ''}`
                            : `${item.user_title} ${item.user_fname} ${item.user_lname ?? ''}`,
                    user_fname: item.user_fname,
                    user_lname: item.user_lname,
                }));
                responseData.sort((a, b) => a.user_fname.localeCompare(b.user_fname));
                setAllUserOfOrg(responseData);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const userArray = selectedUserIds.map(userId => ({ user_id: userId }));
    const addUserInSubscriptionApiCall = async () => {
        const UserData = {
            "subscription_id": orgSubscriptionData.subscription_id,
            "org_id": user.orgId,
            "updated_by": user.userId,
            "userArray": userArray
        }
        await apis.instance.post(`${AddUserInSubscription}`, UserData)
            .then((resp: any) => {
                onFormSubmitComplete(resp);
            })
            .finally(() => setLoading(false));
    }
    const handleChangeBox = (event: any) => {
        const userId = parseInt(event.target.value);
        const userIsSelected = selectedUserIds.includes(userId);
        if (event.target.checked && !userIsSelected && remainingUserCount > 0) {
            setSelectedUserIds([...selectedUserIds, userId]);
        } else if (!event.target.checked) {
            setSelectedUserIds(selectedUserIds.filter((id) => id !== userId));
        }
    };

    const isUserChecked = (user: IManageUser, selectedUserIds: any, orgSubscriptionId: any) => {
        return selectedUserIds.includes(user.user_id) || user.subscription_id === orgSubscriptionId;
    };
    const disabledUserCount = allUserOfOrg.filter(user => user.subscription_id === orgSubscriptionData.subscription_id).length;
    const remainingUserCount = orgSubscriptionData.user_count - selectedUserIds.length - disabledUserCount;

    useEffect(() => {
        setInitialSelectedUserIds(Array(disabledUserCount).fill(null));
    }, [disabledUserCount]);

    const handleSearchInputChange = (event: any) => {
        const value = event.target.value;
        setSearchQuery(value);
    };

    useEffect(() => {
        const filtered = allUserOfOrg.filter(item => {
            return (
                item.full_name.toLowerCase().includes(searchQuery.toLowerCase()) &&
                (values.selectedMode === "Consultants"
                    ? item.user_role_id === 3
                    : values.selectedMode === "Assistants"
                        ? item.user_role_id === 4
                        : true)
            );
        });
        setFilteredData(filtered);
    }, [searchQuery, allUserOfOrg, values.selectedMode]);


    return (
        <>
            <Drawer
                anchor='right' className="assign-user-drawer"
                open={isOpenAssignRemoveUsersDrawer}
                onClose={() => { return null }}
            >
                <Box role="presentation">
                    <Box sx={{ pt: 1.5, position: 'sticky', top: 0, background: '#ffffff', zIndex: 2, paddingX: '20px' }} >
                        <Container sx={{ alignItems: 'center', display: 'flex' }} disableGutters>
                            <Box sx={{ width: '40px', height: '40px', flexGrow: 1, display: { xs: 'flex', pr: 2 } }}>
                                <IconButton onClick={() => {
                                    setSearchQuery('');
                                    resetForm()
                                    setSelectedUserIds([])
                                    setOpenAssignRemoveUsersDrawer(false)
                                }} sx={{}} className='c-pointer'>
                                    <span className='cad-close'></span>
                                </IconButton>
                            </Box>
                            <Box>
                                <FormControl variant="outlined" fullWidth>
                                    <TextField
                                        className="cadis-search"
                                        id="outlined-end-adornment"
                                        size="small"
                                        type="search" placeholder="Search"
                                        sx={{ width: '18ch', my: .5 }}
                                        value={searchQuery}
                                        onChange={handleSearchInputChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <span className="cad-search"></span>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Box>
                        </Container>
                        <Box sx={{ mb: 2, pb: 2, mt: 2, display: 'flex' }}>
                            <Typography className="mt-0 mb-0 slide-right-title" sx={(theme) => theme.typography.h5}>
                                {t(`lbl_assignUsers`)}
                            </Typography>
                        </Box>
                    </Box>
                    <Grid container width={'100%'}>
                        <form onSubmit={handleSubmit} onReset={handleReset} style={{ width: '100%' }}>
                            <Stack spacing={2} direction={'column'} paddingX={'20px'}>
                                <FormControl variant="outlined" size="small" fullWidth>
                                    <label className='form-label '>
                                        {t('lbl_userList')}
                                    </label>
                                    <Select
                                        labelId='selectedMode'
                                        id='selectedMode'
                                        name='selectedMode'
                                        value={values.selectedMode}
                                        onChange={handleChange}
                                        fullWidth>
                                        {Users.map((item, index) => { return <MenuItem value={item} key={index}>{item}</MenuItem> })}
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" size="small">
                                    {allUserOfOrg
                                        .filter(user =>
                                            (user.subscription_id === null || user.subscription_id === orgSubscriptionData.subscription_id) &&
                                            (values.selectedMode === "Consultants" ? user.user_role_id === 3 : values.selectedMode === "Assistants" ? user.user_role_id === 4 : true)
                                            && (searchQuery.length === 0 || user.full_name.toLowerCase().includes(searchQuery.toLowerCase()))
                                        )
                                        .sort((userA, userB) => {
                                            if (userA.subscription_id === orgSubscriptionData.subscription_id && userB.subscription_id !== orgSubscriptionData.subscription_id) {
                                                return 1;
                                            } else if (userB.subscription_id === orgSubscriptionData.subscription_id && userA.subscription_id !== orgSubscriptionData.subscription_id) {
                                                return -1;
                                            } else {
                                                return 0;
                                            }
                                        })
                                        .map((user: any) => (
                                            <div key={user.user_id}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            id='username'
                                                            name='username'
                                                            value={values.username || String(user.user_id)}
                                                            disabled={user.subscription_id === orgSubscriptionData.subscription_id}
                                                            onChange={(event) => { handleChangeBox(event); }}
                                                            checked={isUserChecked(user, selectedUserIds, orgSubscriptionData.subscription_id)} />
                                                    }
                                                    label={
                                                        <Box sx={{ display: 'flex', alignItems: 'center', wordBreak: 'break-all' }} >
                                                            {values.selectedMode === "All" && user.user_role_id !== undefined && (
                                                                <>
                                                                    {user.full_name}
                                                                    {user.user_role_id === 3 ? (
                                                                        <Box className='mx-1 ico-circle-secondary cad-statoscope' sx={{ lineHeight: '1' }}></Box>
                                                                    ) : user.user_role_id === 4 ? (
                                                                        <Box className='mx-1 ico-circle-secondary cad-assistant' sx={{ lineHeight: '1' }}></Box>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                            {values.selectedMode !== "All" && user.full_name}
                                                        </Box>
                                                    }
                                                />
                                            </div>
                                        ))}
                                    {(searchQuery.length > 0 && filteredData.length === 0 || allUserOfOrg.filter(user =>
                                        (user.subscription_id === null || user.subscription_id === orgSubscriptionData.subscription_id) &&
                                        (values.selectedMode === "Consultants" ? user.user_role_id === 3 : values.selectedMode === "Assistants" ? user.user_role_id === 4 : true)).length === 0)
                                        && (<div> {(searchQuery.length > 0 && filteredData.length === 0) ? t('lbl_noRecord') : t('lbl_userAvailable')}</div>)
                                    }
                                </FormControl>
                            </Stack>
                            <Box sx={{ pt: 2, pb: 2, position: 'sticky', bottom: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }}>
                                <Typography variant="body2" color="error" marginBottom={1}>
                                    {remainingUserCount > 0
                                        ? `${t('lbl_select')} ${remainingUserCount} ${t('lbl_moreUsers')}`
                                        : `${t('lbl_usersLimit')}`}
                                </Typography>
                                <Grid container spacing={0} alignItems="center"  >
                                    <Grid item xs="auto" >
                                        <CadisButton type='submit' variant="contained" color='primary' disabled={loading || selectedUserIds.length < 1} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                                            {t(`lbl_assign`)}
                                        </CadisButton>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Box sx={{ pl: 1 }}>
                                            <CadisButton type='reset' variant="contained" color='error' disabled={loading || selectedUserIds.length < 1}
                                                onClick={() => {
                                                    resetForm()
                                                    setSelectedUserIds([])
                                                }} >
                                                {disabledUserCount > 0 ? t('lbl_reset') : t('lbl_clear')}
                                            </CadisButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    </Grid>
                </Box>
            </Drawer>
        </>
    );
}
export default AddRemoveUsers;