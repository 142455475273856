import { Dispatch, SetStateAction, useEffect, useState, } from 'react';
import { Box, Card, CardContent, Container, Divider, Drawer, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BuyDataAddOnPack, GetAllAddonPackList, UpdatePaymentStatus } from '../../Common/api.routes';
import { IUpdateAddOnPack } from '../../Interfaces/ISubscription';
import { formateDecimalValues, formateMbtoGb, isAuthenticated } from '../../Common/helperFunction';
import { currentHost } from '../../Common/helper';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CadisButton from '../../Components/Input/CadisButton';
import apis from '../../HttpConfig/Api';
import PaymentStatusDialog from '../Subscription/PaymentStatusDialog';

const BuyDataAddonPackList = (
    { openDataAddonList, setOpenDatAddonList, remainingData, selectedPlanId }: {
        openDataAddonList: boolean,
        selectedPlanId: number
        remainingData: number
        setOpenDatAddonList: Dispatch<SetStateAction<boolean>>
    }) => {

    const { t } = useTranslation()
    const [addOnPackList, setAddOnPackList] = useState<IUpdateAddOnPack[]>([]);
    const [selectedAddOnPack, setSelectedAddOnPack] = useState<IUpdateAddOnPack | null>(null);
    const userDetails = isAuthenticated();
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [isPaymentSuccessState, setIsPaymentSuccessState] = useState(true)
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const path = window.location.pathname;
    const isPaymentSuccess = path.includes("4");
    const isPaymentCanceled = path.includes("5");

    const handlePaymentStatus = () => {
        if (isPaymentSuccess) {
            setPaymentStatus(true); // Open dialog with success message
            setIsPaymentSuccessState((pre) => pre && isPaymentSuccess)
        } else if (isPaymentCanceled) {
            setPaymentStatus(true); // Open dialog with fail message
            setIsPaymentSuccessState((pre) => pre && !isPaymentCanceled)
        } else {
            setPaymentStatus(false);
        }
    };

    const handleClose = () => {
        navigate("/dashboard/myPlans/0");
        setPaymentStatus(false);
        getallAddonPackList();
    };

    const getallAddonPackList = async () => {
        try {
            const addonList = await apis.instance.get(`${GetAllAddonPackList}`)
            if (addonList.data.success) {
                setAddOnPackList(addonList.data.data)
            } else {
                if (addonList.data.error == "Subscription addon details do not exist.") {
                    setAddOnPackList([])
                }
            }
        } catch (error) {
            console.log(error)
        }
    };

    // send SessionId
    const sendSessionId = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get("session_id");
        const status = isPaymentSuccess ? "Succeeded" : "Cancelled";
        const data = {
            "sessionId": sessionId,
            "status": status,
        }
        try {
            await apis.instance.post(`${UpdatePaymentStatus}`, data)
        } catch (error: any) {
            toast.error(t('responseMsg.msg_wrongEntry'));
        }
    }
    useEffect(() => {
        getallAddonPackList();
        handlePaymentStatus();
        if (isPaymentSuccess || isPaymentCanceled) {
            sendSessionId();
        }
    }, [])

    const buyAddonPack = async () => {
        try {
            setLoader(true)

            const buyAddonPack = await apis.instance.post(`${BuyDataAddOnPack}`, {
                "userId": userDetails.userId,
                "subscriptionId": selectedPlanId,
                "addonId": selectedAddOnPack?.addon_id,
                "successUrl": currentHost + "/dashboard/myPlans/4",
                "cancelUrl": currentHost + "/dashboard/myPlans/5"
            });

            if (buyAddonPack.data.success) {
                window.location.href = buyAddonPack.data.checkoutUrl;
                setLoader(false)
            };

            if (!buyAddonPack.data.success) {
                toast.error(buyAddonPack.data.error);
                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
        }
    };

    return (
        <>
            <Drawer
                anchor='right'
                open={openDataAddonList}
                onClose={() => { return null }}
            >
                <Box role="presentation">
                    <Box sx={{ pt: 1.5, position: 'sticky', top: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }} >
                        <Container sx={{ alignItems: 'center', display: 'flex' }} disableGutters>
                            <Box sx={{ width: '40px', height: '40px', flexGrow: 1, display: { xs: 'flex', pr: 2 } }}>
                                <IconButton onClick={() => {
                                    setOpenDatAddonList(false)
                                    setSelectedAddOnPack(null)
                                }} sx={{}} className='c-pointer'>
                                    <span className='cad-close'></span>
                                </IconButton>
                            </Box>
                        </Container>
                        <Box sx={{ mb: 2, pb: 2, mt: 2, display: 'flex' }}>
                            <Typography className="mt-0 mb-0 slide-right-title" sx={(theme) => theme.typography.h5}>  {t('lbl_buyAddOnPack')}</Typography>
                        </Box>
                    </Box>
                    <Stack spacing={1} direction={'column'} paddingX={'20px'}>
                        {remainingData > 0 ? <>
                            <Box display={'flex'} justifyContent={'space-between'}>
                                <Typography color="grey" variant='body1'>
                                    Current subscription Remaining Data :
                                </Typography>
                                <Typography variant='body1'>
                                    {formateMbtoGb(remainingData)}
                                </Typography>
                            </Box>
                            <Divider></Divider>
                        </> : ""}
                        <Typography color="info.main" variant='body1' fontWeight={'400'}>
                            <strong>Note : </strong>Remaining data from your add-on pack will be carried over to your renewed subscription.
                        </Typography>
                        <Grid container direction={'row'} rowGap={1.5} py={2}>
                            {addOnPackList.map((i) => {
                                return <Grid key={i.addon_id} item xs={12} onClick={() => setSelectedAddOnPack(i)}>
                                    <Card className='add-data-pack-list-item' sx={{ borderColor: selectedAddOnPack?.addon_id === i.addon_id ? "primary.main" : "transparent" }} elevation={3}>
                                        <CardContent>
                                            <Grid container spacing={0}>
                                                <Grid item xs>
                                                    <Typography variant="body2" color="grey" fontWeight={400}>
                                                        Pack
                                                    </Typography>
                                                    <Typography variant="h3" component="div" color="secondary.main" fontWeight={600}>
                                                        $ {formateDecimalValues(parseFloat(i.addon_price))} </Typography>
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant="body2" color="grey" fontWeight={400}>
                                                        Data
                                                    </Typography>
                                                    <Typography variant="h4" className="plan-price" component="div" fontWeight={400}>
                                                        {formateDecimalValues(parseFloat(i.addon_data))} GB
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            })}
                        </Grid>
                    </Stack>
                    <Box sx={{ pt: 2, pb: 2, position: 'sticky', bottom: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }}>
                        <Grid container spacing={0} alignItems="center">
                            <Grid item xs="auto" >
                                <CadisButton onClick={buyAddonPack} disabled={selectedAddOnPack === null || loader} type='submit' variant="contained" color='primary'  >
                                    Make Payment
                                </CadisButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Drawer>

            <PaymentStatusDialog
                open={paymentStatus}
                onClose={handleClose}
                isPaymentSuccess={isPaymentSuccessState}
            />
        </>
    )
}

export default BuyDataAddonPackList;