import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { Button, IconButton, Stack } from '@mui/material';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import { useRef, useState } from 'react';
import CadisLogoVertical from '../../Assets/Images/Logo/CadisLogoVertical.svg'
import CadisLogoMobile from '../../Assets/Images/Logo/CadisLogoMobile.svg';
import { auto } from '@popperjs/core';
import { getLoggedUserRoleName, getUser, isAuthenticated, subscriptionDataUsage } from "../../Common/helperFunction";
import { ButtonActionAdd, CONFERENCE_CALL, assistants, cadisAdmin, consultants, countries, navigateToAssistant, navigateToConsultants, navigateToDeletedUser, navigateToManageUsers, navigateToOrgAdmins, navigateToPurchasedPlansMangeUser } from '../../Common/helper';
import Sidebar from './Sidebar';
import { keyIcon, userOneIcon, logoutIcon } from "../../theme/custSvgIcons/custThemeIcons";
import { useSocketContext } from "../../context/SocketContext";
import { IHeaderSettings } from "../../Interfaces/IHeaderSettings";
import { useUserRoleContext } from "../../context/UserRoleContext";
import { ROLE } from "../../Interfaces/IConstants";
import UserProfile from "../User/UserProfile";
import jwtDecode from "jwt-decode";
import { IUser } from "../../Interfaces/IUser";
import apis from "../../HttpConfig/Api";
import { GetSpecialityList, GetUserRoute, LogoutUserRoute } from "../../Common/api.routes";
import { toast } from "react-toastify";
import ChangePassword from "../auth/ChangePassword";
import { io } from "socket.io-client";
import { useTranslation } from 'react-i18next';
import DataLimitPopup from '../CallScreen/DataLimitPopup';

const drawerWidth = 240;
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export default function ResponsiveDrawer(props: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const MainCompRef: any = useRef();
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  const user = isAuthenticated();
  const [settingOptions, setSettingOptions] = React.useState<IHeaderSettings[]>();
  const { pingIntervalRef, callerToneRef, ringtoneRef, socketRef, handleCloseSocket, setWastInviteSent, setRokidInviteSent } = useSocketContext(); // socket context customeHook
  const { userLoginRole, setUserLoginRole } = useUserRoleContext();
  const [openChangePasswordDrawer, setOpenChangePasswordDrawer] = useState(false);
  const [subscriptionId, setSubscribedUser] = useState(0);
  const [viewButtonLabel, setViewButtonLabel] = useState('');
  const [dataFinished, setDataFinished] = useState(false);

  const [userDetails, setUserDetails] = React.useState<IUser>({
    org_admin_title: "",
    org_admin_fname: "",
    org_admin_lname: "",
    org_admin_email: "",
    country_code: countries[0].code,
    org_admin_phone_m: "",
    org_admin_phone_nm: "",
    org_admin_status: true,
    org_admin_username: "",
    org_id: 0,
    org_name: "",
    orgAdminSendDetailsInEmail: true,
    profile_image: "",
    user_id: 0,
    set_password_url: "",
    specialities: [],
    assign_role_to_user: false,
    newAssignedRole: 0,
    isPasswordSet: false
  });

  const encodedUser: any = getUser();
  let localUser: any = encodedUser ? jwtDecode(encodedUser) : {};

  // commented by Deepika for user profie name view
  // const [userName, setUserName] = useState<string>(`${localUser?.user?.user_fname} ${localUser?.user?.user_lname ?? ''}`);
  const [userName, setUserName] = useState<string>(localUser?.user?.user_title === "None" ? `${localUser?.user?.user_fname}` : `${localUser?.user?.user_title} ${localUser?.user?.user_fname}`);
  const [consultantSpecialities, setConsultantSpecialities] = React.useState<any[]>([]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [userProfileImageBase64, setUserProfileImage] = useState<string>("");
  const temp: any = sessionStorage.getItem("role");
  const sessionStorageUserRole = Number(temp);

  // nested collapse list
  const [open, setOpen] = useState(false);
  const [openOrg, SetOpenOrg] = useState(true);
  const [openConsultant, setOpenConsultant] = useState(true);
  const [openAssistant, setOpenAssistant] = useState(false);
  const [isOpenUpdateUserProfile, setIsOpenUpdateUserProfile] = React.useState(false);
  const [openMyPlan, setOpenMyPlan] = useState(false);
  const path = window.location.pathname;
  const handleClick = () => {
    setOpen(!open);
    SetOpenOrg(false);
    setOpenAssistant(false);
    setOpenConsultant(false);
    setOpenMyPlan(false);
  };

  const handleOrgClick = () => {
    SetOpenOrg(!openOrg);
    setOpen(false);
  };

  const handleConsultantClick = () => {
    setOpenConsultant(!openConsultant);
    setOpenAssistant(false);
    setOpen(false);
    setOpenMyPlan(false);
  };

  const handleAssistantClick = () => {
    setOpenAssistant(!openAssistant);
    setOpenConsultant(false);
    setOpen(false);
    setOpenMyPlan(false);
  };

  const handleMyPlanClick = () => {
    setOpenMyPlan(!openMyPlan);
    setOpenAssistant(false);
    setOpenConsultant(false);
    setOpen(false);

  }

  React.useEffect(() => {
    if ((path === "/dashboard/cadisAdmin" || path === "/dashboard/deletedUserList/cadisAdmin" || path.includes(navigateToOrgAdmins) || path.includes(navigateToDeletedUser)) && !path.includes("/dashboard/deletedUserList/consultants")) {
      setOpen(true);
      SetOpenOrg(false);
      setOpenAssistant(false);
      setOpenConsultant(false);
    }
    if (path.includes(assistants)) {
      setOpenAssistant(true);
      setOpenConsultant(false);
      setOpen(false);
    }
    if (path.includes(consultants)) {
      setOpenAssistant(false);
      SetOpenOrg(false);
      setOpen(false);
      setOpenMyPlan(false);
    }

    const successMessage = sessionStorage.getItem('successMessage');
    if (successMessage) {
      toast.success(t("responseMsg.msg_updateUserProfile"));
      sessionStorage.removeItem('successMessage');
    }
    if ((path.includes("/0") || path.includes("/1") || path.includes("/2")) && !path.includes("/dashboard/deletedUserList/consultants")) {
      setOpenMyPlan(true);
      setOpenConsultant(false);
    } else {
      setOpenMyPlan(false);
    }
    if (path == navigateToPurchasedPlansMangeUser) {
      setOpenMyPlan(true)
      setOpenConsultant(false)
    }
  }, [window.location.pathname])

  const container =
    props.window !== undefined ? () => "window().document.body " : undefined;
  // by Deepika
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const settings: IHeaderSettings[] = [
    {
      icon: userOneIcon,
      text: "My Profile",
      class: "",
      onclick: () => loadUserProfile(),
    },
    {
      icon: keyIcon,
      text: "Change Password",
      class: "",
      onclick: () => ChangeUserPassword(),
    },
    {
      icon: logoutIcon,
      text: "Logout",
      class: "",
      onclick: () => LogoutUser(),
    },
  ];

  const ChangeUserPassword = () => {
    handleCloseUserMenu();
    setOpenChangePasswordDrawer(true);
  };

  const viewAsConsultant = () => {
    let currentPath = false;
    sessionStorage.setItem("role", "3");
    const role = ROLE.Consultant;
    setUserLoginRole(role);
    handleCloseUserMenu();
    if (window.location.pathname === navigateToAssistant)
      currentPath = true;
    navigate(navigateToAssistant);
    if (currentPath) {
      window.location.reload();
    }
  };

  const viewAsAdministrator = () => {
    sessionStorage.setItem("role", "2");
    const role = ROLE.OrgAdmin;
    clearInterval(pingIntervalRef.current);
    pingIntervalRef.current = null;
    handleCloseSocket();
    callerToneRef.current?.pause();
    ringtoneRef.current?.pause();
    setUserLoginRole(role);
    handleCloseUserMenu();
    navigate(navigateToConsultants);
    handleConsultantClick();
  };

  React.useEffect(() => {
    GetUserDetails();
  }, [userLoginRole]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    color: alpha(theme.palette.common.black, 0.7),
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: alpha(theme.palette.common.black, 0.15),
    // backgroundColor: alpha(theme.palette.common.black, 0.15),
    "&:hover": {
      // backgroundColor: alpha(theme.palette.common.black, 0.25),
      borderColor: alpha(theme.palette.common.black, 0.15),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const LogoutUser = async () => {
    const params = {
      user_id: user.userId,
      isUserLoggedIn: 0
    }
    await apis.instance
      .post(`${LogoutUserRoute}`, params)
      .then((resp: any) => {
        if (resp.data.success) {
          clearInterval(pingIntervalRef.current);
          pingIntervalRef.current = null;
          handleCloseSocket();
          callerToneRef.current?.pause();
          ringtoneRef.current?.pause();
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("username");
          sessionStorage.removeItem("role");
          localStorage.removeItem("currentNavNode");
          navigate("/");
        } else {
          toast.error("Something went wrong. Please try again later");
        }
      }
      )
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err);
      });
  };

  const loadUserProfile = async () => {
    handleCloseUserMenu();
    // await GetUserDetails();
    await getSpecialities();
    setIsOpenUpdateUserProfile(true);
  };

  const GetUserDetails = async () => {
    const encodedUser: any = getUser();
    let localUser: any = encodedUser ? jwtDecode(encodedUser) : {};

    var user: IUser = {
      org_admin_title: "",
      org_admin_fname: "",
      org_admin_lname: "",
      org_admin_email: "",
      country_code: countries[0].code,
      org_admin_phone_m: "",
      org_admin_phone_nm: "",
      org_admin_status: true,
      org_admin_username: localUser?.user?.user_name,
      org_id: localUser?.user?.org_id,
      org_name: "",
      orgAdminSendDetailsInEmail: true,
      profile_image: "",
      user_id: 0,
      set_password_url: "",
      specialities: [],
      assign_role_to_user: false,
      newAssignedRole: 0,
      isPasswordSet: false
    };
    const params = {
      user_name: user.org_admin_username,
      orgID: user.org_id,
    };
    let subscriptionId = 0
    await apis.instance
      .get(`${GetUserRoute}`, { params })
      .then((resp: any) => {
        if (resp.data.success) {
          const dbUser = resp.data.data[0];
          user.country_code = dbUser.country_code ?? countries[0].code;
          user.org_id = dbUser.org_id;
          user.user_id = dbUser.user_id;
          user.org_name = dbUser.org_name;
          user.org_admin_phone_m = dbUser.phone_number_m ?? "";
          user.org_admin_phone_nm = dbUser.phone_number_nm;
          user.org_admin_email = dbUser.user_email ?? "";
          user.org_admin_fname = dbUser.user_fname ?? "";
          user.org_admin_lname = dbUser.user_lname ?? "";
          user.org_admin_username = dbUser.user_name ?? "";
          user.profile_image = dbUser.user_profile_img ?? "";
          user.org_admin_status = dbUser.user_status;
          user.org_admin_title = dbUser.user_title;
          user.specialities = dbUser.speciality ?? [];
          subscriptionId = resp.data.data[0].subscription_id;
          setSubscribedUser(subscriptionId);
          handleDualRole();
          sessionStorage.setItem("subscriptionId", JSON.stringify(subscriptionId));
        } else toast.error("Unable to find user details.");
        setUserProfileImage(user.profile_image);
        setUserDetails(user);
      })
      .catch((err) => {
        if (err?.response.status !== 401) toast.error(err.response?.data?.message)
        console.log(err);
      });
  };

  const getSpecialities = async () => {
    await apis.instance
      .get(`${GetSpecialityList}`)
      .then((resp: any) => setConsultantSpecialities(resp.data.data))
      .catch((err) => {
        if (err?.response.status !== 401) toast.error(err.response?.data?.message)
        console.log(err);
      });
  };

  const onDataFinished = (isFinished: any) => {
    setDataFinished(isFinished);
  };

  const onCallInitiated = () => {
    navigate('/call');
    onInitiateCall();
    setWastInviteSent(false);
    setRokidInviteSent(false);
    sessionStorage.setItem('isConferenceCall', JSON.stringify(true));
  };

  const handleDataExhaustedPopup = () => {
    setDataFinished(false);
  }

  const OnActionClick = async (actionId: string) => {
    switch (actionId) {
      case ButtonActionAdd:
        await subscriptionDataUsage(onDataFinished, onCallInitiated);
    }
  };

  const onClick = (e: any) => {
    OnActionClick(e.currentTarget.id);
  };

  const onInitiateCall = () => {
    socketRef.current?.emit(CONFERENCE_CALL);
    if (!socketRef.current && apis.socketURL) {
      const token = sessionStorage.getItem('token');
      const socketAuth = {
        query: { token },
      };
      socketRef.current = io(apis.socketURL, socketAuth);
      socketRef.current.on('connect', () => {
        console.log('Socket connected');
      });
    }
  }

  const handleDualRole = () => {
    const temp: any = sessionStorage.getItem("role");
    const sessionStorageUserRole = Number(temp);
    if (sessionStorageUserRole === ROLE.OrgAdmin)
      setViewButtonLabel("View as Consultant")
    else
      setViewButtonLabel("View as Administrator");

    setSettingOptions(settings);
  }

  return (
    <>
      <DataLimitPopup
        isOpen={dataFinished}
        dataFinished={dataFinished}
        handleDataExhaustedPopup={handleDataExhaustedPopup}
      />

      <Box sx={{ display: "flex" }}>
        {location.pathname !== "/call" && (
          <>
            <CssBaseline />
            <AppBar
              position="fixed"
              color="default"
              className={
                user.isCadisAdmin ? 'superAdminTheme'
                  : sessionStorageUserRole === ROLE.Consultant ? 'consultantTheme' : 'orgAdminTheme'} sx={{ backgroundColor: '#ffffff' }} >
              <Container disableGutters style={{ paddingLeft: '20px', paddingRight: '20px', maxWidth: '100%' }}>
                <Toolbar disableGutters>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: { sm: 2, xs: 0.5 }, display: { sm: "none" } }}
                  >
                    <MenuIcon />
                  </IconButton>

                  <Box
                    sx={{ minWidth: { sm: '220px' }, pr: 1, boxSizing: 'border-box' }}
                    alignItems="center"
                    className="app-logo-postlogin">
                    {/* commented, may need in future */}
                    {/* maxWidth={{xs: "200px", md: "220px"}} */}
                    <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                      <img src={CadisLogoVertical} height="50" alt="" />
                    </Box>
                    <Box sx={{ display: { xs: "flex", sm: "none" } }}>
                      <img width="84px" src={CadisLogoMobile} alt="logo.svg" />
                    </Box>
                  </Box>

                  <Box sx={{ overflow: 'hidden', pr: 1, flexGrow: 1, display: 'flex' }} >
                    <Box color="#2E6B24" borderRadius={'5px'} sx={{ display: 'flex', justifyContent: "center", backgroundColor: 'rgba(93, 201, 131, .25)', overflow: 'hidden' }}>
                      {/* may need in future - backgroundColor: sessionStorageUserRole === ROLE.Consultant ? 'rgba(93,201,131, .25)' : '#04165D'  */}

                      <Box borderRadius='4px' display={user.isCadisAdmin ? 'none' : 'inline-block'} sx={{ py: 0.5, px: 1.5, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 700 }} >
                        {userDetails?.org_name}
                      </Box>
                    </Box>
                  </Box>

                  {sessionStorageUserRole === ROLE.Consultant && (
                    <div>
                      <Button className='initiate-call-btn'
                        id={ButtonActionAdd}
                        onClick={(e) => { onClick(e) }}
                        startIcon={<span className='cad-phone-initiate'></span>}
                        variant="contained" size='large'
                        sx={{ mr: { xs: 0, sm: 1 } }}
                      >
                        <Typography sx={{ font: 'inherit' }} display={{ xs: 'none', md: 'inline' }}>Start&nbsp;Conference</Typography>
                      </Button>
                    </div>
                  )}
                  <Box sx={{ ml: 1 }}>
                    <div onClick={handleOpenUserMenu}>
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        lineHeight="1"
                      >
                        <Avatar
                          alt={userName}
                          src={
                            userProfileImageBase64 ??
                            "/Assets/Images/Avatar/1.jpg"
                          }
                          sx={{
                            boxShadow: "1px 1px 3px 1px #AEAEAE",
                            border: "solid 2px #ffffff",
                          }}
                        />
                        <Tooltip title={userName}>
                          <Box
                            className="myProfile"
                            sx={{
                              display: {
                                xs: "none",
                                sm: "inline-block",
                                cursor: "pointer",
                              },
                            }}
                          >
                            <Typography
                              sx={(theme) => theme.typography.h5}
                              className="myProfile_userName"
                            >
                              {userName}
                            </Typography>
                            <label className="myProfile_userProfile c-pointer">
                              {getLoggedUserRoleName(sessionStorageUserRole)}
                            </label>
                          </Box>
                        </Tooltip>
                        <Tooltip title="Open My Profile Menus">
                          <Box
                            className="cad-more-vertical myProfile-moreIc c-pointer"
                            sx={{ display: "inline" }}
                          ></Box>
                        </Tooltip>
                      </Stack>
                    </div>
                    <Menu
                      sx={{ mt: "45px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      <Box
                        className="myProfile border-bottom"
                        sx={{ display: { sm: "none" } }}
                      >
                        <Typography
                          sx={(theme) => theme.typography.h5}
                          className="myProfile_userName"
                        >
                          {userName}
                        </Typography>
                        <label className="myProfile_userProfile c-pointer">
                          {getLoggedUserRoleName(sessionStorageUserRole)}
                        </label>
                      </Box>
                      {settingOptions?.map((setting) => (
                        <MenuItem
                          key={setting.text}
                          onClick={setting.onclick}
                          className={setting.class}
                          sx={{ py: 1 }}
                        >
                          <setting.icon
                            sx={{ mr: 2 }}
                            opacity={0.6}
                          ></setting.icon>
                          <Typography textAlign="center">
                            {setting.text}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                </Toolbar>
              </Container>
            </AppBar>
            <Sidebar
              open={open}
              openOrg={openOrg}
              openConsultant={openConsultant}
              openAssistant={openAssistant}
              openMyPlan={openMyPlan}
              container={container}
              mobileOpen={mobileOpen}
              handleCloseUserMenu={handleCloseUserMenu}
              handleClick={handleClick}
              handleOrgClick={handleOrgClick}
              handleConsultantClick={handleConsultantClick}
              handleAssistantClick={handleAssistantClick}
              handleMyPlanClick={handleMyPlanClick}
              setMobileOpen={setMobileOpen}
              viewAsAdministrator={viewAsAdministrator}
              viewAsConsultant={viewAsConsultant}
              subscriptionId={subscriptionId}
              viewButtonLabel={viewButtonLabel}
              SetOpenOrg={SetOpenOrg}
              setOpen={setOpen}
              setOpenConsultant={setOpenConsultant}
              setOpenAssistant={setOpenAssistant}
              setOpenMyPlan={setOpenMyPlan}
            />
          </>
        )}
        <Box
          component="main"
          className="main-component"
          ref={MainCompRef}
          sx={{
            flexGrow: 1,
            py: 2,
            mt: 8,
            height: { xs: `calc(100vh - 60px)`, sm: `calc(100vh - 64px)` },
            overflowY: auto,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Outlet />
        </Box>
        {isOpenUpdateUserProfile ? (
          <UserProfile
            isOpenUpdateUserProfile={isOpenUpdateUserProfile}
            setIsOpenUpdateUserProfile={setIsOpenUpdateUserProfile}
            userDetails={userDetails}
            consultantSpecialities={consultantSpecialities}
            GetUserDetails={GetUserDetails}
            setUserName={setUserName}
          />
        ) : null}
      </Box>
      <ChangePassword
        openChangePasswordDrawer={openChangePasswordDrawer}
        setOpenChangePasswordDrawer={setOpenChangePasswordDrawer}
      />
    </>
  );
}
