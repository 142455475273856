import { grey } from '@mui/material/colors';
import { ThemeOptions } from '@mui/material/styles';
import { isAuthenticated } from '../Common/helperFunction';

const user = isAuthenticated();
export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    grey: {
      "100": '#F7F7FB',
      "900": '#777777',
      "700": '#808088',
      "500": '#AEAEAE',
      "300": '#E3E3E3',
    },
    primary: {
      main: '#04165D',
      light: '#04165dc2',
      "100": '#04165dc2',
    },
    secondary: {
      main: '#2E6B24',
    },
    text: {
      primary: '#222222',
      secondary: '#808088',
      disabled: '#777777',
    },
    info: {
      main: '#0058E5',
    },
    warning: {
      main: '#F2AF4C',
    },
    success: {
      main: '#5DC983',
    },
    error: {
      main: '#E9394F',
    },
    divider: '#E3E3E3',
  },
  typography: {
    fontFamily: 'Poppins',
    h6: {
      fontSize: '0.8rem',
      fontWeight: 700,
      lineHeight: '1rem',
    },
    h5: {
      fontSize: '1.2rem',
      fontWeight: 700,
      lineHeight: '1.4rem',
    },
    h4: {
      fontSize: '1.8rem',
      fontWeight: 700,
      lineHeight: '2rem',
    },
    h3: {
      fontSize: '2.2rem',
      fontWeight: 700,
      lineHeight: '2.4rem',
    },
    h2: {
      fontSize: '2.8rem',
      fontWeight: 700,
      lineHeight: '2.9rem',
    },
    h1: {
      fontSize: '3.2rem',
      fontWeight: 600,
      lineHeight: '3.4rem',
    },
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          display: 'inline-block',
          '.form-label': {
            fontSize: '.85rem',
            marginBottom: '0.19rem',
            lineHeight: '1rem',
            // &.mui-disabled (commented for future use)
            '&.mui-disabled .mandatory-star::after': {
              color: 'transparent',
            },
          },
          "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled": {
            backgroundColor: '#E3E3E3',
            '.MuiInputAdornment-root': {
              color: '#AEAEAE',
            },
            'input, .MuiSelect-select': {
              backgroundColor: '#E3E3E3',
              borderRadius: 'inherit',
            },
            '& fieldset': {
              borderColor: '#AEAEAE !important',
            },
          }
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          borderRadius: '5px',
          '&.custom-default': {
            color: 'rgba(0, 0, 0, .7)',
            backgroundColor: 'rgba(255, 255, 255, .7)',
          }
        },
        containedSuccess: {
          backgroundColor: '#5DC983',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: 'rgba(64, 173, 102, 1)',
          }
        },
        containedWarning: {
          backgroundColor: '#F2AF4C',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: 'rgba(235, 156, 40, 1)',
          }
        },
        containedInherit: {
          color: 'rgba(0, 0, 0, .5)',
          backgroundColor: 'rgba(255, 255, 255, .6)',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label.Mui-focused': {
            color: '#04165D',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#04165D',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#AEAEAE',
            },
            '&:hover fieldset': {
              borderColor: '#0058E5',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#04165D',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: '#AEAEAE',
            }
          },
        },
      }
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          root: {
            '.MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'red',
            },
          }
        },
        select: {
          root: {
            '.MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'red',
            },
          }
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
          borderRadius: '5px',
          // cursor: 'default',
          '&.custom-default': {
            color: 'rgba(255, 255, 255, 1)',
            backgroundColor: 'rgba(255, 255, 255, .3)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, .16)',
            },
            '&:active': {
              backgroundColor: 'rgba(93, 201, 131, 1)',
              color: 'rgba(255, 255, 255, 1)',
            },
            '&:selected': {
              backgroundColor: 'rgba(93, 201, 131, 1)',
              color: 'rgba(255, 255, 255, 1)',
            },
            '&:focus': {
              backgroundColor: 'rgba(93, 201, 131, 1)',
              color: 'rgba(255, 255, 255, 1)',
            }
          },
          '&.custom-default.shuffle-video': {
            backgroundColor: 'rgba(0, 0, 0, .3)',
            boxShadow: '-1px 1px 5px 0px #d3d1d1',
            '&:hover': {
              backgroundColor: 'rgba(4, 22, 93, 1)',
              color: 'rgba(255, 255, 255, 1)',
            },
            '&:active, &:selected, &:focus': {
              // backgroundColor: 'rgba(0, 0, 0, .3)',
              backgroundColor: 'rgba(93, 201, 131, 1)',
              color: 'rgba(255, 255, 255, 1)',
            },
          },
          '&.custom-default-annotation': {
            color: 'rgba(255, 255, 255, 1)',
            backgroundColor: 'rgba(255, 255, 255, .3)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, .16)',
            },
          },
          '&.active-annotation-child': {
            backgroundColor: 'rgb(93, 201, 131)',
            color: 'rgb(255, 255, 255)',
          },
        },
        colorPrimary: {
          backgroundColor: 'rgba(4, 22, 93, 0.1)',
          color: 'rgba(4, 22, 93, 1)',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(4, 22, 93, 1)',
            color: 'rgba(255, 255, 255, 1)',
          }
        },
        colorSecondary: {
          backgroundColor: 'rgba(46, 107, 36, 0.1)',
          color: 'rgba(46, 107, 36, 1)',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(46, 107, 36, 1)',
            color: 'rgba(255, 255, 255, 1)',
          }
        },
        colorSuccess: {
          backgroundColor: 'rgba(93, 201, 131, 0.1)',
          color: 'rgba(93, 201, 131, 1)',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(93, 201, 131, 1)',
            color: 'rgba(255, 255, 255, 1)',
          }
        },
        colorError: {
          backgroundColor: 'rgba(233, 57, 79, 0.1)',
          color: 'rgba(233, 57, 79, 1)',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(233, 57, 79, 1)',
            color: 'rgba(255, 255, 255, 1)',
          }
        },
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          height: 'auto',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          '.MuiChip-label': {
            whiteSpace: 'break-spaces',
            overflow: 'unset',
          },
        },
        colorSuccess: {
          backgroundColor: 'rgba(93, 201, 131, 0.2)',
          color: 'rgba(93, 201, 131, 1)',
        },
        colorError: {
          backgroundColor: 'rgba(233, 57, 79, 0.2)',
          color: 'rgba(233, 57, 79, 1)',
        },
        colorWarning: {
          backgroundColor: '#ffe8c7',
          color: 'rgb(227 117 0)',
        },
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
        },
        paperAnchorLeft: {
          backgroundColor: user.isCadisAdmin ? 'success.main' : user.isConsultant ? 'primary.main' : 'secondary.main',
        },
        paperAnchorRight: {
          zIndex: '99999',
          width: '90%',
          maxWidth: '460px',
          overflowY: 'scroll',
          boxSizing: 'border-box',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
        },
        circular: {
          '.MuiAvatar-img': {
            width: 'unset',
            height: 'unset',
            maxWidth: '100%',
            maxHeight: '100%',
          },
          colorScheme: 'light',
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 38,
          height: 22,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                opacity: 1,
              },
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 18,
            height: 18,
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
            transition: 'background-color',
            duration: 500,
          },
          '& ~ .MuiFormControlLabel-label': {
            marginLeft: '10px',
          }
        },
      }
    },
    MuiBadge: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: "#ffffff",
          border: '2px solid #777777',
        },
        colorSuccess: {
          border: 'transparent',
        },
        root: {
          '& .MuiBadge-badge': {
            padding: '0 4px',
            height: '14px',
            borderRadius: '50%',
            minWidth: '14px',
            boxShadow: '0 0 0 2px #ffffff',
          },
        },
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiDialog-paper': {
            maxHeight: '70%',
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
          lineHeight: '1.6rem',
          fontWeight: '600',
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 24px 20px',
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          '&.plan-disabled': {
            backgroundColor: grey[100],
            // boxShadow: 'none',
            '& *:not(button)': {
              color: grey[500],
              fontWeight: 500,
            },
            '&::after': {
              content: '"Disabled"',
              letterSpacing: '0.04rem',
              position: 'absolute',
              bottom: '22px',
              right: '-32px',
              width: '140px',
              transformOrigin: '50%',
              height: '1.6rem',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              textAlign: 'center',
              transform: 'rotate(-45deg)',
              color: 'white',
              fontSize: '0.87rem',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          },
          '&:not(.plan-disabled):hover': {
            background: grey[50],
          },
        },
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '8px 16px 16px',
        }
      }
    },
    MuiRating: {
      styleOverrides: {
        root: {
          color: '#F2AF4C',
          '.MuiRating-icon': {
            color: '#F2AF4C',
          }
        }
      }
    },
    // End of components
  },
};