export const GridStyling = {
    width: '100%',
    // height: 'auto',
    '& .app-them-row-hidden .app-them-cell-hidden': {
        opacity: 0.7,
      },
    '& .MuiDataGrid-footerContainer': {
        justifyContent: 'center',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontFamily: 'poppins',
    },
    '.MuiDataGrid-columnHeaders': {
        backgroundColor: 'rgba(4, 22, 93, 0.08)',
    },
    '& .app-theme-header': {
        '&.wrapped-column-header .MuiDataGrid-columnHeaderTitle': {
            lineHeight: '1.2rem !important',
            textOverflow: 'clip  !important',
            whiteSpace: 'break-spaces !important'
        },
        color: '#807979',
        '.MuiDataGrid-columnHeaderTitle': {
            fontWeight: 300,
        },
        '&:hover, &:focus': {
            outline: 'none !important',
        },
        '&.bg-action': {
            backgroundColor: 'rgb(223 226 235)',
        },
    },
    '& .app-theme-cell': {
        '&:hover, &:focus': {
            outline: 'none !important',
        },
        '&.bg-action': {
            backgroundColor: 'rgb(223 226 235)',
        },
    },
    '.MuiDataGrid-cell': {
        overflowWrap: 'anywhere',
        whiteSpace: 'unset !important',
        borderBottom: '1px solid #F3F3F3',
        '&:focus-within': {
            outline: 'none !important',
        }
    },
    '.MuiDataGrid-root': {
        border: '1px solid #F3F3F3',
        fontSize: '1rem',
    },
    '& .MuiDataGrid-columnHeader--sortable': {
        ' .MuiDataGrid-iconButtonContainer': {
            visibility: 'visible',
            width: 'auto',
            '.MuiDataGrid-sortIcon': {
                opacity: '0.5 !important',
            }
        },
        '&.MuiDataGrid-columnHeader--sorted': {
            outline: 'none !important',
            ' .MuiDataGrid-iconButtonContainer': {
                '.MuiDataGrid-sortIcon': {
                    opacity: '1 !important',
                }
            }
        },
    },
    '.MuiDataGrid-columnSeparator--sideRight': {
        visibility: 'hidden',
    },
    
}
