import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Stack, Typography } from '@mui/material';
import CadisTextField from '../../Components/Input/CadisTextField';
import CadisButton from '../../Components/Input/CadisButton';
import apis from '../../HttpConfig/Api';
import { useTranslation } from 'react-i18next';
import { ResetPassword } from '../../Common/api.routes';
import { currentHost } from '../../Common/helper';
import CadisLoader from '../../Components/Loading/CadisLoader';


const validationSchema =
    Yup.object().shape({
        userid: Yup.string().required('validation.msg_username'),

    })

const ForgotPassword = ({ setUserId }: any) => {

    const { t } = useTranslation();

    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const goToLogin = () => {
        navigate("/login");
    }
    //useFormik
    const { values, handleSubmit, errors, touched, setFieldValue } = useFormik({
        initialValues: {
            userid: ''
        },
        onSubmit: async (values, props) => {
            setLoading(true);
            requestPasswordApiCall();
        },
        validationSchema: validationSchema
    });

    const requestPasswordApiCall = async () => {
        setLoading(true);
        const data = {
            'user_name': values.userid,
            'url': currentHost + "/resetPassword"
        }
        try {
            await apis.instance.post(`${ResetPassword}`, data)
                .then((resp: any) => {
                    if (resp.data.success) {
                        setOpenDialog(true);
                        setLoading(false);
                    } else {
                        if (resp.data.error.toLowerCase() === "invalid username")
                            toast.warning(t('responseMsg.msg_invalidUserId'));
                        else
                            toast.error(resp.data.error);
                        console.log(resp.data.error);
                        setLoading(false);
                    }
                })
                .finally(() => setLoading(false));
        } catch (error: any) {
            setLoading(false);
            toast.error(t('responseMsg.msg_wrongEntry'));
            console.log(error.message);
        }

    }

    return (
        <div>
            <Typography className="mt-0 mb-5 prelogin-title" sx={(theme) => theme.typography.h3}>{t('lbl_forgot_password')}</Typography>
            <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                    <FormControl variant="outlined">
                        <label className='form-label '>
                            {t('lbl_username')}
                            <span className='mandatory-star'></span>
                        </label>
                        <CadisTextField
                            autoComplete="false"
                            value={values.userid}
                            name="userid"
                            type="text"
                            onChange={async (event: any) => {
                                const userid = event.currentTarget.value;
                                setFieldValue("userid", userid.replace(/\s/g, ""));
                            }}
                            error={touched.userid && errors.userid ? true : false}
                            helperText={touched.userid && t(`${errors.userid ? errors.userid : ''}`)}
                        />
                    </FormControl>
                    <Grid container spacing={0} alignItems="center" >
                        <Grid item xs>
                            <CadisButton type='submit' variant="contained" color='primary' disabled={loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                                {t('lbl_continue')}
                            </CadisButton>
                        </Grid>
                        <Grid item xs="auto">
                            <div className="">
                                <CadisButton onClick={goToLogin}
                                    startIcon={<span className='cad-arrow-left'></span>}
                                    sx={{ textTransform: 'unset' }}>
                                    {t('lbl_goBackToLogin')}
                                </CadisButton>
                            </div>
                        </Grid>
                    </Grid>
                </Stack>
            </form>
            <Dialog
                open={openDialog}
                onClose={() => { return null }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" color="success.main">
                    {t('lbl_success')}
                </DialogTitle>
                <DialogContent>
                    {t('lbl_resetPasswordLink')}
                </DialogContent>
                <DialogActions>
                    <CadisButton variant="contained" color='primary' onClick={goToLogin}>
                        {t('lbl_close')}
                    </CadisButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ForgotPassword