import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../Common/helperFunction';
import { User } from '../Interfaces/IConstants';

const GetUser = () => {

  const navigate = useNavigate();

  useEffect(() => {
    const consultantName = sessionStorage.getItem("consultantName");

    if (consultantName) {
      navigate("/webassistantcall");
    } else {
      const user: User = isAuthenticated();

      if (user.id === 0) {
        return navigate('/login');
      };

      if (user.isCadisAdmin) {
        return navigate('/dashboard/Organizations');
      }

      if (user.isOrgAdmin) {
        return navigate('/dashboard/consultants');
      }

      if (user.isConsultant) {
        return navigate('/dashboard/assistants');
      }
    }
  }, [navigate])
  return (
    <></>
  )
};

export default GetUser;