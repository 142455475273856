import axios from "axios";

const loginInstance = axios.create({
    // baseURL: 'http://192.168.1.6:4000/api/',
    // baseURL: 'http://localhost:4000/api/',
    // baseURL: 'http://3.101.135.248/api/',
    baseURL: process.env.REACT_APP_BASE_URL + '/api/',
    headers: {
        "Content-Type": "application/json",
        timeout: 1000,
    }
});

//OLD INSTANCE KEPT FOR REFRENCE BY KUNAL
// const instance = () => {
//     const token = sessionStorage.getItem('token');

//     return axios.create({
//         // baseURL: 'http://192.168.1.6:4000/api/',
//         baseURL: 'http://localhost:4000/api/',
//         // baseURL: 'http://3.101.135.248/api/',
//         // baseURL: process.env.REACT_APP_BASE_URL + '/api/',
//         headers: {
//             "Content-Type": "application/json",
//             Authorization: `${token}`,
//             timeout: 1000,
//         }
//     })
// }

// const socketURL = 'http://192.168.1.6:4000';
// const socketURL = 'http://3.101.135.248/';


const instance = axios.create({
    // baseURL: 'http://192.168.1.6:4000/api/',
    // baseURL: 'http://localhost:4000/api/',
    // baseURL: 'http://3.101.135.248/api/',
    baseURL: process.env.REACT_APP_BASE_URL + '/api/',
    headers: {
        "Content-Type": "application/json",
        Authorization: `${sessionStorage.getItem('token')}`,
        timeout: 1000,
    }
})

const socketURL = process.env.REACT_APP_BASE_URL;

const apis = {
    loginInstance,
    instance,
    socketURL
}

export default apis;