import * as Yup from "yup";
import * as Helper from '../Common/helper';

export const OrganizationValidationSchema = Yup.object({
  org_name: Yup.string()
    .matches(/^[a-zA-Z0-9\s_-]*$/, 'validation.msg_invalidName')
    .required('validation.msg_userName'),
  org_email: Yup.string()
    .matches(/^[a-zA-Z0-9\s!#'*+,-./:;_]+@[a-zA-Z0-9\s-]+\.[a-zA-Z]{2,}$/, 'validation.msg_invalidEmailFormat')
    .email('validation.msg_invalidEmailFormat')
    .required('validation.msg_email'),
  org_phone_m: Yup.string()
    .required('validation.msg_phoneNumber'),
  org_phone_nm: Yup.string()
});

export const CadisAdminValidationSchema = Yup.object({
  cadisAdminFName: Yup.string()
    .matches(/^[a-zA-Z0-9\s_-]*$/, 'validation.msg_invalidName')
    .required("Name is required."),
  cadisAdminEmail: Yup.string()
    .matches(/^[a-zA-Z0-9\s!#'*+,-./:;_]+@[a-zA-Z0-9\s-]+\.[a-zA-Z]{2,}$/, 'validation.msg_invalidEmailFormat')
    .email("Invalid email address format.")
    .required("Email is required."),
  cadisAdminUsername: Yup.string()
    .required("Username is required."),
  cadisAdminPhoneM: Yup.string()
    .required("Phone number is required."),
  cadisAdminPhoneNM: Yup.string(),
});

export const AssistantValidationSchema = Yup.object({
  assistantFName: Yup.string()
    .matches(/^[a-zA-Z0-9\s_-]*$/, 'validation.msg_invalidName')
    .required("Name is required."),
  assistantEmail: Yup.string()
    .matches(/^[a-zA-Z0-9\s!#'*+,-./:;_]+@[a-zA-Z0-9\s-]+\.[a-zA-Z]{2,}$/, 'validation.msg_invalidEmailFormat')
    .email("Invalid email address format.")
    .required("Email is required."),
  assistantUsername: Yup.string()
    .required("Username is required."),
  assistantPhoneM: Yup.string()
    .required("Phone number is required."),
  assistantPhoneNM: Yup.string(),
});

export const consultantValidationSchema = Yup.object({
  constultantFName: Yup.string()
    .matches(/^[a-zA-Z0-9\s_-]*$/, 'validation.msg_invalidName')
    .required("First name is required."),
  consultantEmail: Yup.string()
    .matches(/^[a-zA-Z0-9\s!#'*+,-./:;_]+@[a-zA-Z0-9\s-]+\.[a-zA-Z]{2,}$/, 'validation.msg_invalidEmailFormat')
    .email("Invalid email address format.")
    .required("Email is required."),
  consultantUsername: Yup.string()
    .required("Username is required."),
  consultantPhoneM: Yup.string()
    .required("Phone number is required."),
  consultantPhoneNM: Yup.string(),
})

export const OrganizationAdminValidationSchema = Yup.object({
  isUserAssistant: Yup.boolean(),
  orgAdminFName: Yup.string()
    .matches(/^[a-zA-Z0-9\s_-]*$/, 'validation.msg_invalidName')
    .when(
      ['isUserAssistant'], {
      is: true,
      then: Yup.string().required('validation.msg_userName'),
      otherwise: Yup.string().required('validation.msg_userFirstName')
    },
    ),
  orgAdminEmail: Yup.string()
    .matches(/^[a-zA-Z0-9\s!#'*+,-./:;_]+@[a-zA-Z0-9\s-]+\.[a-zA-Z]{2,}$/, 'validation.msg_invalidEmailFormat')
    .email('validation.msg_invalidEmailFormat')
    .required('validation.msg_email'),
  orgAdminUsername: Yup.string()
    .required('validation.msg_username'),
  orgAdminPhoneM: Yup.string()
    .required('validation.msg_phoneNumber'),
  isSpecialities: Yup.boolean(),
  specialities: Yup.number()
    .when(
      ['isSpecialities'], {
      is: true,
      then: Yup.number().positive('validation.msg_speciality').required(),
      otherwise: Yup.number()
    }),
  orgAdminPhoneNM: Yup.string()
});

export const UserProfileValidationSchema = Yup.object({
  userFName: Yup.string()
    .matches(/^[a-zA-Z0-9\s_-]*$/, 'validation.msg_invalidName')
    .required('validation.msg_userFirstName'),
  userEmail: Yup.string()
    .matches(/^[a-zA-Z0-9\s!#'*+,-./:;_]+@[a-zA-Z0-9\s-]+\.[a-zA-Z]{2,}$/, 'validation.msg_invalidEmailFormat')
    .email('validation.msg_invalidEmailFormat')
    .required('validation.msg_email'),
  userUsername: Yup.string()
    .required('validation.msg_userID'),
  userPhoneM: Yup.string()
    .required('validation.msg_phoneNumber'),
  userPhoneNM: Yup.string(),
  isSpeciality: Yup.boolean(),
  speciality: Yup.number()
    .when(
      ['isSpeciality'], {
      is: true,
      then: Yup.number().positive('validation.msg_speciality').required(),
      otherwise: Yup.number(),
    }),
})

export const ChangePasswordValidationSchema =
  Yup.object().shape({
    currentpassword: Yup.string().required('validation.msg_changepassword'),
    password: Yup.string().notOneOf([Yup.ref('currentpassword')], 'validation.msg_currentnewpassword').
      required('validation.msg_new_password').min(Helper.minPasswordLength, `Password must be atleast ${Helper.minPasswordLength} characters long.`),
    confirmpassword: Yup.string().required('validation.msg_confirmPassword')
      .oneOf([Yup.ref('password')], 'validation.msg_newPasswordAndConfirmPasswordMatch')
  })

export const subscriptionValidationSchema = Yup.object().shape({
  planName: Yup.string().required('validation.msg_planName'),
  planDuration: Yup.string().notOneOf(['Select Plan Duration'], ('validation.msg_planDuration')),
  oneMonthPrice: Yup.string().required('validation.msg_price')
    .test('is-valid-number', 'Price must be a valid number.', function (value) {
      if (value === '.' || value === '-.') {
        return false;
      }
      return !isNaN(Number(value));
    }).test('maxValue', "validation.msg_subscriptionPlanMonthPrice", (value) => {
      if (value) {
        const floatValue = parseFloat(value);
        return floatValue <= 9999;
      }
      return true;
    }).test('is-positive', "validation.msg_priceMustGreaterThanZero", function (value) {
      return (
        value?.trim?.() !== undefined &&
        !isNaN(Number(value)) &&
        parseFloat(value) > 0
      )
    }),
  userCount: Yup.number()
    .required('validation.msg_userCount')
    .min(4, 'validation.msg_minUserCount')
    .max(9999, 'validation.msg_maxUserCount'),
  notifyExpiry: Yup.string().notOneOf(['Select Days'], ('validation.msg_notifyExpiry')),
  is_data_unlimited: Yup.boolean(),
  data_limit: Yup.string().when("is_data_unlimited", {
    is: false,
    then: Yup.string().required("Data usage for a subscription is required.")
  })
});

export const orgSubscriptionValidationSchema = Yup.object().shape({
  subscriptionPlan: Yup.number().required('validation.msg_planName').moreThan(0, 'validation.msg_planName'),
  paidAmount: Yup.number().required('validation.msg_amount').moreThan(0, 'validation.msg_amount'),
  subscriptionCount: Yup.number().required('validation.msg_subscriptionCount')
    .max(999, 'validation.msg_maxSubscriptionCount'),
  paymentMode: Yup.string().notOneOf(['Select Payment Mode'], ('validation.msg_paymentMode')),
})

