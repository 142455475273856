import { Box, Grid, Tooltip, Stack, Typography, ListItem, ListItemText, Chip, Drawer, Container, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ActiveStatus, ButtonActionEdit, ButtonEditReminderDate, DefaultGridRowSize, ExpiredStatus, GridRowsPerPageOptions, InActiveStatus, navigateToManageUsers, navigateToSubscriptionPlans, ButtonActionRenew, DefaultOnlineSubscriptionDetails, FormAddMode, FormRenewMode, navigateToPurchasedPlansMangeUser, ButtonActionDelete, ButtonBuyDataAddonList, ButtonShowRenewPlanDetails, DefaultRenewSubscriptionDetails } from "../../Common/helper";
import { useNavigate } from "react-router-dom";
import { auto } from '@popperjs/core';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { GridStyling } from "../../Common/sharedStyling";
import { GetGridNoRecordMessage, GetShortDateWithLuxon, formateDecimalValues, formateMbtoGb, isAuthenticated } from "../../Common/helperFunction";
import apis from "../../HttpConfig/Api";
import { DeleteSubscriptionPlanRoute, GetOrganizationSubscriptionDetailsRoute, UpdatePaymentStatus, UpdateReminderDate, getExpiredPlanRolledOverData } from "../../Common/api.routes";
import { useUserRoleContext } from "../../context/UserRoleContext";
import { useTranslation } from "react-i18next";
import BuildGridAction from "../../Components/Input/cadisBuildGridAction";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from 'dayjs';
import { toast } from "react-toastify";
import { currentHost } from "../../Common/helper";
import OrgAdminAddSubscriptionPlan from "./OrgAdminAddSubscriptionPlan";
import { IOnlineSubscriptionDetails, IrenewSubscriptionInfo } from "../../Interfaces/ISubscription";
import PaymentStatusDialog from "./PaymentStatusDialog";
import CadisButton from "../../Components/Input/CadisButton";
import BuyDataAddonPackList from "../DataAddOns/BuyDataAddonPackList";
import RenewedSubscriptionDetails from "./RenewedSubscriptionDetails";
import React from "react";

const MyPlans = () => {
    const [orgSubscriptionDetailsGridData, setOrgSubscriptionDetailsGridData] = useState([])
    const PageTitleRef: any = useRef();
    const tableGridRef: any = useRef();
    const [pageSize, setPageSize] = useState<number>(DefaultGridRowSize);
    const [tableHeight, setTableHeight] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);
    const [expireDate, setExpireDate] = useState(null);
    const [InitialSelectedDate, setInitialSelectedDate] = useState(null);
    const [renewSubscriptionDrawer, setRenewSubscriptionDrawer] = useState<boolean>(false);
    const [renewCurrentSubscriptionPlan, setRenewCurrentSubscriptionPlan] = useState<IOnlineSubscriptionDetails>(DefaultOnlineSubscriptionDetails);
    const [onlineSubscriptionDetailsMode, setOnlineSubscriptionDetailsMode] = useState(FormAddMode);
    const [subscriptionPlanDetails, setSubscriptionPlanDetails] = useState<IrenewSubscriptionInfo>(DefaultRenewSubscriptionDetails)
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [openDataAddonList, setOpenDatAddonList] = useState(false);
    const [isPaymentSuccessState, setIsPaymentSuccessState] = useState(true);
    const [isRenewPlanDetailsDrawerOpen, setIsRenewPlanDetailsDrawerOpen] = useState(false);
    const [isRenewPlanInfoPopupOpen, setIsRenewPlanInfoPopupOpen] = useState(false);
    const [remainingData, setRemainingData] = useState(0);
    const [renewPlanWarningMsg, setRenewPlanWarningMsg] = useState('');
    const [selectedPlanId, setSelectedPlanId] = useState(0);
    const { handleToggleLoader } = useUserRoleContext();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const user = isAuthenticated();
    const [openDeleteDialog, setOpenDeleteDialog] = useState({
        'open': false,
        'subscriptionPlanId': 0,
        'subscriptionPlan': ""
    });

    const handleCloseDelete = () => {
        setOpenDeleteDialog({
            'open': false,
            'subscriptionPlanId': 0,
            'subscriptionPlan': ""
        });
    };
    const path = window.location.pathname;
    const isPaymentSuccess = path.includes("1");
    const isPaymentCanceled = path.includes("2");
    const isPaymentSuccessForDataAddon = path.includes("4");
    const isPaymentCanceledForDataAddon = path.includes("5");

    //paymentStatus dialog 
    useEffect(() => {
        const handlePaymentStatus = () => {
            if (isPaymentSuccess) {
                setPaymentStatus(true); // Open dialog with success message
                setIsPaymentSuccessState((pre) => pre && isPaymentSuccess)
            } else if (isPaymentCanceled) {
                setPaymentStatus(true); // Open dialog with fail message
                setIsPaymentSuccessState((pre) => pre && !isPaymentCanceled)
            } else {
                setPaymentStatus(false);
            }
        };
        const sendSessionIdIfNeeded = () => {
            if (isPaymentSuccess || isPaymentCanceled) {
                sendSessionId();
            }
        };
        handlePaymentStatus();
        sendSessionIdIfNeeded();
    }, []);

    // send SessionId
    const sendSessionId = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get("session_id");
        const status = isPaymentSuccess ? "Succeeded" : "Cancelled";
        const data = {
            "sessionId": sessionId,
            "status": status,
        }
        try {
            await apis.instance.post(`${UpdatePaymentStatus}`, data)
        } catch (error: any) {
            if (error?.response.status !== 401) toast.error(error.response?.data?.message)
        }
    }

    const columns: GridColDef[] = [
        { field: 'subscription_id', width: 1, hide: true },
        { field: 'plan_id', width: 1, hide: true },
        {
            field: 'name', flex: 1, headerName: 'Plan Name', minWidth: 160, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: true,
            renderCell: ({ row }: any) => {
                return <>
                    <ListItem disablePadding>
                        <Tooltip title={`${row.name}`} >
                            <ListItemText sx={{ textTransform: 'capitalize', }} className="long-col-name-ellipsis"
                                primary={`${row.name}`}
                                secondary=""
                            />
                        </Tooltip>
                    </ListItem>
                </>
            },
        },
        {
            field: 'active_user_count', flex: 1, headerName: 'Assigned Users', minWidth: 106, maxWidth: 160, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', sortable: false,
            renderCell: ({ row }: any) => (
                `${row.active_user_count}`
            ),
        },
        {
            field: 'users_for_assignment', flex: 1, headerName: 'Available User Count for Assignment', minWidth: 140, maxWidth: 200, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', sortable: false,
            renderCell: ({ row }: any) => (
                `${row.users_for_assignment}`
            ),
        },
        {
            field: 'subscription_count', flex: 1, headerName: 'User Bundles', minWidth: 120, maxWidth: 160, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', sortable: false,
            renderCell: ({ row }: any) => (
                <Typography variant="inherit" component="div" color="inherit" className="">{row.subscription_count}&nbsp;
                    <Typography variant="body1" component="span" fontSize={'0.8rem'} color="GrayText">({row.user_count} {t(`lbl_users`)})</Typography>
                </Typography>
            ),
        },
        {
            field: 'actual_start_date', flex: 1, headerName: 'Purchase Date', minWidth: 130, maxWidth: 160, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', sortable: false,
            renderCell: ({ row }: any) => (
                row.actual_start_date ? GetShortDateWithLuxon(row.actual_start_date) : ''
            ),
        },
        {
            field: 'actual_end_date', flex: 1, headerName: 'Expiry Date', minWidth: 160, maxWidth: 200, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', sortable: true,
            renderCell: ({ row }: any) => {
                return <ListItem disablePadding>
                    {row.actual_end_date ?
                        <ListItemText primary={GetShortDateWithLuxon(row.actual_end_date)}
                            secondary={<React.Fragment>
                                {
                                    row.actual_end_date && row.renew?.length ? <Tooltip title={row.renew[0].payment_status === "Succeeded" ? 'Subscription is already renewed' : row.renew[0].payment_status === "Failed" ? 'Subscription pre-renewal payment has failed.' :
                                        row.renew[0].payment_status === "Pending" ? "Subscription pre-renewal payment is in progress." : ""}>
                                        <Typography variant="inherit" color={row.renew[0].payment_status === "Succeeded" ? 'success.main' : row.renew[0].payment_status === "Failed" ? 'error' : row.renew[0].payment_status === "Pending" ? 'warning.main' : ""}>
                                            {row.renew[0].payment_status === "Succeeded" ? '[Pre-Renewed]' : row.renew[0].payment_status === "Failed" ? '[Renewal Failed]' :
                                                row.renew[0].payment_status === "Pending" ? '[Renewal Pending]' : ""}
                                        </Typography>
                                    </Tooltip> : ""
                                }
                            </React.Fragment >}
                        /> : ''}
                </ListItem >
            }
        },
        {
            field: 'actual_reminder_date', flex: 1, headerName: 'Plan Expiry Reminder Date', minWidth: 130, maxWidth: 160, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', sortable: false,
            renderCell: ({ row }: any) => (
                row.actual_reminder_date ? GetShortDateWithLuxon(row.actual_reminder_date) : ''
            ),
        },
        {
            field: 'subscription_status', headerName: 'Status',
            cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', minWidth: 100, maxWidth: 160, flex: 1, sortable: true,
            sortComparator: (row1, row2) => {
                return row1.trim().localeCompare(row2.trim())
            },
            renderCell: ({ row }: any) => {
                return <Chip size="small" label={row.subscription_status} color={row.subscription_status === "Payment Pending" ? "warning" : row.subscription_status === "Active" ? 'success' : 'error'} />
            }
        },
        {
            field: 'rollover_data_limit', headerName: 'Rolled Over Data',
            cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', minWidth: 110, maxWidth: 160, flex: 1, sortable: false,
            renderCell: ({ row }: any) => row.rollover_data_limit == 0 ? '-' : !isNaN(row.rollover_data_limit) ? (formateMbtoGb(row.rollover_data_limit)) : "-"
        },
        {
            field: 'data_limit', headerName: 'Subscription Data',
            cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', minWidth: 120, maxWidth: 160, flex: 1, sortable: false,
            renderCell: ({ row }: any) => <span>{row.is_data_unlimited === 1 ? "Unlimited" : (!isNaN(row.data_limit) && row.data_limt !== null) ? (formateMbtoGb(row.data_limit)) : "-"}</span>
        },
        {
            field: 'addon_data_limit', headerName: 'Add-On Data',
            cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', minWidth: 160, maxWidth: 200, flex: 1, sortable: false,
            renderCell: ({ row }: any) => {
                return <ListItem disablePadding>
                    <ListItemText primary={row.addon_data_limit == 0 ? '-' : !isNaN(row.addon_data_limit) ? (formateMbtoGb(row.addon_data_limit)) : "-"}
                        secondary={<React.Fragment>
                            {
                                row?.pending_addon_data_limit > 0 ? <Tooltip title={`Payment for ${formateMbtoGb(row?.pending_addon_data_limit)} data is in progress. Data will be available once the payment succeeds.`}>
                                    <Typography variant='inherit'>
                                        [ +{formateMbtoGb(row?.pending_addon_data_limit)} Pending]
                                    </Typography>
                                </Tooltip> : ""
                            }
                        </React.Fragment>}
                    />
                </ListItem>
            }
        },
        {
            field: 'total_data_used', headerName: 'Total Consumed Data',
            cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', minWidth: 110, maxWidth: 160, flex: 1, sortable: false,
            renderCell: ({ row }: any) => (<span>{!isNaN(row.total_data_used) && row.total_data_used !== null && row.total_data_used != 0 && row.is_data_unlimited == 0 ? (formateMbtoGb(row.total_data_used)) : "-"}</span>)
        },
        {
            field: 'remaining_data_limit', headerName: 'Total Remaining Data',
            cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header wrapped-column-header', minWidth: 110, maxWidth: 180, flex: 1, sortable: false,
            renderCell: ({ row }: any) => <span className={row.remaining_data_Percentage <= 10 ? "text-danger" : ""}>{!isNaN(row.remaining_data_limit) ? (formateMbtoGb(row.remaining_data_limit)) : "-"}</span>
        },
        {
            field: 'actions', headerAlign: 'center',
            cellClassName: 'app-theme-cell bg-action', headerClassName: ' app-theme-header bg-action',
            flex: 1,
            headerName: 'Actions', align: 'center',
            minWidth: 240, maxWidth: 340, sortable: false,
            renderCell: (params: any) => {
                const subscriptionStatus = (params.row.subscription_status === "Payment Pending"
                    || params.row.subscription_status === "Payment cancelled"
                    || params.row.subscription_status === "Payment Failed");

                const isShowDataAddOn = (!subscriptionStatus
                    && params.row.subscription_status
                    && params.row.is_data_unlimited == 0 &&
                    params.row.subscription_status !== ExpiredStatus
                    && params.row.is_disabled == 0)

                const renewSubscriptionPaymentStatus = (params.row.renew_payment_status === "Pending") ? true : false
                return <Stack direction="row" spacing={0}>
                    {!subscriptionStatus ? (
                        <BuildGridAction
                            title="Manage User"
                            onClickAction={(e: any) => onClick(e, params)}
                            actionParams={params}
                            placement="bottom-end"
                            color="primary"
                            ActionId={ButtonActionEdit}
                        >
                            <span className="cad-users-manage"></span>
                        </BuildGridAction>
                    ) : <Box className="grid-act-btn"></Box>}

                    {params.row.subscription_status === ActiveStatus ? (
                        <BuildGridAction
                            title="Update Plan Expiry Reminder Date"
                            onClickAction={(e: any) => onClick(e, params)}
                            actionParams={params}
                            placement="bottom-end"
                            color="primary"
                            ActionId={ButtonEditReminderDate}
                        >
                            <span className="cad-notification-date"></span>
                        </BuildGridAction>
                    ) : <Box className="grid-act-btn"></Box>}
                    {(!subscriptionStatus && params.row.subscription_status === ActiveStatus || params.row.subscription_status === ExpiredStatus) && ((params.row.renew.length === 0) || (params.row.renew[0]?.payment_status === "Cancelled" && params.row.renew.length === 1)) ?
                        <BuildGridAction
                            title={params.row.plan_status === 0 ? t("lbl_planNoLongerActive") : t("lbl_renewSubscription")}
                            onClickAction={(e: any) => ((params.row.plan_status && !renewSubscriptionPaymentStatus) ? onClick(e, params) : "")}
                            actionParams={params}
                            placement="bottom-end"    
                            color={params.row.plan_status === 0 ? "default" : "primary"}
                            ActionId={ButtonActionRenew}
                        >
                            {<span className="cad-renew-subscription"></span>}
                        </BuildGridAction>
                        : params.row?.renew.length && params.row.renew[0]?.payment_status !== "Cancelled" ? <BuildGridAction
                            title='View Renewed Subscription Details'
                            onClickAction={(e: any) => onClick(e, params)}
                            actionParams={params}
                            placement="bottom-end"
                            color="primary"
                            ActionId={ButtonShowRenewPlanDetails}
                        >
                            <span className="cad-tick-icon"></span>
                        </BuildGridAction> : <Box className="grid-act-btn"></Box>
                    }
                    {isShowDataAddOn ?
                        <BuildGridAction
                            title='Buy Add-On Data Pack'
                            onClickAction={(e: any) => onClick(e, params)}
                            actionParams={params}
                            placement="bottom-end"
                            color="primary"
                            ActionId={ButtonBuyDataAddonList}
                        >
                            <span className="cad-plus"></span>
                        </BuildGridAction> : <Box className="grid-act-btn"></Box>
                    }
                    {(params.row.subscription_status === "Payment cancelled" || params.row.subscription_status === "Payment Failed") ?
                        <BuildGridAction
                            title='Delete Subscription Record'
                            onClickAction={(e: any) => onClick(e, params)}
                            actionParams={params}
                            placement="bottom-end"
                            color="error"
                            ActionId={ButtonActionDelete}
                        >
                            <span className="cad-trash-icon"></span>
                        </BuildGridAction>
                        : <Box className="grid-act-btn"></Box>
                    }
                </Stack>
            }
        }
    ];

    const GetOrgSubscriptionPlanDetails = async () => {
        handleToggleLoader(true);
        const roleId: number = Number(sessionStorage.getItem("role"));
        const params = {
            'org_id': user.orgId,
            'role_id': roleId
        }
        try {
            const response = await apis.instance.get(`${GetOrganizationSubscriptionDetailsRoute}`, { params });
            if (response.data.success) {
                const responseData = response.data.data.map((item: any) => ({
                    ...item,
                    user_bundle: `${item.subscription_count} (${item.user_count})`,
                    subscription_id: item.subscription_id,
                    subscription_status: getStatus(item),
                    defaultDate: dayjs(item.actual_reminder_date),
                    users_for_assignment: item.user_count - item.active_user_count,
                }));
                responseData.reverse();
                setOrgSubscriptionDetailsGridData(responseData);
            }
            handleToggleLoader(false);
        } catch (err) {
            handleToggleLoader(false);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const getStatus = (data: any) => {
        const paymetStatus = data.payment_status;
        const isDisabled = data.is_disabled;
        // Determine status based on conditions
        const status = isDisabled ? InActiveStatus : data.is_expired ? ExpiredStatus
            : paymetStatus === "Succeeded" ? ActiveStatus : paymetStatus === "Cancelled" ? "Payment cancelled"
                : paymetStatus === "Failed" ? "Payment Failed" : "Payment Pending";

        return status;
    };

    useEffect(() => {
        if (isPaymentCanceledForDataAddon || isPaymentSuccessForDataAddon) {
            GetOrgSubscriptionPlanDetails();
        }
        GetOrgSubscriptionPlanDetails();
        getPageTitleHeight();
    }, [window.location.pathname]);

    // dynamic scrollbar for grid view start
    const getPageTitleHeight = () => {
        let pageTitleHeight: any = 0;
        if (PageTitleRef && PageTitleRef.current) {
            pageTitleHeight = PageTitleRef.current.clientHeight;
        }
        setTableHeight(window.innerHeight - pageTitleHeight - 82);
    };
    window.addEventListener("resize", getPageTitleHeight);

    const onClick = (e: any, params: any) => {
        e.stopPropagation();
        OnActionClick(e.currentTarget.id, params);
    };

    const onFormSubmitComplete = (resp: any) => {
        if (resp.data.success) {
            setIsDatePickerOpen(false);
            toast.success((t('responseMsg.msg_reminderDate')))
            GetOrgSubscriptionPlanDetails();
            setLoading(false);
        } else {
            if (resp.data.error === "please select a future date for the reminder")
                toast.error(t('responseMsg.msg_reminderDateUpdatedToTodaysDate'));
            else
                toast.error(resp.data.error);
            setLoading(false);
        }
    };

    const getUpdatedPlanDetails = async (subscriptionId: number) => {
        const params = { "subscriptionId": subscriptionId };
        try {
            const resp = await apis.instance.get(`${getExpiredPlanRolledOverData}`, { params });
            if (resp.data.success) {
                return resp.data;
            } else {
                toast.error(resp.data.error || t('responseMsg.msg_wrongEntry'));
            }
        } catch (error) {
            toast.error(t('responseMsg.msg_wrongEntry'));
        }
    };

    const OnActionClick = async (actionId: string, params: any) => {
        switch (actionId) {
            case ButtonActionEdit:
                navigate(navigateToPurchasedPlansMangeUser, {
                    state: { orgSubscriptionData: params.row }
                }
                );
                break;
            case ButtonEditReminderDate:
                setInitialSelectedDate(params.row.defaultDate);
                setExpireDate(params.row.actual_end_date);
                setIsDatePickerOpen(true);
                setSelectedSubscriptionId(params.row.subscription_id);
                break;
            case ButtonActionRenew:
                const subscriptionIdOrgAdmin = params.row.subscription_id;
                const updatedPlanDetailsOrgAdmin = await getUpdatedPlanDetails(subscriptionIdOrgAdmin);
                if (updatedPlanDetailsOrgAdmin.success) {
                    const renewSubscriptionOrgAdmin = {
                        "userName": user.name,
                        "userId": user.userId,
                        "userEmail": user.userEmail,
                        "orgId": user.orgId,
                        "planId": updatedPlanDetailsOrgAdmin?.data.plan_id,
                        "planName": updatedPlanDetailsOrgAdmin?.data.name,
                        "planAmount": (updatedPlanDetailsOrgAdmin?.data.total_price),
                        "noOfSubscription": params.row.subscription_count,
                        "noOfUsers": (updatedPlanDetailsOrgAdmin?.data.max_user_bundle_count * params.row.subscription_count),
                        "successUrl": currentHost + "/dashboard/myPlans/1",
                        "cancelUrl": currentHost + "/dashboard/myPlans/2",
                        "subscriptionId": params.row.subscription_id,
                        'dataLimit': (updatedPlanDetailsOrgAdmin?.data.data_limit * params.row.subscription_count),
                        'isDataUnlimited': updatedPlanDetailsOrgAdmin?.data.is_data_unlimited,
                        'isPlanExpired': updatedPlanDetailsOrgAdmin?.data.is_expired,
                        'totalRolloverData': updatedPlanDetailsOrgAdmin?.data.rolloverDataLimit / 1024,
                        "billing_cycle": updatedPlanDetailsOrgAdmin?.data.billing_cycle
                    }
                    setOnlineSubscriptionDetailsMode(FormRenewMode);
                    setRenewCurrentSubscriptionPlan(renewSubscriptionOrgAdmin);
                    if (params.row.is_data_unlimited === 0 && params.row.is_expired === 0 && ((params.row.renew[0]?.payment_status !== "Cancelled" || params.row.renew.length >= 1) || (params.row.renew.length === 0))) {
                        if (params.row.remaining_data_Percentage <= 10 && params.row.remaining_data_limit !== 0) {
                            setRenewPlanWarningMsg("lbl_dataRunningLow")
                        } else if (params.row.remaining_data_limit == 0) {
                            setRenewPlanWarningMsg("lbl_dataFinished")
                        } else {
                            setRenewPlanWarningMsg("lbl_renewPlanWarning")
                        }
                        setIsRenewPlanInfoPopupOpen(true)
                    } else {
                        setRenewSubscriptionDrawer(true);
                    }
                }
                break;
            case ButtonActionDelete:
                setOpenDeleteDialog({
                    'open': true,
                    'subscriptionPlanId': params.row.subscription_id,
                    'subscriptionPlan': params.row.name
                });
                break;
            case ButtonBuyDataAddonList:
                setOpenDatAddonList(true);
                setRemainingData(params.row.remaining_data_limit);
                setSelectedPlanId(params.row.subscription_id);
                break;
            case ButtonShowRenewPlanDetails:
                const renewInfoDetails: IrenewSubscriptionInfo = {
                    planName: params.row.renew[0].name,
                    startDate: GetShortDateWithLuxon(params.row.renew[0].actual_start_date),
                    endDate: GetShortDateWithLuxon(params.row.renew[0].actual_end_date),
                    paymentStatus: params.row.renew[0].payment_status,
                    totalData: params.row.renew[0].is_data_unlimited === 1 ? 0 : params.row.renew[0].data_limit
                }
                setSubscriptionPlanDetails(renewInfoDetails)
                setIsRenewPlanDetailsDrawerOpen(true)
                const renewSubscriptionOrg = {
                    "userName": user.name,
                    "userId": user.userId,
                    "userEmail": user.userEmail,
                    "orgId": user.orgId,
                    "planId": params.row.plan_id,
                    "planName": params.row.name,
                    "planAmount": params.row.plan_total_price,
                    "noOfSubscription": params.row.subscription_count,
                    "noOfUsers": params.row.user_count,
                    "successUrl": currentHost + "/dashboard/myPlans/1",
                    "cancelUrl": currentHost + "/dashboard/myPlans/2",
                    "subscriptionId": params.row.subscription_id,
                    'dataLimit': params.row.data_limit,
                    'isDataUnlimited': params.row.is_data_unlimited == 1 ? true : false,
                    'isPlanExpired': params.row.is_expired == 1 ? true : false,
                    'totalRolloverData': parseFloat(params.row.rollover_addon_data) + parseFloat(params.row.addon_data_limit)
                }
                setOnlineSubscriptionDetailsMode(FormRenewMode);
                setRenewCurrentSubscriptionPlan(renewSubscriptionOrg);
                break;
            default:
                break;
        }
    };

    const formatDate = (date: any) => {
        if (dayjs(date).isValid()) {
            return dayjs(date).format('YYYY-MM-DD');
        }
        return '';
    };

    const formattedReminderDate = formatDate(selectedDate);
    const endDate = dayjs(expireDate);

    const handleDateChange = (date: any) => {
        setSelectedDate(date);
    };

    const handleOKButtonClick = () => {
        setIsDatePickerOpen(false)
        EditReminderDate()
    };

    const EditReminderDate = async () => {
        const SubscriptionData = {
            "reminderDate": formattedReminderDate,
            "subscription_id": selectedSubscriptionId,
            "org_id": user.orgId
        }
        try {
            await apis.instance.put(`${UpdateReminderDate}`, SubscriptionData)
                .then((resp: any) => {
                    onFormSubmitComplete(resp)
                    setSelectedDate(null)
                })
                .finally(() => setLoading(false));
        } catch (error: any) {
            setLoading(false);
            toast.error(t('responseMsg.msg_wrongEntry'));
        }
    };

    const handleBuyNewSubscription = () => {
        navigate(navigateToSubscriptionPlans + "/orgAdmins/0")
    };

    const handleClose = () => {
        navigate("/dashboard/myPlans/0");
        setPaymentStatus(false);
        GetOrgSubscriptionPlanDetails();
    };

    const DeleteSubscriptionPlan = async () => {
        const params = {
            'subscriptionId': openDeleteDialog.subscriptionPlanId
        }
        try {
            const resp = await apis.instance.get(`${DeleteSubscriptionPlanRoute}`, { params });
            if (resp.data.success) {
                handleCloseDelete();
                GetOrgSubscriptionPlanDetails();
                toast.success(t('responseMsg.msg_deleteSubscriptionPlan'));
            } else {
                toast.error(resp.data.error);
            }
        } catch (error: any) {
            toast.error(t('responseMsg.msg_wrongEntry'));
        }
    };

    return (
        <div>
            <Box ref={PageTitleRef} className="page-title-row pb-3">
                <Grid
                    container
                    alignItems={"center"}
                    textAlign={"left"}
                    rowSpacing={2}
                    columnSpacing={2} >
                    <Grid item xs={12} md={auto} mr={auto} sm padding={0} className="page-title-box">
                        <Typography
                            className="page-title mt-0 mb-0"
                            sx={(theme) => theme.typography.h5}>
                            {t('lbl_purchasedPlans')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={auto} padding={0}>
                        <Button
                            onClick={handleBuyNewSubscription}
                            startIcon={<span className='cad-plus'></span>}
                            variant="contained" sx={{ my: .5, mr: 1 }} color="primary">
                            {t('lbl_buyNewSubscription')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={GridStyling} ref={tableGridRef} height={tableHeight}>
                <DataGrid
                    components={{
                        NoRowsOverlay: GetGridNoRecordMessage,
                    }}
                    rows={orgSubscriptionDetailsGridData}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                    rowsPerPageOptions={GridRowsPerPageOptions}
                    getRowId={(row: any) => row.subscription_id}
                    autoHeight={false}
                    autoPageSize={false}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    disableSelectionOnClick={true}
                    headerHeight={72}
                />
            </Box>
            <Drawer
                anchor='right'
                open={isDatePickerOpen}
                onClose={() => { return null }}
            >
                <Box role="presentation">
                    <Box sx={{ pt: 1.5, position: 'sticky', top: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }} >
                        <Container sx={{ alignItems: 'center', display: 'flex' }} disableGutters>
                            <Box sx={{ width: '40px', height: '40px', flexGrow: 1, display: { xs: 'flex', pr: 2 } }}>
                                <IconButton onClick={() => { setSelectedDate(null); setIsDatePickerOpen(false) }} sx={{}} className='c-pointer'>
                                    <span className='cad-close'></span>
                                </IconButton>
                            </Box>
                        </Container>
                        <Box sx={{ mb: 2, pb: 2, mt: 2, display: 'flex' }}>
                            <Typography className="mt-0 mb-0 slide-right-title" sx={(theme) => theme.typography.h5}> {t(`lbl_updateReminderDate`)} </Typography>
                        </Box>
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box sx={{ paddingX: '20px' }}>
                            <StaticDatePicker
                                value={InitialSelectedDate}
                                onChange={handleDateChange}
                                minDate={dayjs().startOf('day')}
                                maxDate={dayjs(endDate).startOf('day')}
                                onAccept={handleOKButtonClick}
                                slots={
                                    {
                                        actionBar: () => (<div className="MuiDialogActions-root MuiDialogActions-spacing MuiPickersLayout-actionBar">
                                            <Button disabled={!selectedDate || (JSON.stringify(selectedDate) == JSON.stringify(InitialSelectedDate))} onClick={() => handleOKButtonClick()} color="primary" variant="contained">
                                                {t("lbl_ok")}
                                            </Button>
                                            <Button onClick={() => { setSelectedDate(null); setIsDatePickerOpen(false) }} color="error" variant="contained">
                                                {t("lbl_cancel")}
                                            </Button>
                                        </div>)
                                    }
                                }
                            />
                        </Box>
                    </LocalizationProvider>
                </Box>
            </Drawer>
            <OrgAdminAddSubscriptionPlan
                isOpenAddSelectedPlan={renewSubscriptionDrawer}
                renewCurrentSubscriptionPlan={renewCurrentSubscriptionPlan}
                setRenewSubscriptionDrawer={setRenewSubscriptionDrawer}
                onlineSubscriptionDetailsMode={onlineSubscriptionDetailsMode}
            />
            <BuyDataAddonPackList
                setOpenDatAddonList={setOpenDatAddonList}
                openDataAddonList={openDataAddonList}
                remainingData={remainingData}
                selectedPlanId={selectedPlanId}
            />
            <PaymentStatusDialog
                open={paymentStatus}
                onClose={handleClose}
                isPaymentSuccess={isPaymentSuccessState}
            />
            <RenewedSubscriptionDetails
                setRenewSubscriptionDrawer={setRenewSubscriptionDrawer}
                currentRenewPlanDetails={subscriptionPlanDetails}
                setIsRenewPlanDetailsDrawerOpen={setIsRenewPlanDetailsDrawerOpen}
                isRenewPlanDetailsDrawerOpen={isRenewPlanDetailsDrawerOpen} />
            <Dialog
                open={openDeleteDialog.open}
                onClose={() => { return null }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('lbl_confirmation')}
                </DialogTitle>
                <DialogContent>
                    {t('lbl_confirmationForDeleteSubscriptionPlan')}
                </DialogContent>
                <DialogActions>
                    <CadisButton onClick={DeleteSubscriptionPlan} variant="contained" color='error'>
                        {t('lbl_yes')}
                    </CadisButton>
                    <CadisButton onClick={handleCloseDelete} variant="contained" color='success'>
                        {t('lbl_no')}
                    </CadisButton>
                </DialogActions>
            </Dialog>

            {/* information popup for renew subscription */}

            <Dialog
                open={isRenewPlanInfoPopupOpen}
                onClose={() => { return null }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('lbl_confirmation')}
                </DialogTitle>
                <DialogContent>
                    <span dangerouslySetInnerHTML={{ __html: t(renewPlanWarningMsg) }}></span>
                </DialogContent>
                <DialogActions>
                    <CadisButton variant="contained" color="success" onClick={() => {
                        setIsRenewPlanInfoPopupOpen(false)
                        setRenewSubscriptionDrawer(true)
                    }}>{t('lbl_yes')}</CadisButton>
                    <CadisButton variant="contained" color="primary" onClick={() => setIsRenewPlanInfoPopupOpen(false)}>{t('lbl_no')}</CadisButton>
                </DialogActions>
            </Dialog>

        </div>
    );
}
export default MyPlans;