import { Box, Button, Card, CardActions, CardContent, Divider, Grid, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ButtonActionAdd, ButtonActionEdit, ButtonActionSelectPlan, DefaultSubscriptionPlan, FormAddMode, FormUpdateMode, FormViewMode, } from "../../Common/helper";
import AddUpdateSubscriptionPlans from "./AddUpdateSubscriptionPlans";
import { ISubscriptionPlan } from "../../Interfaces/ISubscription";
import apis from "../../HttpConfig/Api";
import { GetAllBillingCycle, GetSubscriptionPlan, UpdatePaymentStatus } from "../../Common/api.routes";
import { useTranslation } from "react-i18next";
import { formateDecimalValues, getAccuratePrice } from "../../Common/helperFunction";
import { grey } from "@mui/material/colors";
import { useUserRoleContext } from "../../context/UserRoleContext";
import { ROLE } from "../../Interfaces/IConstants";
import OrgAdminAddSubscriptionPlan from "./OrgAdminAddSubscriptionPlan";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PaymentStatusDialog from "./PaymentStatusDialog";

const SubscriptionPlans = () => {
    const [currentSubscriptionPlans, setCurrentSubscriptionPlans] = useState<ISubscriptionPlan>(DefaultSubscriptionPlan);
    const [SubscriptionCreationMode, setSubscriptionCreationMode] = useState(FormViewMode);
    const [addSubscriptionDrawer, setAddSubscriptionDrawer] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [planDuration, setPlanDuration] = useState([]);
    const { t } = useTranslation();
    const { userLoginRole, handleToggleLoader } = useUserRoleContext();
    const [localRole, setLocalRole] = useState(userLoginRole);
    const [AddSelectedPlanDrawer, setAddSelectedPlanDrawer] = useState<boolean>(false);
    const [planDetails, setPlanDetails] = useState('');
    const [paymentStatus, setPaymentStatus] = useState(false);
    const navigate = useNavigate();

    const path = window.location.pathname;
    const isPaymentSuccess = path.includes("1");
    const isPaymentCanceled = path.includes("2");

    useEffect(() => {
        const sessionStorageUserRole: any = Number(sessionStorage.getItem("role"));
        if (sessionStorageUserRole) {
            setLocalRole(sessionStorageUserRole);
        } else {
            setLocalRole(userLoginRole);
        }
    }, [userLoginRole]);

    //paymentStatus dialog 
    useEffect(() => {
        const handlePaymentStatus = () => {
            if (isPaymentSuccess) {
                setPaymentStatus(true); // Open dialog with success message
            } else if (isPaymentCanceled) {
                setPaymentStatus(true); // Open dialog with fail message
            } else {
                setPaymentStatus(false);
            }
        };
        const sendSessionIdIfNeeded = () => {
            if (isPaymentSuccess || isPaymentCanceled) {
                sendSessionId();
            }
        };
        handlePaymentStatus();
        sendSessionIdIfNeeded();
    }, []);

    // send SessionId
    const sendSessionId = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get("session_id");
        const status = isPaymentSuccess ? "Succeeded" : "Cancelled";
        const data = {
            "sessionId": sessionId,
            "status": status,
        }
        try {
            await apis.instance.post(`${UpdatePaymentStatus}`, data)
        } catch (error: any) {
            toast.error(t('responseMsg.msg_wrongEntry'));
        }
    }

    const OnActionClick = (actionId: string, planDetails: any) => {
        switch (actionId) {
            case ButtonActionAdd:
                setSubscriptionCreationMode(FormAddMode);
                setCurrentSubscriptionPlans(DefaultSubscriptionPlan)
                setAddSubscriptionDrawer(true);
                break;
            case ButtonActionEdit:
                setSubscriptionCreationMode(FormUpdateMode);
                setAddSubscriptionDrawer(true)
                const subscriptionPlanEdit = {
                    'sub_PlanId': planDetails.plan_id,
                    'sub_name': planDetails.name,
                    'sub_billing_cycle': planDetails.billing_cycle,
                    'sub_min_user_bundle_count': planDetails.max_user_bundle_count,
                    'sub_one_month_price': planDetails.one_month_price,
                    'sub_total_price': planDetails.total_price,
                    'sub_remind_before': planDetails.remind_before,
                    'data_limit': planDetails.data_limit,
                    'is_data_unlimited': planDetails.is_data_unlimited == 1 ? true : false,
                    'total_price': planDetails.total_price,
                    "is_plan_disable": planDetails.plan_status == 1 ? true : false,
                    "total_purchases": planDetails.total_purchases,
                    "total_org_count": planDetails.total_org_count
                }
                setCurrentSubscriptionPlans(subscriptionPlanEdit)
                break;
            case ButtonActionSelectPlan:
                setCurrentSubscriptionPlans(DefaultSubscriptionPlan);
                setAddSelectedPlanDrawer(true);
                break;
            default:
                break;
        }
    }

    const handleClickEvent = (e: any, planDetails: any) => {
        OnActionClick(e.currentTarget.id, planDetails);
    };

    const handleSelectPlanClick = (e: any, planDetails: any) => {
        OnActionClick(e.currentTarget.id, planDetails);
        setPlanDetails(planDetails);
    };

    const GetAllPlanDuration = async () => {
        try {
            const response = await apis.instance.get(`${GetAllBillingCycle}`);
            const responseData = response?.data?.data?.[0]?.COLUMN_TYPE;
            const match = responseData.match(/^enum\('(.*)'\)$/);
            const durationArray = match ? match[1].split("','") : [];
            durationArray.unshift("Select Plan Duration");
            setPlanDuration(durationArray)
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const GetAllSubscriptionPlan = async () => {
        try {
            const response = await apis.instance.get(`${GetSubscriptionPlan}`);
            const responseData = response?.data?.data;
            responseData.reverse()
            setSubscriptionPlans(responseData);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        GetAllSubscriptionPlan();
        const localUserRole = JSON.parse(sessionStorage.getItem("role") || "null")
        if (localUserRole === ROLE.CadisAdmin) GetAllPlanDuration();
    }, []);

    const handleClose = () => {
        navigate("/dashboard/myPlans/0");
        setPaymentStatus(false)
    }

    return (
        <div>
            <Box className="page-title-row pb-2 sticky-page-title">
                <Grid container alignItems={'center'} textAlign={'left'} rowSpacing={2} columnSpacing={{ xs: 2, md: 0 }}>
                    <Grid item xs={12} md={'auto'} mr={'auto'} sm padding={0} className="page-title-box">
                        <Typography className="page-title mt-0 mb-0" sx={(theme) => theme.typography.h5}>{localRole !== ROLE.OrgAdmin ? t('lbl_subscriptionPlans') : 'Subscription Plan List'}</Typography>
                    </Grid>
                    {localRole === ROLE.CadisAdmin && <Grid item xs={12} md={'auto'} padding={0}>
                        <Button id={ButtonActionAdd}
                            onClick={(e) => {
                                handleClickEvent(e, null)
                            }}
                            startIcon={<span className='cad-plus'></span>}
                            variant="contained" sx={{ my: .5 }}>
                            {t('lbl_create')}<Typography sx={{ font: 'inherit' }} display={{ xs: 'none', md: 'inline' }}>&nbsp;{t('lbl_newPlan')}</Typography>
                        </Button>
                    </Grid>}
                </Grid>
            </Box>
            <Box className="plan-list-box pt-2">
                <Grid container alignItems={'flex-start'} textAlign={'left'} rowSpacing={2} columnSpacing={2}>
                    {subscriptionPlans.map((plan: any, index) => (
                        <Grid key={index} item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <Card sx={{ width: '100%', border: 'solid 1px', borderColor: grey[50], position: 'relative' }} elevation={4} className={plan.plan_status === 0 ? 'plan-disabled' : ''}>
                                <CardContent>
                                    <Tooltip title={`${plan.name}`} placement="bottom-start">
                                        <Typography variant="h5" component="div" color={'grey.700'} fontWeight={600} className="plan-name text-truncate" sx={{ mb: 2 }}>
                                            {plan.name}
                                        </Typography>
                                    </Tooltip>
                                    <Grid container spacing={0} sx={{ mb: 2.7 }}>
                                        <Grid item xs display="flex" alignItems="end">
                                            <Box display="flex" flexDirection="column" sx={{ maxWidth: '100%' }}>
                                                <Typography variant="h4" component="div" color="secondary.main" className="plan-price" fontWeight={600}
                                                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flexShrink: 1, minWidth: 0 }}>
                                                    <Typography variant="h5" display="inline">$</Typography>
                                                    {getAccuratePrice(plan.one_month_price)}
                                                    <Typography variant="body2" display="inline">
                                                        / {t('lbl_month')}
                                                    </Typography>
                                                </Typography>
                                                <Divider sx={{ width: 'calc(100% + 6px)', my: 0.3 }} />
                                                <Typography
                                                    variant="body2"
                                                    color="primary.main"
                                                    fontWeight={400}
                                                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flexShrink: 1, minWidth: 0 }}
                                                >
                                                    {plan.max_user_bundle_count} {t('lbl_usersPerSubscription')}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs="auto" display="flex" alignItems="end">
                                            <Box display="flex" flexDirection="column" textAlign="end">
                                                {plan.is_data_unlimited ? (
                                                    <Typography variant="h5" component="div" color="primary.main" fontWeight={600}>
                                                        {"Unlimited"}
                                                    </Typography>
                                                ) : plan.data_limit !== null ? (
                                                    <Typography variant="h4" component="div" color="primary.main" fontWeight={600}>
                                                        {formateDecimalValues(parseFloat(plan.data_limit))}
                                                        <Typography variant="h6" display="inline">{t('GB')}</Typography>
                                                    </Typography>
                                                ) : null}
                                                <Typography variant="body2" color="primary.main" fontWeight={400}>
                                                    {t('lbl_data')}/{t('lbl_subscription')}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant="h5" component="div" color="secondary.main" fontWeight={600} className="plan-price text-truncate" sx={{ mb: 3 }}>
                                        ${getAccuratePrice(plan.total_price)} For {` ${plan.billing_cycle === "1" ? t('lbl_month') : `${plan.billing_cycle} ${t('lbl_months')}`}`}
                                    </Typography>
                                    <Box className="plan-features" sx={{ mb: 1.5 }}>
                                        <Typography gutterBottom>
                                            <span className='cad-tick-icon text-secondary'></span> Consultant & Assistant unlimited calls
                                        </Typography>
                                        <Typography gutterBottom>
                                            <span className='cad-tick-icon text-secondary'></span> Email support during business hours
                                        </Typography>
                                        <Typography gutterBottom>
                                            <span className='cad-tick-icon text-secondary'></span> 24/7 email & dedicated call support
                                        </Typography>
                                    </Box>
                                    <Box className="plan-notification-days">
                                        <Typography gutterBottom className="text-truncate">
                                            <span className='cad-notification-date text-grey-700'></span> {t('lbl_notificationBefore')} <strong>{plan.remind_before} {t('lbl_days')}</strong> {t('lbl_ofExpiry')}
                                        </Typography>
                                    </Box>
                                </CardContent>
                                <CardActions>
                                    {localRole !== ROLE.OrgAdmin ? <Button id={ButtonActionEdit}
                                        onClick={(e) => {
                                            handleClickEvent(e, plan)
                                        }} variant="contained" color="primary" >
                                        {plan.plan_status === 0 ? t('lbl_enablePlan') : t('lbl_editPlan')}</Button>
                                        : <Button id={ButtonActionSelectPlan}
                                            onClick={(e) => {
                                                handleSelectPlanClick(e, plan)
                                            }} variant="contained" >{t('lbl_buyNow')}</Button>
                                    }
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            {SubscriptionCreationMode !== FormViewMode ? <AddUpdateSubscriptionPlans
                isOpenAddUpdateSubscriptionPlans={addSubscriptionDrawer}
                setIsOpenAddUpdateSubscriptionPlans={setAddSubscriptionDrawer}
                currentSubscriptionPlans={currentSubscriptionPlans}
                getSubscriptionPlan={GetAllSubscriptionPlan}
                planDuration={planDuration}
                mode={SubscriptionCreationMode}
            /> : null}
            <OrgAdminAddSubscriptionPlan
                isOpenAddSelectedPlan={AddSelectedPlanDrawer}
                currentSubscriptionPlans={currentSubscriptionPlans}
                setAddSelectedPlanDrawer={setAddSelectedPlanDrawer}
                planDetails={planDetails}
            />
            <PaymentStatusDialog
                open={paymentStatus}
                onClose={handleClose}
                isPaymentSuccess={isPaymentSuccess}
            />
        </div>
    );
}
export default SubscriptionPlans;