import { Box, Container, Drawer, FormControl, FormControlLabel, Grid, IconButton, Stack, Switch, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { OrganizationCreateRoute, OrganizationUpdateRoute } from '../../Common/api.routes';
import { ActiveStatusID, FormAddMode, FormUpdateMode, InActiveStatusID, maxPhoneLength, minPhoneLength, numberAndDashRegex, phoneNumberLengthValidationMessage, phoneNumberStartEndHyphenValidationMessage } from '../../Common/helper';
import * as HelperFunction from '../../Common/helperFunction';
import { OrganizationValidationSchema } from '../../Common/validationSchemas';
import CadisButton from '../../Components/Input/CadisButton';
import CadisTextField from '../../Components/Input/CadisTextField';
import CadisLoader from '../../Components/Loading/CadisLoader';
import apis from '../../HttpConfig/Api';
import { IOrganization } from '../../Interfaces/IOrganization';

const AddUpdateOrganization = ({ isOpen, setIsOpen, getAllOrganizations, openAddOrnAdminForm, mode, currentOrgData }: {
  isOpen: boolean, setIsOpen: any,
  getAllOrganizations: any, mode: any, currentOrgData: IOrganization, openAddOrnAdminForm: (orgName: string, orgID: number) => void
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const user = HelperFunction.isAuthenticated();

  const { values, dirty, handleReset, handleSubmit, handleChange, touched, errors, setFieldValue, setFieldError, resetForm } = useFormik({
    initialValues: {
      org_id: currentOrgData.org_id,
      org_name: currentOrgData.org_name,
      org_email: currentOrgData.org_email,
      org_phone_m: currentOrgData.org_phone_m,
      org_phone_nm: currentOrgData.org_phone_nm,
      org_status: currentOrgData.org_status,
      org_is_deleted: currentOrgData.org_is_deleted
    },
    enableReinitialize: true,
    validationSchema: OrganizationValidationSchema,
    onSubmit: async (values: any, props: any) => {
      const arePhoneNumbersValid = ValidatePhoneNumbers(values.org_phone_m, values.org_phone_nm, "org_phone_m", "org_phone_nm");
      if (!arePhoneNumbersValid) {
        return;
      }
      setLoading(true);
      AddUpdateOrgApiCall();
    }
  })

  const ValidatePhoneNumbers = (phoneM: string, phoneNM: string, phoneMFieldName: string, phoneNMFieldName: string) => {
    let isPhoneNumberValid = true;
    const orgPhoneMFirstChar = phoneM.charAt(0);
    const orgPhoneMlastChar = phoneM.charAt(phoneM.length - 1);
    if (orgPhoneMFirstChar === "-" || orgPhoneMlastChar === "-") {
      setFieldError(phoneMFieldName, phoneNumberStartEndHyphenValidationMessage);
      isPhoneNumberValid = false;
    }

    const orgPhoneMTextWithoutHyphens = phoneM.replace(/-/g, "");
    if (orgPhoneMTextWithoutHyphens.length < minPhoneLength || orgPhoneMTextWithoutHyphens.length > maxPhoneLength) {
      setFieldError(phoneMFieldName, phoneNumberLengthValidationMessage);
      isPhoneNumberValid = false;
    }

    if (phoneNM && phoneNM.length > 0) {
      const orgPhoneNMFirstChar = phoneNM.charAt(0);
      const orgPhoneNMlastChar = phoneNM.charAt(phoneNM.length - 1);
      if (orgPhoneNMFirstChar === "-" || orgPhoneNMlastChar === "-") {
        setFieldError(phoneNMFieldName, phoneNumberStartEndHyphenValidationMessage);
        isPhoneNumberValid = false;
      }

      const orgPhoneNMTextWithoutHyphens = phoneNM.replace(/-/g, "");
      if (orgPhoneNMTextWithoutHyphens.length < minPhoneLength || orgPhoneNMTextWithoutHyphens.length > maxPhoneLength) {
        setFieldError(phoneNMFieldName, phoneNumberLengthValidationMessage);
        isPhoneNumberValid = false;
      }
    }
    return isPhoneNumberValid;
  }

  const AddUpdateOrgApiCall = async () => {
    let orgData = { ...values as any };
    if (mode === FormAddMode)
      orgData.org_status = InActiveStatusID
    else
      orgData.org_status = values.org_status ? ActiveStatusID : InActiveStatusID;
    orgData.updated_by = user.userId;
    orgData.created_by = user.userId;
    try {
      await apis.instance.post(`${mode === FormAddMode ? OrganizationCreateRoute : OrganizationUpdateRoute}`, orgData)
        .then((resp: any) => {
          if (resp.data.success) {
            getAllOrganizations();
            handleReset(values);
            setIsOpen(false);
            if (mode === FormAddMode) {
              openAddOrnAdminForm(resp.data.data.organization.org_name, resp.data.data.organization.org_id);
            };
            toast.success(`${t('lbl_org')} ${mode === 'add' ? t('lbl_created') : t('lbl_updated')} ${t('lbl_successfully')}`);
            setLoading(false);
          } else {
            toast.error(resp.data.error);
            console.log(resp.data.error);
            setLoading(false);
          }
        })
        .finally(() => setLoading(false));
    } catch (error: any) {
      setLoading(false);
      toast.error(t('responseMsg.msg_wrongEntry'));
      console.log(error.message);
    }
  }

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={() => { return null }} sx={{}}
    >
      <Box role="presentation">
        <Box sx={{ pt: 1.5, position: 'sticky', top: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }} >
          <Container sx={{ alignItems: 'center', display: 'flex' }} disableGutters>
            <Box sx={{ width: '40px', height: '40px', flexGrow: 1, display: { xs: 'flex', pr: 2 } }}>
              <IconButton onClick={() => {
                resetForm()
                setIsOpen(false)
              }} sx={{}} className='c-pointer'>
                <span className='cad-close'></span>
              </IconButton>
            </Box>
          </Container>
          <Box sx={{ mb: 5, mt: 2, display: 'flex' }}>
            <Typography className="mt-0 mb-0 slide-right-title" sx={(theme) => theme.typography.h5}>
              {mode === FormAddMode ? t('lbl_create') : t('lbl_update')} {t('lbl_org')}
            </Typography>
          </Box>
        </Box>
        <Grid container width={'100%'}>
          <form onSubmit={handleSubmit} onReset={handleReset} style={{ width: '100%' }}>
            <Stack spacing={2} direction={'column'} paddingX={'20px'}>
              <FormControl variant="outlined">
                <label className='form-label '>
                  {t('lbl_name')}
                  <span className='mandatory-star'></span>
                </label>
                <CadisTextField
                  autoComplete='false'
                  fullWidth
                  id="org_name"
                  name="org_name"
                  type="text"
                  value={values.org_name.trimStart()}
                  inputProps={{ maxLength: 100 }}
                  onChange={handleChange}
                  error={touched.org_name && Boolean(errors.org_name)}
                  helperText={touched.org_name && t(`${errors.org_name ? errors.org_name : ''}`)}
                />
              </FormControl>

              <FormControl variant="outlined">
                <label className='form-label '>
                  {t('lbl_email')}
                  <span className='mandatory-star'></span>
                </label>
                <CadisTextField
                  id="org_email"
                  name="org_email"
                  value={values.org_email}
                  onChange={handleChange}
                  error={touched.org_email && Boolean(errors.org_email)}
                  helperText={touched.org_email && t(`${errors.org_email ? errors.org_email : ''}`)}
                />
              </FormControl>

              <FormControl variant="outlined">
                <label className='form-label '>
                  {t('lbl_phone1')}
                  <span className='mandatory-star'></span>
                </label>
                <CadisTextField
                  id="org_phone_m"
                  name="org_phone_m"
                  value={values.org_phone_m}
                  onChange={async (e: any) => {
                    if (e.currentTarget.value.trim().length < 1) {
                      setFieldValue("org_phone_m", e.currentTarget.value)
                    }
                    else if (numberAndDashRegex.test(e.currentTarget.value)) {
                      setFieldValue("org_phone_m", e.currentTarget.value)
                    }
                  }}
                  error={touched.org_phone_m && Boolean(errors.org_phone_m)}
                  helperText={touched.org_phone_m && t(`${errors.org_phone_m ? errors.org_phone_m : ''}`)}
                />
              </FormControl>

              <FormControl variant="outlined">
                <label className='form-label '>
                  {t('lbl_phone2')}
                </label>
                <CadisTextField
                  id="org_phone_nm"
                  name="org_phone_nm"
                  value={values.org_phone_nm}
                  onChange={async (e: any) => {
                    if (e.currentTarget.value.trim().length < 1) {
                      setFieldValue("org_phone_nm", e.currentTarget.value)
                    }
                    else if (numberAndDashRegex.test(e.currentTarget.value)) {
                      setFieldValue("org_phone_nm", e.currentTarget.value)
                    }
                  }}
                  error={touched.org_phone_nm && Boolean(errors.org_phone_nm)}
                  helperText={touched.org_phone_nm && errors.org_phone_nm}
                />
              </FormControl>

              {mode !== 'add' ?
                <FormControl>
                  <label className='form-label '>
                    {t('lbl_permission')}
                  </label>
                  <Box className='access-permission-box' sx={{ px: 2, py: 1 }}>
                    < FormControlLabel control={
                      <Switch color='success' id="org_status" name="org_status" checked={Boolean(values.org_status)} onChange={(event: any, checked: boolean) => {
                        setFieldValue("org_status", checked)
                      }} />} label={`${values.org_status ? t('lbl_disable') : t('lbl_enable')}${t('lbl_OrgAccess')}`} />
                    {!values.org_status && <div className='helper-text'>{t('lbl_access')}</div>}
                  </Box>
                </FormControl> : null}
            </Stack>
            <Box sx={{ pt: 2, pb: 2, position: 'sticky', bottom: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }}>
              <Grid container spacing={0} alignItems="center"  >
                <Grid item xs="auto" >
                  <CadisButton type='submit' variant="contained" color='primary' disabled={mode === 'update' && !dirty || loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                    {mode === 'add' ? t('lbl_create') : t('lbl_update')}
                  </CadisButton>
                </Grid>
                <Grid item xs="auto">
                  <Box sx={{ pl: 1 }}>
                    <CadisButton type='reset' variant="contained" color='error' disabled={mode === 'update' && !dirty || loading}>
                      {mode === FormUpdateMode ? `${t('lbl_reset')}` : `${t('lbl_clear')}`}
                    </CadisButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Grid>
      </Box>
    </Drawer>
  )
}

export default AddUpdateOrganization