import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import CadisButton from "../../Components/Input/CadisButton"
import { useState } from "react";
import apis from "../../HttpConfig/Api";
import { toast } from "react-toastify";
import { ReactivateUser } from "../../Common/api.routes";
import { InActiveStatusID } from "../../Common/helper";
import CadisLoader from "../../Components/Loading/CadisLoader";
import { useTranslation } from "react-i18next";

const UserReactivate = ({
  openReactivateOrgAdminDialog, setOpenReactivateOrgAdminDialog, getAllOrgAdmins }:
  { openReactivateOrgAdminDialog: any, setOpenReactivateOrgAdminDialog: any, getAllOrgAdmins: any }
) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  // #region Handle Reactivate User
  const handleReactivateUser = async () => {
    const userData = {
      'userID': openReactivateOrgAdminDialog.UserID,
      'isUserDeleted': InActiveStatusID
    }
    try {
      await apis.instance.post(`${ReactivateUser}`, userData)
        .then((resp: any) => {
          if (resp.data.success) {
            handleCloseReactivateUser();
            getAllOrgAdmins();
            toast.success(t('responseMsg.msg_RestoredUser'));
          } else {
            if (resp.data.error.toLowerCase() === "organization has exceeded the maximum limit for user creation.")
              toast.error(t('responseMsg.msg_unhideUser'));
            else if (resp.data.error.toLowerCase() === 'you cannot reactivate user as no subscription plan is active for this organization')
              toast.error(t('responseMsg.msg_noActiveSubscriptiontoUnhideUser'));
            else
              toast.error(resp.data.error);
            handleCloseReactivateUser();
          }
        })
        .finally(() => setLoading(false));
    } catch (error: any) {
      setLoading(false);
      toast.error(t('responseMsg.msg_wrongEntry'));
      console.log(error.message);
    }
  }

  const handleCloseReactivateUser = () => {
    setOpenReactivateOrgAdminDialog({
      'open': false,
      'UserID': null,
      'UserName': ""
    });
  }
  //#endregion

  return (
    <>
      <Dialog
        open={openReactivateOrgAdminDialog.open}
        onClose={() => { return null }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('lbl_confirmation')}
        </DialogTitle>
        <DialogContent>
          {t('lbl_reactivate')} {openReactivateOrgAdminDialog.UserName}?
        </DialogContent>
        <DialogActions>
          <CadisButton onClick={handleReactivateUser} variant="contained" color='success' disabled={loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
            {t('lbl_yes')}
          </CadisButton>
          <CadisButton onClick={handleCloseReactivateUser} variant="contained" color='error' disabled={loading}>
            {t('lbl_no')}
          </CadisButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default UserReactivate