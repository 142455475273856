import { Backdrop, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { isAuthenticated } from "../Common/helperFunction";
import apis from "../HttpConfig/Api";
import { LogoutUserRoute, UserSessionExpireRoute, UserSessionStatusRoute } from "../Common/api.routes";
import { sessionTimeoutInMins, sessionWarningTimeInMins } from "../Common/helper";
import CadisButton from "../Components/Input/CadisButton";
import { useLocation } from "react-router-dom";
import { useSocketContext } from "./SocketContext";
import { useTranslation } from "react-i18next";



export interface IUserRoleType {
  userLoginRole: number | null | any,
  setUserLoginRole: Dispatch<SetStateAction<number | null | any>>;
  setCurrentNavItem: Dispatch<SetStateAction<number | null | any>>;
  currentNavItem: any,
  handleToggleLoader: Dispatch<SetStateAction<number | null | any>>;
  setSessionState: Dispatch<SetStateAction<number | null | any>>;
}

const defaultValue = {
  userLoginRole: null,
  setUserLoginRole: () => { },
  setCurrentNavItem: () => { },
  currentNavItem: null,
  handleToggleLoader: () => { },
  setSessionState: () => { }
} as IUserRoleType

export const UserRoleContext = createContext(defaultValue);

type UserRoleContextProviderProps = {
  children: ReactNode;
};

export const useUserRoleContext = () => {
  return useContext(UserRoleContext);
}

const UserRoleContextProvider = ({ children }: UserRoleContextProviderProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const user = isAuthenticated();
  const [userLoginRole, setUserLoginRole] = useState();
  const [currentNavItem, setCurrentNavItem] = useState();
  const [showModalTokenExpiry, setShowModalTokenExpiry] = useState(false);
  const navigate = useNavigate();
  const [showFullScreenLoader, setFullScreenLoader] = useState(false);
  const [sessionTimeoutLatestWarningTime, setSessionTimeoutLatestWarningTime] = useState<Number>(Number(sessionWarningTimeInMins));
  const [showSessionTimeoutWarningDialog, setShowSessionTimeoutWarningDialog] = useState(false);
  const [showSessionTimeoutDialog, setShowSessionTimeoutDialog] = useState(false);
  const [sessionState, setSessionState] = useState("inactive");
  const { pingIntervalRef, callerToneRef, ringtoneRef, handleCloseSocket } =
    useSocketContext(); // socket context customeHook
  const handleToggleLoader = (setActive: boolean) => {
    setFullScreenLoader(setActive);
  };

  const callScreenPath = "/call";

  const InitiateSessions = (isSessionContinued: boolean) => {
    ClearExistingSessions();
    if (isSessionContinued && showSessionTimeoutWarningDialog) {
      setShowSessionTimeoutWarningDialog(false);
    }
    InitiateSessionTimeoutWarning();
    InitiateSessionTimeout();
  };

  const InitiateSessionTimeoutWarning = () => {
    const currentActivePath = location.pathname;
    if (currentActivePath !== callScreenPath) {//when not in call then activate the session
      const calculatedTime = Number(sessionTimeoutInMins) - Number(sessionWarningTimeInMins);
      const currentSessionWarningTimerID = setTimeout(() => {
        setShowSessionTimeoutWarningDialog(true);
      }, calculatedTime * 60000);
      const previousWarningTimerID = localStorage.getItem('sessionTimeoutWarningTimerID') as any;
      clearTimeout(previousWarningTimerID);
      localStorage.setItem('sessionTimeoutWarningTimerID', currentSessionWarningTimerID.toString());
    } else {
      const previousWarningTimerID = localStorage.getItem('sessionTimeoutWarningTimerID') as any;
      clearTimeout(previousWarningTimerID);
    }
  }

  const InitiateSessionTimeout = () => {
    const currentActivePath = location.pathname;
    if (currentActivePath !== callScreenPath) {//when not in call then activate the session
      let currentSessionTimerID = setTimeout(() => {
        ClearExistingSessions();
        setShowSessionTimeoutWarningDialog(false);
        setShowSessionTimeoutDialog(true);
        ExpireUserSession();
      }, Number(sessionTimeoutInMins) * 60000);
      const previousTimerID = localStorage.getItem('sessionTimeoutTimerID') as any;
      clearTimeout(previousTimerID);
      localStorage.setItem('sessionTimeoutTimerID', currentSessionTimerID.toString());
    } else {
      const previousTimerID = localStorage.getItem('sessionTimeoutTimerID') as any;
      clearTimeout(previousTimerID);
    }
  }

  const LogoutUser = async () => {
    setShowSessionTimeoutDialog(false);
    const params = {
      user_id: user.userId,
      isUserLoggedIn: 0
    }
    await apis.instance
      .post(`${LogoutUserRoute}`, params)
      .then((resp: any) => {
        if (resp.data.success) {
          clearInterval(pingIntervalRef.current);
          pingIntervalRef.current = null;
          handleCloseSocket();
          callerToneRef.current?.pause();
          ringtoneRef.current?.pause();
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("username");
          sessionStorage.removeItem("role");
          localStorage.removeItem("currentNavNode");
          localStorage.removeItem("autoLogOffTimerID");
          navigate("/");
        } else toast.error(t('responseMsg.msg_wrongEntry'));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err);
      });
  };

  const ExpireUserSession = async () => {
    const params = {
      user_id: user.userId,
      isSessionExpired: true
    }
    await apis.instance
      .post(`${UserSessionExpireRoute}`, params)
      .then((resp: any) => {
        if (!resp.data.success) {
          toast.error(t('responseMsg.msg_wrongEntry'));
        } else {
          clearInterval(pingIntervalRef.current);
          pingIntervalRef.current = null;
          handleCloseSocket();
          callerToneRef.current?.pause();
          ringtoneRef.current?.pause();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err);
      });
  }

  const CheckUserSession = async () => {
    const params = {
      user_id: user.userId
    }
    await apis.instance
      .get(`${UserSessionStatusRoute}`, { params })
      .then((resp: any) => {
        if (resp.data.success && resp.data.data[0].is_session_expired) {
          ClearExistingSessions();
          setShowSessionTimeoutWarningDialog(false);
          setShowSessionTimeoutDialog(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          ClearExistingSessions();
          clearInterval(pingIntervalRef.current);
          pingIntervalRef.current = null;
          handleCloseSocket();
          callerToneRef.current?.pause();
          ringtoneRef.current?.pause();
          navigate("/");
          sessionStorage.removeItem("token");
          if (user.userId != 0)
            toast.warning(t('responseMsg.msg_sessionExpired'));
        }
      }
      );
  }

  
  const ClearExistingSessions = () => {
    const previousWarningTimerID = localStorage.getItem('sessionTimeoutWarningTimerID') as any;
    clearTimeout(previousWarningTimerID);

    const previousTimerID = localStorage.getItem('sessionTimeoutTimerID') as any;
    clearTimeout(previousTimerID);
  }

  apis.instance.interceptors.request.use((req: any) => {
    req.headers['Authorization'] = sessionStorage.getItem('token');
    return req;
  });

  useEffect(() => {
    //don't start sessions if the user is at login page, login otp page, and initial page
    if (location.pathname !== '/login' && location.pathname !== '/LoginOtp' && location.pathname !== '/' && !location.pathname.includes('/setNewPassword/') && !location.pathname.includes('/resetPassword/')
      && !location.pathname.includes('/webAssistantJoin/') && !location.pathname.includes('/webassistantcall')) {
      const intercept = apis.instance.interceptors.request.use((req: any) => {

        req.headers['Authorization'] = sessionStorage.getItem('token');
        //if user is trying to logout clear existing sessions
        const logoutUrl = "/user/logoutUser";
        if (req.url == logoutUrl) {
          ClearExistingSessions();
        } else {
          if (!showSessionTimeoutDialog) {
            InitiateSessions(false);
          }
        }
        return req;
      }, error => {
        return error;
      });
      CheckUserSession();
    }

    return () => {
      //apis.instance.interceptors.request.eject(intercept);
    };
  }, [sessionState]);

  // if new session started old session nevigate to login 
  useEffect(() => {
    let isSessionExpiredToastShown = false;

    const interceptor = apis.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        let requestUrl = error.response.request.responseURL;
        if (error.response.status === 401) {
          ClearExistingSessions();
          setShowSessionTimeoutWarningDialog(false);
          setShowSessionTimeoutDialog(false);
          clearInterval(pingIntervalRef.current);
          pingIntervalRef.current = null;
          handleCloseSocket();
          callerToneRef.current?.pause();
          ringtoneRef.current?.pause();
          sessionStorage.removeItem("role");
          sessionStorage.removeItem("token");
          navigate("/");
          sessionStorage.removeItem("username");
          localStorage.removeItem("currentNavNode");
          if (!requestUrl.includes("getSpecialityList") && !isSessionExpiredToastShown) {
            toast.warning(t("responseMsg.msg_sessionExpired"));
            isSessionExpiredToastShown = true;
          }
        }
        return Promise.reject();
      }
    );
    return () => {
      apis.instance.interceptors.response.eject(interceptor);
    };
  }, [history]);

  useEffect(() => {
    if (showSessionTimeoutDialog) {
      ClearExistingSessions();
    }
  }, [showSessionTimeoutDialog]);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 2, backgroundColor: 'rgba(255,255,255,0.3)', width: { sm: 'calc(100vw - 240px)' }, marginLeft: { xs: '0', sm: '240px' }, height: { xs: 'calc(100vh - 60px)', sm: 'calc(100vh - 64px)' }, marginTop: { xs: '60px', sm: '64px' } }}
        open={showFullScreenLoader}
        onClick={() => { return null }}
      >
        <CircularProgress color="primary" />
      </Backdrop>

      {/* session timeout WARNING */}
      {location.pathname !== '/login' && (
        <Dialog
          open={showSessionTimeoutWarningDialog}
          onClose={() => { return null }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title" color="success.main">
            Warning
          </DialogTitle>
          <DialogContent>
            {
              `Your session is about to expire in the next ${sessionTimeoutLatestWarningTime} mins.
            Click on 'Continue' button to remain logged in.`
            }
          </DialogContent>
          <DialogActions>
            <CadisButton variant="contained" color='primary' onClick={() => InitiateSessions(true)}>
              Continue
            </CadisButton>
          </DialogActions>
        </Dialog>
      )}

      {/* session TIMEOUT */}
      <Dialog
        open={showSessionTimeoutDialog}
        onClose={() => { return null }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" color="success.main">
          {t('lbl_sesstionTimeout')}
        </DialogTitle>
        <DialogContent>
          {(t('responseMsg.msg_sessionTimeout'))}
        </DialogContent>
        <DialogActions>
          <CadisButton variant="contained" color='primary' onClick={LogoutUser}>
            {t('lbl_goToLogin')}
          </CadisButton>
        </DialogActions>
      </Dialog>
      <UserRoleContext.Provider
        value={{
          userLoginRole,
          setUserLoginRole,
          currentNavItem,
          setCurrentNavItem,
          handleToggleLoader,
          setSessionState
        }}
      >
        {children}
      </UserRoleContext.Provider>
    </>
  );
}

export { UserRoleContextProvider }