import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

let feedbackTimeout: any ;

const FeedbackReplay = () => {
  const navigate = useNavigate();

  useEffect(()=>{
    feedbackTimeout = setTimeout(()=>{
      navigate('/dashboard/assistants');
    },2000);
    return () => {
      clearTimeout(feedbackTimeout);
      feedbackTimeout = null;
    }
  },[]);
  
  return (
    <Box height='100vh' sx={{backgroundColor:'#2A2A2A'}} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        <Typography fontSize='2rem' color='#ffffff' fontWeight='bold'>Thanks for the feedback! </Typography>
        <Typography color='#ffffff'>we'll keep moving on improving your experience. </Typography>
    </Box>
  )
}

export default FeedbackReplay;