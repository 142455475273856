import { Box, Grid, Select, MenuItem, SelectChangeEvent, ListItem, ListItemText, Avatar, Tooltip, Typography, Button, styled, AvatarGroup, AvatarProps } from '@mui/material';
import { useEffect, useRef, useState } from 'react'
import { stringAvatar, GetNonDeletedUser, GetShortDateWithLuxon, GetTimeWithAMPMWithLuxon, isAuthenticated, GetNonDeletedUserCallsession, formateMbtoGb, } from '../../Common/helperFunction';
import { callSessionsDataExportFileName, callStatusIncoming, callStatusOutgoing, dummyCallSessionID, DefaultGridRowSize, GridRowsPerPageOptions, navigateToAssistant, navigateToCallSessions, navigateToConsultants, NoCallSessionsMessageAssistantGrid, NoCallSessionsMessageConsultantGrid, callStatusMissed } from '../../Common/helper';
import { auto } from '@popperjs/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { AllUserListByRoleRoute, GetOrganizationsCallHistoryRoute, GetUserCallSessionsRoute } from '../../Common/api.routes';
import apis from '../../HttpConfig/Api';
import { toast } from 'react-toastify';
import { ICallSessions } from '../../Interfaces/ICallSessions';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { GridStyling } from '../../Common/sharedStyling';
import { io } from 'socket.io-client';
import { useSocketContext } from '../../context/SocketContext';
import { ROLE } from '../../Interfaces/IConstants';
import React from 'react';
import { useUserRoleContext } from '../../context/UserRoleContext';
import { CSVLink } from "react-csv";

const CallSessions = ({ username, fullname, showAssistantsCallSessions, userRoleID }: any) => {
    const { state } = useLocation();
    const user = isAuthenticated();
    const navigate = useNavigate();
    const { userLoginRole, handleToggleLoader } = useUserRoleContext();

    const { socketRef, openIncomingCallBox, setUserDetails, openReconnectDialog } = useSocketContext();
    const [pageSize, setPageSize] = useState<number>(DefaultGridRowSize);
    const [callSessionsData, setCallSessionData] = useState<ICallSessions[]>([]);
    const [assistants, setAssistants] = useState<any[]>([]);
    const [assistant, setAssistant] = useState<any>("");
    const [assistantName, setAssistantName] = useState<any>("");
    const [consultants, setConsultants] = useState<any[]>([]);
    const [consultant, setConsultant] = useState<any>("");
    const [consultantName, setConsultantName] = useState<any>("");
    const [exportData, setExportData] = useState<any[]>([]);
    let exportDataRef = useRef<any>();
    const [columnHeaders, setColumnHeaders] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [localRole, setLocalRole] = useState(userLoginRole);

    useEffect(() => {
        const temp: any = Number(sessionStorage.getItem("role"));
        if (temp) {
            setLocalRole(temp);
        } else {
            setLocalRole(userLoginRole);
        }
    }, [userLoginRole])

    const [sort, setSort] = useState(
        [{
            field: 'date',
            sortOrder: 'desc'
        },
        {
            field: 'duration',
            sortOrder: 'asc'
        }]
    );

    // const StyledAvatarLeft = styled(Avatar)<AvatarProps>(({ theme, color }) =>
    // ({
    //     '&.MuiAvatar-root': {
    //         borderRadius: "40px 0 0 40px !important",
    //         width: "20px !important",
    //         border: "1px solid #b5c0eb",
    //         boxShadow: "0px 0px 1px 1px #fff",
    //         fontSize: "12px",
    //         // 'img': {
    //         //     height: '100%'
    //         // }
    //     }
    // }));

    // const StyledAvatarRight = styled(Avatar)<AvatarProps>(({ theme, color }) =>
    // ({
    //     '&.MuiAvatar-root': {
    //         borderRadius: "0 40px 40px 0 !important",
    //         width: "20px !important",
    //         marginLeft: "1px",
    //         border: "1px solid #b5c0eb",
    //         boxShadow: "0px 0px 1px 1px #fff",
    //         fontSize: "12px",
    //     }
    // }));

    const tempImg = (item1: any, item2: any, item3: any) => {
        return (
            <Box display='flex' alignItems='center'>
                <Tooltip title={item1} >
                    <Box maxWidth={(item1 && item2 && item3) ? 'calc(32% - 27px)' : 'calc(50% - 27px)'} overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>{item1}</Box>
                </Tooltip>
                <Box className='mx-1 ico-circle-secondary cad-statoscope' sx={{ lineHeight: '1' }}></Box>&nbsp;
                {item2 !== '' && <>
                    <Tooltip title={item2} >
                        <Box maxWidth={(item1 && item2 && item3) ? 'calc(32% - 27px)' : 'calc(50% - 27px)'} overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>{item2}</Box>
                    </Tooltip>
                    <Box className='mx-1 ico-circle-secondary cad-assistant' sx={{ lineHeight: '1' }}></Box>&nbsp;
                </>
                }
                {item3 !== null && <>
                    <Tooltip title={item3} >
                        <Box maxWidth={(item1 && item2 && item3) ? 'calc(32% - 27px)' : 'calc(50% - 27px)'} overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>{item3}</Box>
                    </Tooltip>
                    <Box className='mx-1 ico-circle-secondary cad-web-device-user' sx={{ lineHeight: '1' }}></Box>
                </>
                }
            </Box>
        )
    }

    const columns: GridColDef[] = [
        {
            field: "session_id", headerName: "ID",
            width: 1, hide: true, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header'
        },
        {
            field: 'assistant', headerName: 'Participants', flex: 1,
            minWidth: 400, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header',
            sortable: false, renderCell: ({ row }: any) => {
                return <ListItem disablePadding>
                    <Avatar sx={{ mr: 2, bgcolor: row.is_conference_call === 1 ? 'primary.light' : 'success.main' }}>
                        {
                            row.is_conference_call === 1 ? <span className='cad-user-group'></span> : <span className='cad-phone-call'></span>
                        }
                    </Avatar>
                    <ListItemText sx={{ textTransform: 'capitalize', }}
                        primary={<React.Fragment>
                            {tempImg(row.consultant_user_title === "None" ? `${row.consultant_fname}`
                                : `${row.consultant_user_title} ${row.consultant_fname}`,
                                row.call_assistant_id !== dummyCallSessionID ? row.assistant_fname : '', row.web_ast_name)}
                        </React.Fragment>}
                        secondary={<React.Fragment>
                            {
                                row.created_by ?
                                    <Typography variant='inherit' color={bindCallStatus(row.created_by, row.call_assistant_id, row.call_consultant_id, row.call_status).callStatus === 'Missed' ? 'error' : 'grey'} >
                                        <span className={bindCallStatus(row.created_by, row.call_assistant_id, row.call_consultant_id, row.call_status).callStatusClass}>
                                        </span>&nbsp;
                                        {bindCallStatus(row.created_by, row.call_assistant_id, row.call_consultant_id, row.call_status).callStatus}
                                    </Typography>
                                    : null
                            }
                        </React.Fragment>}
                    />
                    {/* </Tooltip> */}
                </ListItem>
            }
        },
        {
            field: "date", headerName: "Date & Time", sortable: false,
            flex: 1, minWidth: 140, cellClassName: 'app-theme-cell',
            headerClassName: sort.find((x: any) => x.field === "date")?.sortOrder === "asc" ? 'app-theme-header cad-arrow-up' : 'app-theme-header cad-arrow-down',
            renderCell: ({ row }: any) => {
                const { call_start_time, call_end_time } = row;
                return <ListItem disablePadding>
                    <ListItemText sx={{ textTransform: 'capitalize' }}
                        primary={`${GetShortDateWithLuxon(call_start_time) ?? null}`}
                        secondary={
                            call_start_time && call_end_time ? `${GetTimeWithAMPMWithLuxon(call_start_time)} - ${GetTimeWithAMPMWithLuxon(call_end_time)}`
                                : null
                        }
                    />
                </ListItem>
            }
        },
        {
            field: "duration", headerName: "Duration", sortable: false,
            flex: 1, minWidth: 100, cellClassName: 'app-theme-cell',
            headerClassName: sort.find((x: any) => x.field === "duration")?.sortOrder === "asc" ? 'app-theme-header cad-arrow-duration cad-arrow-up' : 'app-theme-header cad-arrow-duration cad-arrow-down',
        },
        {
            field: "data_consumption", headerName: "Data Used", sortable: false,
            renderCell: ({ row }) => (row.data_consumption !== null && row.data_consumption != 0) ? formateMbtoGb(row.data_consumption) : "",
            flex: 1, minWidth: 100, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header'
        },
        {
            field: "call_quality", flex: 1, headerName: "Call Quality", sortable: false,
            minWidth: 110, hide: false, cellClassName: 'app-theme-cell',
            headerClassName: 'app-theme-header',
            renderCell: ({ row }: any) => {
                const { call_quality } = row;
                const data = Array.from({ length: 5 }, () => '');

                return data.map((_, index: any) => {
                    const className = `cad-star-${index < call_quality ? 'fill' : 'outline'}`;
                    return (
                        <Typography className={className} key={`star-${index}`} color="warning.main">
                        </Typography>
                    )
                })
            }
        },
    ];

    const GetOrganizationsCallSessionsDetails = async () => {
        handleToggleLoader(true);
        await apis.instance.get(`${GetOrganizationsCallHistoryRoute}`)
            .then((resp: any) => {
                handleToggleLoader(false);
                setLoading(false);
                if (resp.data?.success) {
                    if (resp.data?.data.length < 1) {
                        setCallSessionData([]);
                        return toast.error("No details found")
                    }
                    const sortedDataByDateUsingId = resp.data?.data ? resp.data.data.data.sort(function (initial: any, next: any) {
                        return (+new Date(next.call_end_time)) - (+new Date(initial.call_start_time))
                    }) : [];
                    const formattedCallSessions = sortedDataByDateUsingId.filter((callSession: any) => {
                        return callSession.is_conference_call === 1 ? callSession.call_assistant_id !== dummyCallSessionID || callSession.web_ast_name !== null : callSession.call_assistant_id !== dummyCallSessionID &&
                            callSession.call_consultant_id !== dummyCallSessionID
                    })
                    setCallSessionData(formattedCallSessions);
                    bindDataExportData(formattedCallSessions);
                }
                else {
                    setCallSessionData([]);
                    if (resp.data.error.toLowerCase() === "no details found!") {
                        return toast.error("No details found");
                    }
                    toast.error(resp.data.error)
                }
            })
            .catch(err => {
                handleToggleLoader(false);
                setLoading(false);
                console.log(err)
                if (err.error.toLowerCase() === "no details found!") {
                    return toast.error("No details found");
                }
                else if (err?.response.status !== 401) {
                    toast.error(err.response?.data?.message)
                }
            })
    }
    
    const GetAssistantsList = async () => {
        handleToggleLoader(true);
        const params = {
            'role': ROLE.Assistant,
            'orgID': user.orgId
        }
        await apis.instance.get(`${AllUserListByRoleRoute}`, { params })
            .then((resp: any) => {
                handleToggleLoader(false);
                if (resp.data?.success) {
                    const nonDeletedAssistants = GetNonDeletedUser(resp.data?.data, user.userId);
                    setAssistants(nonDeletedAssistants);
                    if (state?.showAssistantsCallSessions) {
                        setAssistant(state && state.username);
                        setAssistantName(state && state.fullname);
                    } else {
                        setAssistant(nonDeletedAssistants[0].user_name);
                        setAssistantName(`${nonDeletedAssistants[0].user_fname} ${nonDeletedAssistants[0].user_lname ?? ''}`);
                    }
                    if (state?.showAssistantsCallSessions) {
                        GetAssistantsCallSessionDetails(state.username);
                    } else {
                        GetOrganizationsCallSessionsDetails();
                    }
                }
            })
            .catch(err => {
                handleToggleLoader(false);
                if (err?.response.status !== 401) toast.error(err.response?.data?.message)
                console.log(err)
            })
    }

    const GetConsultantsList = async () => {
        handleToggleLoader(true);
        const params = {
            'role': ROLE.Consultant,
            'orgID': user.orgId
        }
        await apis.instance.get(`${AllUserListByRoleRoute}`, { params })
            .then((resp: any) => {
                handleToggleLoader(false);
                if (resp.data?.success) {
                    const nonDeletedConsultants = GetNonDeletedUser(resp.data?.data, user.userId);
                    const nonDeletedConsultantsCallsession = GetNonDeletedUserCallsession(resp.data?.data, user.userId);
                    setConsultants(nonDeletedConsultants);
                    if (state?.showAssistantsCallSessions) {
                        setConsultant(state && state.username);
                        setConsultantName(state && state.fullname);
                    } else {
                        setConsultant(nonDeletedConsultantsCallsession[0].user_name);
                        setConsultantName(`${nonDeletedConsultantsCallsession[0].user_fname} ${nonDeletedConsultantsCallsession[0].user_lname ?? ''}`);
                    }
                    const localStorageNavItem = localStorage.getItem("currentNavNode");
                    if (localStorageNavItem !== navigateToCallSessions && state !== null) {
                        GetAssistantsCallSessionDetails(state?.username ?? user.username);
                    } else {
                        const localRole: any = Number(sessionStorage.getItem("role"));
                        if (localRole === ROLE.OrgAdmin) {
                            GetOrganizationsCallSessionsDetails();
                        }
                        if (localRole === ROLE.Consultant) {
                            GetAssistantsCallSessionDetails(state?.username ?? user.username);
                        }
                    }
                }
            })
            .catch(err => {
                handleToggleLoader(false);
                if (err?.response.status !== 401) toast.error(err.response?.data?.message)
                console.log(err)
            })
    }

    const GetAssistantsCallSessionDetails = async (assistantOrConsultantUsername: string) => {
        handleToggleLoader(true);
        const params = {
            'user_name': assistantOrConsultantUsername
        }
        if (state?.userRoleID === ROLE.Assistant) {
            setAssistant(assistantOrConsultantUsername);
            if (assistants.length > 0) {
                const selectedAssistant = assistants.find((assistant) => assistant.user_name === assistantOrConsultantUsername);
                setAssistantName(`${selectedAssistant.user_fname} ${selectedAssistant.user_lname ?? ''}`);
            }
        } else {
            setConsultant(assistantOrConsultantUsername);
            if (consultants.length > 0) {
                const selectedConsultant = consultants.find((consultant) => consultant.user_name === assistantOrConsultantUsername);
                setConsultantName(`${selectedConsultant.user_fname ?? ''} ${selectedConsultant.user_lname ?? ''}`);
            }
        }

        await apis.instance.get(`${GetUserCallSessionsRoute}`, { params })
            .then((resp: any) => {
                handleToggleLoader(false);
                setLoading(false);
                if (resp.data?.success) {
                    if (resp.data?.data.length < 1) {
                        setCallSessionData([]);
                        return toast.error("No details found")
                    }
                    const sortedDataByDateUsingId = resp.data?.data ? resp.data.data.sort(function (initial: any, next: any) {
                        return (+new Date(next.call_end_time)) - (+new Date(initial.call_start_time))
                    }) : [];
                    const OrgsortedDataByDateUsingId = resp.data?.data
                        ? resp.data.data
                            .filter((record: any) => record.call_status !== 4) // Filter out records with call_status === 4
                            .sort((initial: any, next: any) => {
                                return (+new Date(next.call_end_time)) - (+new Date(initial.call_start_time));
                            }) : [];
                    let formattedCallSessions: any;
                    const localRole: any = Number(sessionStorage.getItem("role"));
                    if (localRole === ROLE.Consultant) {
                        formattedCallSessions = sortedDataByDateUsingId.filter((callSession: any) => {
                            return callSession.is_conference_call === 1 ? callSession.call_consultant_id === user.userId && (callSession.call_assistant_id !== dummyCallSessionID || callSession.web_ast_name !== null) : callSession.call_consultant_id === user.userId && callSession.call_assistant_id !== dummyCallSessionID &&
                                callSession.call_consultant_id !== dummyCallSessionID
                        })
                    } else {
                        formattedCallSessions = OrgsortedDataByDateUsingId.filter((callSession: any) => {
                            return callSession.is_conference_call === 1 ? callSession.call_assistant_id !== dummyCallSessionID || callSession.web_ast_name !== null : callSession.call_assistant_id !== dummyCallSessionID &&
                                callSession.call_consultant_id !== dummyCallSessionID
                        })
                    }
                    setCallSessionData(formattedCallSessions);
                    bindDataExportData(formattedCallSessions);
                }
                else {
                    setCallSessionData([]);
                    if (resp.data.error.toLowerCase() === "no details found!") {
                        return toast.error("No details found");
                    }
                    toast.error(resp.data.error)
                }
            })
            .catch(err => {
                handleToggleLoader(false);
                setLoading(false);
                console.log(err)
                if (err.error.toLowerCase() === "no details found!") {
                    return toast.error("No details found");
                }
                toast.error(err.response.data.message);
            })
    }

    const onColumnHeaderClick = (currentHeaderObj: any) => {
        const fieldName = currentHeaderObj.field;
        const headerClassName = currentHeaderObj.colDef.headerClassName;
        const ascClassName = 'app-theme-header cad-arrow-up';
        const ascClassNameDuration = 'app-theme-header cad-arrow-duration cad-arrow-up';
        const replicatedCallSessionData = [...callSessionsData] as any;
        const duplicatedSortOrderObj = [...sort];

        if (fieldName === "date") {
            duplicatedSortOrderObj[0].sortOrder = headerClassName === ascClassName ? 'desc' : 'asc';
            setSort(duplicatedSortOrderObj)
            if (replicatedCallSessionData.length > 0) {
                //ACTUAL SORT
                const sortedDataByDateUsingDateDiffDesc = replicatedCallSessionData.sort(function (initial: any, next: any) {
                    return (+new Date(next.call_end_time)) - (+new Date(initial.call_start_time))
                });
                setCallSessionData(
                    headerClassName === ascClassName
                        ? sortedDataByDateUsingDateDiffDesc
                        : sortedDataByDateUsingDateDiffDesc.reverse()
                );
            }
        } else if (fieldName === "duration") {
            const callSessionWithEpocDuration = replicatedCallSessionData.map((x: any) => {
                x['durationEpoc'] = new Date(x.call_end_time).getTime() - new Date(x.call_start_time).getTime()
                return x;
            });
            duplicatedSortOrderObj[1].sortOrder = headerClassName === ascClassNameDuration ? 'desc' : 'asc';
            setSort(duplicatedSortOrderObj)

            if (replicatedCallSessionData.length > 0) {
                //ACTUAL SORT
                let sortedDataByDateUsingDurationEpocDiffDesc = callSessionWithEpocDuration.sort(function (initial: any, next: any) {
                    return next.durationEpoc - initial.durationEpoc
                });
                sortedDataByDateUsingDurationEpocDiffDesc = sortedDataByDateUsingDurationEpocDiffDesc.map((x: any) => {
                    delete x['durationEpoc']
                    return x;
                })
                setCallSessionData(
                    headerClassName === ascClassNameDuration
                        ? sortedDataByDateUsingDurationEpocDiffDesc
                        : sortedDataByDateUsingDurationEpocDiffDesc.reverse()
                );
            }
        }
    }

    const onBackClick = () => {
        const isAssistant = state?.userRoleID === ROLE.Assistant;
        navigate(isAssistant ? navigateToAssistant : navigateToConsultants);
    }

    const bindCallStatus: any = (created_by: number, assistantUserID: number, consultantUserID: number, call_status: number) => {
        const userID = state?.userRoleID === ROLE.Assistant ? assistantUserID : consultantUserID;
        const isMissedCall = call_status === 4;
        const isShowAssistantsCallSessions = state?.showAssistantsCallSessions;
        return isMissedCall
            ? {
                callStatus: (isShowAssistantsCallSessions ? assistantUserID === created_by : consultantUserID === created_by) ? callStatusOutgoing : callStatusMissed,
                callStatusClass: (isShowAssistantsCallSessions ? assistantUserID === created_by : consultantUserID === created_by)
                    ? 'cad-phone-outgoing' : 'cad-phone-missed'
            } : {
                callStatus: (isShowAssistantsCallSessions ? userID === created_by : user.userId === created_by)
                    ? callStatusOutgoing
                    : callStatusIncoming,
                callStatusClass: (isShowAssistantsCallSessions ? userID === created_by : user.userId === created_by)
                    ? 'cad-phone-outgoing'
                    : 'cad-phone-incoming'
            };
    };

    const exportToCSV = () => {
        if (callSessionsData.length < 1) return toast.error("No details found to export.");
        const exp: any = exportDataRef;
        exp.link.click();
    }

    const bindDataExportData = (dataToExport: any[]) => {
        const dataToDownload = dataToExport.map((record: any, index: number) => {
            const recordToDownload: any = {};
            columns.forEach((col: any) => {
                let actualData = record[col.field];
                let header = col.headerName;
                if (header === "Participants") actualData = `${record.assistant_fname}, ${record.consultant_fname}`;
                else if (header === "Date & Time") actualData = `${GetShortDateWithLuxon(record.call_start_time)}, ${GetTimeWithAMPMWithLuxon(record.call_start_time)}`;
                else if (header === "ID") {
                    header = "Sr.No";
                    actualData = `${index + 1}`;
                } if (header === "Data Used") actualData = formateMbtoGb(record.data_consumption)
                recordToDownload[header] = actualData;
            });
            return recordToDownload;
        });

        const localRole = Number(sessionStorage.getItem("role"));
        const columnHeaders = columns.filter((i) => {
            if (localRole !== ROLE.OrgAdmin) {
                return i.headerName !== "Data Used"
            } else {
                return i
            }
        }).map((col) => {
            return col.headerName === "ID" ? "Sr.No" : col.headerName;
        });
        setColumnHeaders(columnHeaders);

        const keyToRemove = 'Data Used';
        const updatedArrayByRemovingDataUsed = dataToDownload.map(obj => {
            const { [keyToRemove]: _, ...newObj } = obj;
            return newObj;
        });
        const exportData = (localRole === ROLE.OrgAdmin) ? dataToDownload : updatedArrayByRemovingDataUsed
        setExportData(exportData);
    }

    useEffect(() => {
        setLoading(true);
        if (state?.userRoleID === ROLE.Assistant) {
            GetAssistantsList();
        }
        else {
            GetConsultantsList();
        }
        const username: string | null = JSON.parse(sessionStorage.getItem('username')!);
        let userRole: number | null = parseInt(JSON.parse(sessionStorage.getItem('role')!));
        setUserDetails({ username: username, userrole: userRole });
        if (!socketRef.current && userRole === 3 && apis.socketURL) {
            const token = sessionStorage.getItem('token');
            const socketAuth = {
                query: { token },
            };
            socketRef.current = io(apis.socketURL, socketAuth);
            socketRef.current.on('connect', () => {
                console.log('Socket connected');
            });
        }
        getPageTitleHeight();
    }, [state?.showAssistantsCallSessions])

    const GetGridNoRecordMessage = () => {
        return <Box sx={{ mt: 1, px: 1, opacity: '0.8' }}>{
            !user.isConsultant ?
                state?.showAssistantsCallSessions ?
                    state.userRoleID === ROLE.Assistant
                        ? NoCallSessionsMessageAssistantGrid
                        : NoCallSessionsMessageConsultantGrid
                    : "No call logs."
                : "No call logs."
        }
        </Box>
    }

    // dynamic scrollbar for grid view start
    const PageTitleRef: any = useRef();
    const tableGridRef: any = useRef();
    const [tableHeight, setTableHeight] = useState<any>(
    );

    const getPageTitleHeight = () => {
        let pageTitleHeight: any = 0;
        if (PageTitleRef && PageTitleRef.current) {
            pageTitleHeight = PageTitleRef.current.clientHeight;
        }
        setTableHeight(window.innerHeight - pageTitleHeight - 82);
    };

    window.addEventListener("resize", getPageTitleHeight);

    // dynamic scrollbar for grid view end

    return (
        <>
            {openIncomingCallBox()}
            {openReconnectDialog()}
            <CSVLink
                data={exportData}
                filename={`${callSessionsDataExportFileName}.csv`}
                className="hidden"
                ref={(r: any) => { exportDataRef = r }}
                target="_blank"
                headers={columnHeaders}
            />
            <div>
                <Box ref={PageTitleRef} className="page-title-row pb-3">
                    <Grid container alignItems={'center'} textAlign={'left'} rowSpacing={2} spacing={1}>
                        <Grid item xs={auto} >
                            {
                                state?.showAssistantsCallSessions ?
                                    <Button id='backbtn'
                                        onClick={(e) => { onBackClick() }}
                                        startIcon={<span className='cad-arrow-left'></span>}
                                        variant="outlined">
                                        <Typography sx={{ font: 'inherit', display: { xs: 'none', md: 'inline' } }}>Go&nbsp;</Typography>Back
                                    </Button> : null
                            }
                        </Grid>
                        <Grid item xs={12} md={auto} mr={auto} sm className="page-title-box" padding={0}>
                            {
                                state?.showAssistantsCallSessions ? <>
                                    <Typography className="page-title mt-0 mb-0" sx={(theme) => theme.typography.h5}>
                                        {localRole === ROLE.Consultant ? 'My Call Sessions with' : 'Call Sessions for'}:&nbsp;<Typography color="secondary" sx={(theme) => theme.typography.h5} display="inline">
                                            {state.userRoleID === ROLE.Assistant ? assistantName : consultantName}
                                        </Typography>
                                    </Typography>
                                </> :
                                    <>
                                        <Typography className="page-title mt-0 mb-0" sx={(theme) => theme.typography.h5}>{localRole === ROLE.Consultant ? 'My Call Sessions' : 'Call Sessions'}</Typography>
                                    </>

                            }
                        </Grid>
                        <Grid item xs={12} md={auto} padding={0}>
                            {state?.showAssistantsCallSessions &&
                                <Select
                                    id="assistantDropDown"
                                    name='assistantDropDown'
                                    size="small"
                                    sx={{ height: '34px', alignItems: 'end', my: .5, mr: 1 }}
                                    value={state.userRoleID === ROLE.Assistant ? assistant : consultant}
                                    onChange={async (event: SelectChangeEvent) => {
                                        GetAssistantsCallSessionDetails(event.target.value);
                                    }}>
                                    {
                                        state.userRoleID === ROLE.Assistant ?
                                            assistants.map((assistant, index) => {
                                                return <MenuItem
                                                    value={assistant.user_name}
                                                    key={assistant.user_name}>{`${assistant.user_fname} ${assistant.user_lname ?? ''}`}
                                                </MenuItem>
                                            }) :
                                            consultants.map((consultant, index) => {
                                                return <MenuItem
                                                    value={consultant.user_name}
                                                    key={consultant.user_name}>{`${consultant.user_fname} ${consultant.user_lname ?? ''}`}
                                                </MenuItem>
                                            })
                                    }
                                </Select>
                            }
                            <Button size='medium'
                                onClick={(e) => { exportToCSV() }}
                                startIcon={<span className='cad-arrow-up-circle'></span>}
                                variant="contained" color='primary' sx={{ my: .5 }}>
                                Export
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={GridStyling} ref={tableGridRef} height={tableHeight}>
                    <DataGrid
                        components={{
                            NoRowsOverlay: GetGridNoRecordMessage,
                            LoadingOverlay: () => {
                                return <div className='p-2'>No Call Logs</div>
                            }
                        }}
                        loading={loading}
                        sortingOrder={['desc', 'asc']}
                        getRowId={(row) => row.session_id}
                        rows={callSessionsData}
                        columns={localRole === ROLE.OrgAdmin ? columns : columns.filter(i => i.headerName !== "Data Used")}
                        pageSize={pageSize}
                        rowsPerPageOptions={GridRowsPerPageOptions}
                        onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                        autoHeight={false}
                        autoPageSize={false}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        disableSelectionOnClick={true}
                        onColumnHeaderClick={(data) => onColumnHeaderClick(data)}

                    />
                </Box>
            </div>
        </>
    );
}

export default CallSessions;