import { useState } from 'react';
import { useFormik } from 'formik';
import CadisTextField from '../../Components/Input/CadisTextField';
import { Avatar, Box, Checkbox, Container, Divider, Drawer, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import * as Helper from '../../Common/helper';
import { toast } from 'react-toastify';
import apis from '../../HttpConfig/Api';
import CadisButton from '../../Components/Input/CadisButton';
import CadisLoader from '../../Components/Loading/CadisLoader';
import { AddDiscountCouponCOde, UpdateDiscountCouponCOde, } from '../../Common/api.routes';
import { FormAddMode, FormUpdateMode } from '../../Common/helper';
import { useTranslation } from 'react-i18next';
import { IDiscountCoupon } from '../../Interfaces/ISubscription';
import { validateAndSetMonthPrice } from '../../Common/helperFunction';
import * as Yup from "yup";

const AddUpdateCouponCode = (
  { isOpenAddUpdateCouponCode, setIsOpenAddUpdateCouponCode, mode, currentCouponCodeData, GetAllDiscountCouponCode }
    :
    { isOpenAddUpdateCouponCode: boolean, setIsOpenAddUpdateCouponCode: any, mode: string, currentCouponCodeData: IDiscountCoupon, GetAllDiscountCouponCode: any }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    manualCouponCode: Yup.string().test('manualCouponCode', 'Please enter a Coupon Code.', function (value) {
      const { useAutoGeneratedCode } = this.parent;
      if (!useAutoGeneratedCode) {
        return Yup.string().required().isValidSync(value);
      }
      return true;
    }).max(15, 'Maximum Coupon Code length is 15 characters.'),
    useAutoGeneratedCode: Yup.boolean(),
    discountPercentage: Yup.mixed().required('Discount Percentage is required.')
      .test('is-valid-number', 'Discount Percentage must be a valid number.', function (value) {
        if (value === '.' || value === '-.') {
          return false;
        }
        return !isNaN(Number(value));
      }).test('is-positive', 'Discount Percentage must be greater than 0.', function (value) {
        return value > 0;
      })
      .test('is-less-than-100', 'Discount percentage must be less than or equal to 100.', function (value) {
        return value <= 100;
      }),
  });

  const { values, dirty, handleReset, handleSubmit, touched, errors, setFieldValue, resetForm } = useFormik({
    initialValues: {
      couponCodeId: currentCouponCodeData.discount_id,
      manualCouponCode: currentCouponCodeData.discount_code,
      discountPercentage: currentCouponCodeData.discount_percentage,
      autoCouponCode: '',
      couponCode: currentCouponCodeData.discount_code,
      useAutoGeneratedCode: false
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values: any, props: any) => {
      setLoading(true);
      AddUpdateCouponCodeApiCall();
    }
  })

  const onFormSubmitComplete = (resp: any) => {
    if (resp.data.success) {
      handleReset(values);
      setIsOpenAddUpdateCouponCode(false);
      toast.success(`Coupon Code ${mode === FormAddMode ? t('lbl_created') : t('lbl_updated')} ${t('lbl_successfully')}`);
    setLoading(false);
      GetAllDiscountCouponCode();
    } else {
      if (resp.data.error.toLowerCase() === "coupon code already exists") {
        toast.error(t('responseMsg.msg_couponCodeExist'));
      }
      else if (resp.data.error.toLowerCase() === "discount with this code is already exist") {
        toast.error(t('responseMsg.msg_couponCodeExist'));
      }
      else {
        toast.error(resp.data.error);
      }
      setLoading(false);
    }
  }

  const AddUpdateCouponCodeApiCall = async () => {
    const couponCodeData = {
      'discountId': values.couponCodeId,
      'discountCode': values.useAutoGeneratedCode ? values.autoCouponCode : values.manualCouponCode.toUpperCase(),
      'discountPercentage': parseFloat(values.discountPercentage.toString()),
      'isDeleted': false
    }
    try {
      if (mode === Helper.FormAddMode) {
        await apis.instance.post(`${AddDiscountCouponCOde}`, couponCodeData)
          .then((resp: any) => {
            onFormSubmitComplete(resp);
          })
          .finally(() => setLoading(false));
      }
      else if (mode === Helper.FormUpdateMode) {
        await apis.instance.put(`${UpdateDiscountCouponCOde}`, couponCodeData)
          .then((resp: any) => {
            onFormSubmitComplete(resp);
          })
          .finally(() => setLoading(false));
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(t('responseMsg.msg_wrongEntry'));
      console.log(error.message);
    }
  }

  const handlePercentageChange = async (e: any) => {
    validateAndSetMonthPrice(e.target.value, 'discountPercentage', mode, setFieldValue)
  };

  const handleManualCodeChange = (event: any) => {
    const couponCode = event.currentTarget.value;
    setFieldValue("manualCouponCode", couponCode.replace(/\s/g, ""));
  };

  const generateCouponCode = () => {
    const newCouponCode = Math.random().toString(36).substring(2, 10).toUpperCase();
    setFieldValue('autoCouponCode', newCouponCode);
  };

  const handleFormReset = (e: any) => {
    handleReset(e);
  }

  return (
    <>
      <Drawer
        anchor='right'
        open={isOpenAddUpdateCouponCode}
        onClose={() => { return null }}
      >
        <Box role="presentation">
          <Box sx={{ pt: 1.5, position: 'sticky', top: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }} >
            <Container sx={{ alignItems: 'center', display: 'flex' }} disableGutters>
              <Box sx={{ width: '40px', height: '40px', flexGrow: 1, display: { xs: 'flex', pr: 2 } }}>
                <IconButton onClick={() => {
                  resetForm()
                  setIsOpenAddUpdateCouponCode(false)
                }} sx={{}} className='c-pointer'>
                  <span className='cad-close'></span>
                </IconButton>
              </Box>
            </Container>
            <Box sx={{ mb: 2, pb: 2, mt: 2, display: 'flex' }}>
              <Typography className="mt-0 mb-0 slide-right-title" sx={(theme) => theme.typography.h5}>{mode === FormAddMode ? "Create" : "Update"} Coupon</Typography>
            </Box>
          </Box>
          <Grid container width={'100%'}>
            <form onSubmit={handleSubmit} onReset={handleFormReset} style={{ width: '100%' }}>
              <Stack spacing={2} direction={'column'} paddingX={'20px'}>
                <FormControl variant="outlined" size="small">
                  <label id="userTitleLabelID" className='form-label'>{t('lbl_couponCode')}<span className='mandatory-star'></span></label>
                  <CadisTextField id="manualCouponCode" name="manualCouponCode" value={values.manualCouponCode} onChange={handleManualCodeChange} inputProps={{ maxLength: 15 }} error={touched.manualCouponCode && Boolean(errors.manualCouponCode)} helperText={touched.manualCouponCode && t(`${errors.manualCouponCode ? errors.manualCouponCode : ''}`)} className='custom-field-caps' disabled={values.useAutoGeneratedCode} />
                </FormControl>
                <Divider>OR</Divider>
                <Grid container rowSpacing={0} columnGap={1} alignItems={"center"}>
                  <Grid item xs="auto">
                    <FormControl variant="outlined" size="small">
                      <CadisButton size="large" variant="outlined" color='primary' onClick={generateCouponCode} >
                        {t('lbl_autoGenerateCouponCode')}
                      </CadisButton>
                    </FormControl>
                  </Grid>
                  <Grid item xs>
                    <CadisTextField id="autoCouponCode" name="autoCouponCode" value={values.autoCouponCode} sx={{ border: 0 }} className='custom-field-outline' disabled />
                  </Grid>
                </Grid>
                <FormControl sx={{ marginTop: '0 !important' }}>
                  <FormControlLabel control={
                    <Checkbox id='useAutoGeneratedCode' name='useAutoGeneratedCode' value={values.useAutoGeneratedCode} checked={values.useAutoGeneratedCode}
                      onChange={(event: any, checked: boolean) => {
                        setFieldValue("useAutoGeneratedCode", checked)
                      }} disabled={!values.autoCouponCode} />
                  } label={t('lbl_useAutoGeneratedCouponCode')} />
                </FormControl>
                <FormControl>
                  <label id="userTitleLabelID" className='form-label '>{t('lbl_discount')} ({t('lbl_in')} &#37;)<span className='mandatory-star'></span></label>
                  <CadisTextField
                    id="discountPercentage"
                    name="discountPercentage"
                    value={values.discountPercentage}
                    onChange={handlePercentageChange}
                    error={touched.discountPercentage && Boolean(errors.discountPercentage)}
                    helperText={touched.discountPercentage && t(`${errors.discountPercentage ? errors.discountPercentage : ''}`)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ fontSize: '1.2rem' }}>
                          &#37;
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Stack>
              <Box sx={{ pt: 2, pb: 2, position: 'sticky', bottom: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }}>
                <Grid container spacing={0} alignItems="center">
                  <Grid item xs="auto" >
                    <CadisButton type='submit' variant="contained" color='primary' disabled={mode === 'update' && !dirty || loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                      {mode === 'add' ? t('lbl_create') : t('lbl_update')}
                    </CadisButton>
                  </Grid>
                  <Grid item xs="auto">
                    <Box sx={{ pl: 1 }}>
                      <CadisButton type='reset' variant="contained" color='error' disabled={mode === 'update' && !dirty || loading}>
                        {mode === FormUpdateMode ? `${t('lbl_reset')}` : `${t('lbl_clear')}`}
                      </CadisButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Grid>
        </Box>
      </Drawer >
    </>
  )
}

export default AddUpdateCouponCode