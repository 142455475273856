import { Avatar, Box, Button, Checkbox, Container, Drawer, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select, Stack, Switch, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import userProfile from '../../Assets/Images/userProfile.svg';
import { CreateUserRoute, UpdateUserRoute } from '../../Common/api.routes';
import * as Helper from '../../Common/helper';
import { ActiveStatusID, FormAddMode, FormUpdateMode, InActiveStatusID, allowedProfileImgExtensions, currentHost, maxPhoneLength, minPhoneLength, numberAndDashRegex, phoneNumberLengthValidationMessage, phoneNumberStartEndHyphenValidationMessage } from '../../Common/helper';
import * as HelperFunction from '../../Common/helperFunction';
import { DisplayProfileImageValidationMessage, GenerateRandomUsername, ValidateFileSize, ValidateFileType, fileToBase64, getUserRole } from "../../Common/helperFunction";
import { OrganizationAdminValidationSchema } from '../../Common/validationSchemas';
import CadisButton from '../../Components/Input/CadisButton';
import CadisTextField from '../../Components/Input/CadisTextField';
import CadisLoader from '../../Components/Loading/CadisLoader';
import apis from '../../HttpConfig/Api';
import { ROLE, ROLENAMES } from '../../Interfaces/IConstants';
import { IUser } from '../../Interfaces/IUser';

const AddUpdateOrganizationAdmin = (
    { isOpenAddUpdateAdmin, setIsOpenAddUpdateAdminExpandAdminData, setShowOrgAdminForm,
        mode, currentOrgAdminData, getOrgUserList, userRole = ROLENAMES.OrgAdmin, consultantSpecialities, userFlow }: {
            isOpenAddUpdateAdmin: boolean, setIsOpenAddUpdateAdminExpandAdminData: any,
            mode: any, currentOrgAdminData: IUser, getOrgUserList: any, setShowOrgAdminForm?: Dispatch<SetStateAction<boolean>>, userRole: string | any, consultantSpecialities: any, userFlow: string
        }) => {
    const userRoleId = getUserRole(userRole);
    const [loading, setLoading] = useState(false);
    const user = HelperFunction.isAuthenticated();
    const [spec, setSpeciality] = useState({ 'speciality_id': "0", 'speciality': '' })
    let profileImgRef = useRef<any>();
    const { t } = useTranslation();
    const localRole = Number(sessionStorage.getItem("role"));
    const { values, dirty, handleReset, handleSubmit, handleChange, touched, errors, setFieldValue, setFieldError, resetForm } = useFormik({
        initialValues: {
            orgID: currentOrgAdminData.org_id ?? 0,
            orgName: currentOrgAdminData.org_name ?? "",
            userID: currentOrgAdminData.user_id,
            orgAdminTitle: currentOrgAdminData.org_admin_title,
            orgAdminFName: currentOrgAdminData.org_admin_fname,
            orgAdminLName: currentOrgAdminData.org_admin_lname,
            orgAdminEmail: currentOrgAdminData.org_admin_email,
            orgAdminUsername: currentOrgAdminData.org_admin_username,
            orgAdminPhoneM: currentOrgAdminData.org_admin_phone_m,
            orgAdminPhoneNM: currentOrgAdminData.org_admin_phone_nm,
            orgAdminStatus: currentOrgAdminData.org_admin_status,
            orgAdminSendDetailsInEmail: true,
            specialities: currentOrgAdminData.specialities.length === 0 ? spec.speciality_id : currentOrgAdminData.specialities[0].speciality_id,
            orgAdminProfileImg: currentOrgAdminData.profile_image,
            countryCode: currentOrgAdminData.country_code,
            newRoleAssignStatus: currentOrgAdminData.assign_role_to_user,
            isUserAssistant: userRoleId === ROLE.Assistant,
            isSpecialities: userRoleId === ROLE.Consultant,
            isPasswordSet: currentOrgAdminData.isPasswordSet
        },
        enableReinitialize: true,
        validationSchema: OrganizationAdminValidationSchema,
        onSubmit: async (values: any, props: any) => {
            const arePhoneNumbersValid = ValidatePhoneNumbers(values.orgAdminPhoneM, values.orgAdminPhoneNM, "orgAdminPhoneM", "orgAdminPhoneNM");
            if (!arePhoneNumbersValid) {
                return;
            }
            setLoading(true);
            AddUpdateOrgApiCall();
        }
    })
    const ValidatePhoneNumbers = (phoneM: string, phoneNM: string, phoneMFieldName: string, phoneNMFieldName: string) => {
        let isPhoneNumberValid = true;
        const orgPhoneMFirstChar = phoneM.charAt(0);
        const orgPhoneMlastChar = phoneM.charAt(phoneM.length - 1);
        if (orgPhoneMFirstChar === "-" || orgPhoneMlastChar === "-") {
            setFieldError(phoneMFieldName, phoneNumberStartEndHyphenValidationMessage);
            isPhoneNumberValid = false;
        }

        const orgPhoneMTextWithoutHyphens = phoneM.replace(/-/g, "");
        if (orgPhoneMTextWithoutHyphens.length < minPhoneLength || orgPhoneMTextWithoutHyphens.length > maxPhoneLength) {
            setFieldError(phoneMFieldName, phoneNumberLengthValidationMessage);
            isPhoneNumberValid = false;
        }

        if (phoneNM && phoneNM.length > 0) {
            const orgPhoneNMFirstChar = phoneNM.charAt(0);
            const orgPhoneNMlastChar = phoneNM.charAt(phoneNM.length - 1);
            if (orgPhoneNMFirstChar === "-" || orgPhoneNMlastChar === "-") {
                setFieldError(phoneNMFieldName, phoneNumberStartEndHyphenValidationMessage);
                isPhoneNumberValid = false;
            }

            const orgPhoneNMTextWithoutHyphens = phoneNM.replace(/-/g, "");
            if (orgPhoneNMTextWithoutHyphens.length < minPhoneLength || orgPhoneNMTextWithoutHyphens.length > maxPhoneLength) {
                setFieldError(phoneNMFieldName, phoneNumberLengthValidationMessage);
                isPhoneNumberValid = false;
            }
        }
        return isPhoneNumberValid;
    }

    const onFormSubmitComplete = (resp: any) => {
        if (resp.data.success) {
            handleReset(values);
            if (userFlow === "consultantFlow" || userFlow === "cadisAdminFlow") {
                getOrgUserList(values.orgID);
            }
            else if (userFlow === "assistantFlow") {
                getOrgUserList();
            }
            else {
                getOrgUserList(values.orgName, values.orgID, userRole);
            }
            setIsOpenAddUpdateAdminExpandAdminData(false);
            if (setShowOrgAdminForm) {
                setShowOrgAdminForm(false);
            }
            toast.success(`${userRole} ${mode === FormAddMode ? t('lbl_created') : t('lbl_updated')} ${t('lbl_successfully')}`);
            setLoading(false);
        } else {
            if (resp.data.error.toLowerCase() === "user with this user id already exist. try using another user id")
                toast.error(t('responseMsg.msg_useralreadyexist'));
            else if (resp.data.error.toLowerCase() === "organization has exceeded the maximum limit for user creation.")
                if (localRole === ROLE.OrgAdmin) {
                    toast.error(t('responseMsg.msg_assignConstultantAccess'));
                } else {
                    toast.error(t('responseMsg.msg_assignConstultantAccessOrg'));
                }
            else if (resp.data.error.toLowerCase() === 'failed to retrieve total user count for the organization.'
                || resp.data.error.toLowerCase() === 'failed to retrieve total active user count for the organization.'
                || resp.data.error.toLowerCase() === 'cannot update user as no subscription plan is active for this organization') {
                if (localRole === ROLE.OrgAdmin) {
                    toast.error(t('responseMsg.msg_noActiveSubscriptiontoAssignAccess'));
                } else {
                    toast.error(t('responseMsg.msg_noActiveSubscriptiontoAssignAccessOrg'));
                }
            }
            else
                toast.error(resp.data.error);
            setLoading(false);
        }
    }

    const AddUpdateOrgApiCall = async () => {
        const organizationUserData = {
            'orgID': values.orgID,
            'userID': values.userID,
            'title': values.orgAdminTitle,
            'fname': values.orgAdminFName,
            'lname': values.orgAdminLName,
            'username': values.orgAdminUsername,
            'email': values.orgAdminEmail,
            'phoneM': values.orgAdminPhoneM,
            'phoneNM': values.orgAdminPhoneNM,
            'role': userRoleId,
            'sendDetailsInEmail': values.orgAdminSendDetailsInEmail,
            'status': values.orgAdminStatus === true ? ActiveStatusID : values.orgAdminStatus === false ? InActiveStatusID : values.orgAdminStatus,
            'user_profile_img': values.orgAdminProfileImg,
            'country_code': values.countryCode,
            'specialityId': values.specialities,
            'set_password_url': currentHost + "/setNewPassword",
            'createID': user.userId,
            'updateID': user.userId,
            'newRoleAssignStatus': values.newRoleAssignStatus,
            'newAssignedRole': currentOrgAdminData.newAssignedRole,
            'isConferenceCall': false,
            'sessionId': 0,
        }
        try {
            if (mode === Helper.FormAddMode) {
                await apis.instance.post(`${CreateUserRoute}`, organizationUserData)
                    .then((resp: any) => {
                        onFormSubmitComplete(resp);
                    })
                    .finally(() => setLoading(false));
            }
            else if (mode === Helper.FormUpdateMode) {
                await apis.instance.put(`${UpdateUserRoute}`, organizationUserData)
                    .then((resp: any) => {
                        onFormSubmitComplete(resp);
                    })
                    .finally(() => setLoading(false));
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(t('responseMsg.msg_wrongEntry'));
            console.log(error.message);
        }
    }

    const SetUsername = (fieldname: string, fieldValue: string, firstname: string, lastname: string) => {
        setFieldValue(fieldname, fieldValue);
        if (mode === FormAddMode) {
            let randomUsername = GenerateRandomUsername(firstname, lastname);
            setFieldValue("orgAdminUsername", randomUsername);
        }
    }

    const RemoveProfileImg = () => {
        profileImgRef.current.value = ''
        setFieldValue("orgAdminProfileImg", '')
    }

    return (
        <>
            <Drawer
                anchor='right'
                open={isOpenAddUpdateAdmin}
                onClose={() => { return null }}
            >
                <Box role="presentation">
                    <Box sx={{ pt: 1.5, position: 'sticky', top: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }} >
                        <Container sx={{ alignItems: 'center', display: 'flex' }} disableGutters>
                            <Box sx={{ width: '40px', height: '40px', flexGrow: 1, display: { xs: 'flex', pr: 2 } }}>
                                <IconButton onClick={() => {
                                    resetForm();
                                    setIsOpenAddUpdateAdminExpandAdminData(false);
                                    handleReset(values);
                                    if (setShowOrgAdminForm) {
                                        setShowOrgAdminForm(false);
                                    }
                                }} sx={{}} className='c-pointer'>
                                    <span className='cad-close'></span>
                                </IconButton>
                            </Box>
                        </Container>
                        <Box sx={{ mb: 2, pb: 2, mt: 2, display: 'flex' }}>
                            <Typography className="mt-0 mb-0 slide-right-title" sx={(theme) => theme.typography.h5}>{mode === FormAddMode ? "Create" : "Update"} {userRole}</Typography>
                        </Box>
                    </Box>
                    <Grid container width={'100%'}>
                        <form onSubmit={handleSubmit} onReset={handleReset} style={{ width: '100%' }}>
                            <Stack spacing={2} direction={'column'} paddingX={'20px'}>
                                <FormControl>
                                    {/* <label className='form-label '>Upload Profile Image</label> */}
                                    <Stack direction="column" >
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignSelf: 'center' }} className='upload-user-prof-wrapper'>
                                            <Avatar
                                                alt={user.name} src={values.orgAdminProfileImg !== '' ?
                                                    values.orgAdminProfileImg : userProfile}
                                                sx={{ boxShadow: '0 0 1px #AEAEAE', border: 'solid 2px #ffffff', width: 100, height: 100, }} />
                                        </Box>
                                        <Typography color="error" textAlign="center" fontWeight="bold"><small>{t('lbl_maxFileSize')}</small></Typography>
                                        <Grid container spacing={0} alignItems="center" sx={{ mt: 1, displa: 'flex', justifyContent: 'center' }}>
                                            <Box sx={{ pl: 1 }} className='upload-btn-wrapper'>
                                                <Button variant="contained" component="label">
                                                    {t('lbl_uploadPhoto')}
                                                    <input hidden onChange={async (event: any) => {
                                                        if (event.currentTarget.files.length > 0) {
                                                            const file = event.currentTarget.files[0];
                                                            const isFileSizeValid = ValidateFileSize(file);
                                                            const isFileTypeValid = ValidateFileType(file);
                                                            if (!isFileSizeValid || !isFileTypeValid) {
                                                                DisplayProfileImageValidationMessage(isFileTypeValid, isFileSizeValid);
                                                                setFieldValue("orgAdminProfileImg", '')
                                                            } else {
                                                                let base = await fileToBase64(file);
                                                                setFieldValue("orgAdminProfileImg", base)
                                                            }
                                                        } else RemoveProfileImg();
                                                    }}
                                                        accept={allowedProfileImgExtensions}
                                                        id="orgAdminProfileImg"
                                                        name='orgAdminProfileImg'
                                                        type="file"
                                                        ref={profileImgRef} />
                                                </Button>
                                            </Box>
                                            {values.orgAdminProfileImg.length > 0 ? <Box sx={{ pl: 1 }}>
                                                <CadisButton variant="contained" color='error' onClick={() => RemoveProfileImg()}>
                                                    {t('lbl_delete')}
                                                </CadisButton>
                                            </Box> : null}
                                        </Grid>
                                    </Stack>
                                </FormControl>
                                {
                                    userRoleId === ROLE.Assistant ?
                                        <FormControl variant="outlined">
                                            <label className='form-label '>
                                                {t('lbl_individualName')}
                                                <span className='mandatory-star'></span>
                                            </label>
                                            <CadisTextField
                                                autoComplete='false'
                                                id="orgAdminFName"
                                                name="orgAdminFName"
                                                value={values.orgAdminFName}
                                                onChange={async (event: any) => {
                                                    const firstname = event.currentTarget.value;
                                                    SetUsername("orgAdminFName", firstname.trimStart(), firstname, values.orgAdminFName);
                                                }}
                                                error={touched.orgAdminFName && Boolean(errors.orgAdminFName)}
                                                helperText={touched.orgAdminFName && t(`${errors.orgAdminFName ? errors.orgAdminFName : ''}`)}
                                            />
                                        </FormControl>
                                        :
                                        <Box>
                                            <Grid container spacing={1} sx={{ marginX: '-8px', display: 'flex', alignItems: 'flex-start' }} >
                                                <Grid item xs="auto" width='100px !important'>
                                                    <FormControl variant="outlined" size="small" fullWidth>
                                                        <label id="userTitleLabelID" className='form-label '>{t('lbl_title')} <span className='mandatory-star'></span></label>
                                                        <Select fullWidth
                                                            labelId='userTitleLabelID'
                                                            id="orgAdminTitle"
                                                            name='orgAdminTitle'
                                                            value={values.orgAdminTitle}
                                                            onChange={handleChange}>
                                                            {Helper.UserTitles.map((title, index) => { return <MenuItem value={title} key={index}>{title}</MenuItem> })}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg >
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={6} sm={6}>
                                                            <FormControl>
                                                                <label id="userTitleLabelID" className='form-label '>{t('lbl_firstName')} <span className='mandatory-star'></span></label>
                                                                <CadisTextField
                                                                    fullWidth
                                                                    autoComplete='false'
                                                                    id="orgAdminFName"
                                                                    name="orgAdminFName"
                                                                    value={values.orgAdminFName}
                                                                    inputProps={{ maxLength: 25 }}
                                                                    onChange={async (event: any) => {
                                                                        const firstname = event.currentTarget.value;
                                                                        SetUsername("orgAdminFName", firstname.replace(/\s/g, ""), firstname, values.orgAdminLName);
                                                                    }}
                                                                    error={touched.orgAdminFName && Boolean(errors.orgAdminFName)}
                                                                    helperText={touched.orgAdminFName && t(`${errors.orgAdminFName ? errors.orgAdminFName : ''}`)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6}>
                                                            <FormControl>
                                                                <label id="userTitleLabelID" className='form-label '>{t('lbl_lastName')}</label>
                                                                <CadisTextField
                                                                    autoComplete='false'
                                                                    id="orgAdminLName"
                                                                    name="orgAdminLName"
                                                                    value={values.orgAdminLName}
                                                                    inputProps={{ maxLength: 25 }}
                                                                    onChange={async (event: any) => {
                                                                        const lastname = event.currentTarget.value;
                                                                        SetUsername("orgAdminLName", lastname.replace(/\s/g, ""), values.orgAdminFName, lastname);
                                                                    }}
                                                                    error={touched.orgAdminLName && Boolean(errors.orgAdminLName)}
                                                                    helperText={touched.orgAdminLName && errors.orgAdminLName}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>}
                                <FormControl>
                                    <label id="userTitleLabelID" className='form-label '>{t('lbl_email')} <span className='mandatory-star'></span></label>
                                    <CadisTextField
                                        id="orgAdminEmail"
                                        name="orgAdminEmail"
                                        value={values.orgAdminEmail}
                                        onChange={handleChange}
                                        error={touched.orgAdminEmail && Boolean(errors.orgAdminEmail)}
                                        helperText={touched.orgAdminEmail && t(`${errors.orgAdminEmail ? errors.orgAdminEmail : ''}`)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <label id="userTitleLabelID" className='form-label '>{t('lbl_userId')} <span className='mandatory-star'></span></label>
                                    <CadisTextField
                                        autoComplete='false'
                                        id="orgAdminUsername"
                                        name="orgAdminUsername"
                                        value={values.orgAdminUsername}
                                        onChange={async (event: any) => {
                                            const username = event.currentTarget.value;
                                            setFieldValue("orgAdminUsername", username.replace(/\s/g, ""));
                                        }}
                                        error={touched.orgAdminUsername && Boolean(errors.orgAdminUsername)}
                                        helperText={touched.orgAdminUsername && t(`${errors.orgAdminUsername ? errors.orgAdminUsername : ''}`)}
                                    />
                                </FormControl>
                                {userRoleId !== ROLE.Assistant && <FormControl variant="outlined" size="small">
                                    <label className='form-label '>
                                        {t('lbl_country')}
                                        <span className='mandatory-star'></span>
                                    </label>
                                    <Select fullWidth
                                        labelId='userTitleLabelID'
                                        id="countryCode"
                                        name='countryCode'
                                        value={values.countryCode}
                                        onChange={handleChange}>
                                        {Helper.countries.map((item, index) => { return <MenuItem value={item.code} key={index}>{item.name}</MenuItem> })}
                                    </Select>
                                </FormControl>}
                                <FormControl>
                                    <label id="userTitleLabelID" className='form-label '>{t('lbl_phone1')} <span className='mandatory-star'></span></label>
                                    <CadisTextField
                                        id="orgAdminPhoneM"
                                        name="orgAdminPhoneM"
                                        value={values.orgAdminPhoneM}
                                        onChange={async (e: any) => {
                                            if (e.currentTarget.value.trim().length < 1) {
                                                setFieldValue("orgAdminPhoneM", e.currentTarget.value)
                                            }
                                            else if (numberAndDashRegex.test(e.currentTarget.value)) {
                                                setFieldValue("orgAdminPhoneM", e.currentTarget.value)
                                            }
                                        }}
                                        error={touched.orgAdminPhoneM && Boolean(errors.orgAdminPhoneM)}
                                        helperText={touched.orgAdminPhoneM && t(`${errors.orgAdminPhoneM ? errors.orgAdminPhoneM : ''}`)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <label id="userTitleLabelID" className='form-label '>{t('lbl_phone2')}</label>
                                    <CadisTextField
                                        id="orgAdminPhoneNM"
                                        name="orgAdminPhoneNM"
                                        value={values.orgAdminPhoneNM}
                                        onChange={async (e: any) => {
                                            if (e.currentTarget.value.trim().length < 1) {
                                                setFieldValue("orgAdminPhoneNM", e.currentTarget.value)
                                            }
                                            else if (numberAndDashRegex.test(e.currentTarget.value)) {
                                                setFieldValue("orgAdminPhoneNM", e.currentTarget.value)
                                            }
                                        }}
                                        error={touched.orgAdminPhoneNM && Boolean(errors.orgAdminPhoneNM)}
                                        helperText={touched.orgAdminPhoneNM && errors.orgAdminPhoneNM}
                                    />
                                </FormControl>
                                {userRoleId === ROLE.Consultant && <FormControl variant="outlined" size="small">
                                    <label className='form-label '>
                                        {t('lbl_speciality')}
                                        <span className='mandatory-star'></span>
                                    </label>
                                    <Select fullWidth
                                        labelId='userTitleLabelID'
                                        id="specialities"
                                        name='specialities'
                                        value={values.specialities}
                                        onChange={handleChange}>
                                        <MenuItem value="0">{t('lbl_selectSpeciality')}</MenuItem>
                                        {consultantSpecialities.slice().sort((a: any, b: any) => {
                                            if (a.speciality === "Nephrology") return -1;
                                            if (b.speciality === "Nephrology") return 1;
                                            return a.speciality.localeCompare(b.speciality);
                                        })
                                            .map((item: any, index: any) => (
                                                <MenuItem value={item.speciality_id} key={index}>
                                                    {item.speciality}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                    {errors.specialities && touched.specialities ? (
                                        <Typography variant="caption" color="error">
                                            {t(errors.specialities)}
                                        </Typography>) : null}
                                </FormControl>}
                                {(mode !== 'add' && userRoleId !== ROLE.Assistant && userRoleId !== ROLE.CadisAdmin) && <FormControl>
                                    <FormControlLabel control={
                                        <Checkbox
                                            id='newRoleAssignStatus' name='newRoleAssignStatus'
                                            onChange={(event: any, checked: boolean) => {
                                                setFieldValue("newRoleAssignStatus", checked);
                                            }} checked={Boolean(values.newRoleAssignStatus)}
                                            disabled={!values.isPasswordSet}
                                        />
                                    }
                                        label={`${t('lbl_enable')} ${currentOrgAdminData.newAssignedRole === ROLE.Consultant ? t('lbl_consultant') : t('lbl_admin')} ${t('lbl_accesstag')}`}
                                    />
                                </FormControl>}
                                <FormControl>
                                    <FormControlLabel control={
                                        <Checkbox
                                            id='orgAdminSendDetailsInEmail' name='orgAdminSendDetailsInEmail'
                                            onChange={(event: any, checked: boolean) => {
                                                setFieldValue("orgAdminSendDetailsInEmail", checked);
                                            }} checked={Boolean(values.orgAdminSendDetailsInEmail)} disabled={mode === FormAddMode} />
                                    } label="Send Details in Email" />
                                </FormControl>
                                {mode !== 'add' ?
                                    <FormControl>
                                        <label id="userTitleLabelID" className='form-label '>{t('lbl_permission')}</label>
                                        <Box className='access-permission-box' sx={{ px: 2, py: 1 }}>
                                            <FormControlLabel control={
                                                <Switch color="success" id="orgAdminStatus" name="orgAdminStatus"
                                                    checked={Boolean(values.orgAdminStatus)}
                                                    onChange={(event: any, checked: boolean) => {
                                                        setFieldValue("orgAdminStatus", checked)
                                                    }} disabled={!values.isPasswordSet && userRoleId !== ROLE.Assistant} />
                                            } label={`${values.orgAdminStatus ? t('lbl_disable') : t('lbl_enable')} ${userRole} ${t('lbl_accesstag')}`} />

                                            {(!values.isPasswordSet && userRoleId !== ROLE.Assistant) && <div className='helper-text'>{t('lbl_userStatus')}</div>}

                                        </Box></FormControl>
                                    : null}
                            </Stack>
                            <Box sx={{ pt: 2, pb: 2, position: 'sticky', bottom: 0, background: '#ffffff', paddingX: '20px', zIndex: 1 }}>
                                <Grid container spacing={0} alignItems="center">
                                    <Grid item xs="auto" >
                                        <CadisButton type='submit' variant="contained" color='primary' disabled={mode === 'update' && !dirty || loading} endIcon={loading ? <CadisLoader color='primary' size={14} /> : ''}>
                                            {mode === 'add' ? t('lbl_create') : t('lbl_update')}
                                        </CadisButton>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Box sx={{ pl: 1 }}>
                                            <CadisButton type='reset' variant="contained" color='error' disabled={mode === 'update' && !dirty || loading}>
                                                {mode === FormUpdateMode ? `${t('lbl_reset')}` : `${t('lbl_clear')}`}
                                            </CadisButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    </Grid>
                </Box>
            </Drawer>
        </>
    )
}

export default AddUpdateOrganizationAdmin