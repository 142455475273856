import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, Tooltip,
    Typography
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import apis from "../../HttpConfig/Api";
 import { DeleteAddOnDataPackRoute, GetAllAddonPackList, } from "../../Common/api.routes";
import {
    ButtonActionAdd, FormAddMode, ButtonActionEdit, ButtonActionDelete, FormUpdateMode, DefaultGridRowSize, GridRowsPerPageOptions, FormViewMode,
    DefaultAddOnPackDetails,
} from "../../Common/helper";
import { GridStyling } from "../../Common/sharedStyling";
import { useTranslation } from "react-i18next";
import { IUpdateAddOnPack } from "../../Interfaces/ISubscription";
import CadisButton from "../../Components/Input/CadisButton";
import AddUpdateDataAddOn from "./AddUpdateDataAddOn";
import { GetGridNoRecordMessage, formateDecimalValues } from "../../Common/helperFunction";

const DataAddOnList = () => {
    const { t } = useTranslation();
    const [isOpenDataAddOnForm, setIsOpenDataAddOnForm] = useState(false);
    const [addOnPackList, setAddOnPackList] = useState<IUpdateAddOnPack[]>([]);
    const [pageSize, setPageSize] = useState<number>(DefaultGridRowSize);
    const [dataAddOnPackCreationMode, setDataAddOnPackCreationMode] = useState(FormViewMode);
    const [openDeleteAddOnPackDialog, setOpenDeleteAddOnPackDialog] = useState({
        open: false,
        dataPackId: null,
    });

    const [currentAddonPackDetails, setCurrentAddOnPackDetails] = useState<IUpdateAddOnPack>({
        addon_price: "",
        addon_data: "",
        addon_id: ''
    });
    const tableGridRef: any = useRef();
    const PageTitleRef: any = useRef();
    const [tableHeight, setTableHeight] = useState<any>();

    const handleCloseDelete = () => {
        setOpenDeleteAddOnPackDialog({
            'open': false,
            'dataPackId': null,
        });
    };
    // dynamic scrollbar for grid view start
    const getPageTitleHeight = () => {
        let pageTitleHeight: any = 0;
        if (PageTitleRef && PageTitleRef.current) {
            pageTitleHeight = PageTitleRef.current.clientHeight;
        }
        setTableHeight(window.innerHeight - pageTitleHeight - 82);
    };
    const deleteAddonPack = async () => {
        try {
            const deletedPack = await apis.instance.delete(`${DeleteAddOnDataPackRoute}${openDeleteAddOnPackDialog.dataPackId}`)
            if (deletedPack.data.success) {
                handleCloseDelete();
                getallAddonPackList();
                toast.success(t('responseMsg.msg_addOnPackDeleted'))
            } else {
                toast.error(deletedPack.data.error);
            }
        } catch (error: any) {
            handleCloseDelete();
            toast.error(t('responseMsg.msg_wrongEntry'));
            console.log(error.message);
        }
    }

    const onClick = (e: any, params: any) => {
        e.stopPropagation(); // don't select this row after 
        OnActionClick(e.currentTarget.id, params?.row);
    };

    const OnActionClick = (actionId: string, row: any) => {
        switch (actionId) {
            case ButtonActionAdd:
                setDataAddOnPackCreationMode(FormAddMode);
                setCurrentAddOnPackDetails(DefaultAddOnPackDetails);
                setIsOpenDataAddOnForm(true);
                break;
            case ButtonActionEdit:
                setCurrentAddOnPackDetails(row);
                setDataAddOnPackCreationMode(FormUpdateMode);
                setIsOpenDataAddOnForm(true);
                break;
            case ButtonActionDelete:
                setOpenDeleteAddOnPackDialog({
                    open: true,
                    dataPackId: row.addon_id
                })
                break;
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'addon_data', flex: 1, headerName: 'Data Pack', minWidth: 120,
            cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: false,
            renderCell: ({ row }: any) => (`${formateDecimalValues(parseFloat(row.addon_data))} GB`),
        },
        {
            field: 'addon_price', flex: 1, headerName: 'Price', minWidth: 160, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: false,
            renderCell: ({ row }: any) => (<>{`$ ${formateDecimalValues(parseFloat(row.addon_price))}`}</>
            ),
        },
        {
            field: 'actions', headerName: 'Actions',
            minWidth: 190, flex: 1, headerClassName: 'app-theme-header bg-action',
            headerAlign: "center", align: "center",
            cellClassName: 'app-theme-cell bg-action', sortable: false,
            renderCell: (params: any) => {
                return <Stack direction="row" spacing={0}>
                    <Box className='grid-act-btn'>
                        <Tooltip title={t('lbl_editAddOn')} placement="bottom-end">
                            <IconButton aria-label="AdminIcon" id={ButtonActionEdit}
                                onClick={(e) => {
                                    onClick(e, params)
                                }} color="primary"><span className='cad-edit'></span>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box className='grid-act-btn'></Box>
                    <Box className='grid-act-btn'>
                        <Tooltip title={t('lbl_deleteAddOn')} placement="bottom-end">
                            <IconButton aria-label="AdminIcon" id={ButtonActionDelete} color="error" onClick={(e) => {
                                onClick(e, params)
                            }}><span className='cad-trash-icon'></span></IconButton>
                        </Tooltip>
                    </Box>
                </Stack>
            }
        }
    ]


    const getallAddonPackList = async () => {
        try {
            const addonList = await apis.instance.get(`${GetAllAddonPackList}`)
            if (addonList.data.success) {
                setAddOnPackList(addonList.data.data)
            } else {
                if (addonList.data.error == "Subscription data addon does not exist.") {
                    setAddOnPackList([])
                }
            }
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => { 
        getallAddonPackList();
        getPageTitleHeight();
        window.addEventListener("resize", getPageTitleHeight);
        return () => window.removeEventListener("resize", getPageTitleHeight);
    }, []);

    return (
            <div>
                <Box ref={PageTitleRef} className="page-title-row pb-3">
                    <Grid container alignItems={'center'} textAlign={'left'} rowSpacing={2} columnSpacing={{ xs: 2, md: 0 }}>
                        <Grid item xs={12} md={'auto'} mr={'auto'} sm padding={0} className="page-title-box">
                            <Typography className="page-title mt-0 mb-0" sx={(theme) => theme.typography.h5}>{t('lbl_dataAddOn')}</Typography>
                        </Grid>
                        <Grid item xs={12} md={'auto'} padding={0}>
                            <Button id={ButtonActionAdd}
                                onClick={(e) => {
                                    onClick(e, null)
                                    setIsOpenDataAddOnForm(true)
                                    setDataAddOnPackCreationMode(FormAddMode)
                                }}
                                startIcon={<span className='cad-plus'></span>}
                                variant="contained" sx={{ my: .5 }}>&nbsp;{t('lbl_create')}
                                <Typography sx={{ font: 'inherit' }} display={{ xs: 'none', md: 'inline' }}>&nbsp;{t('lbl_addOnDataPack')}</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={GridStyling} ref={tableGridRef} height={tableHeight}>
                    <DataGrid
                        components={{
                            NoRowsOverlay: GetGridNoRecordMessage,
                        }}
                        rows={addOnPackList}
                        columns={columns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                        rowsPerPageOptions={GridRowsPerPageOptions}
                        getRowId={(row: any) => row.addon_id}
                        autoHeight={false}
                        autoPageSize={false}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                        disableSelectionOnClick={true}
                    />
                </Box>
                {dataAddOnPackCreationMode !== FormViewMode ? (
                <AddUpdateDataAddOn
                    addOnDetails={currentAddonPackDetails}
                    mode={dataAddOnPackCreationMode}
                    isOpenDataAddOnForm={isOpenDataAddOnForm}
                    setIsOpenDataAddOnForm={setIsOpenDataAddOnForm}
                    getallAddonPackList={getallAddonPackList}
                />
            ) : null}
            <Dialog
                open={openDeleteAddOnPackDialog.open}
                onClose={() => {
                    return null;
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
                <DialogContent>
                    {t('lbl_confirmationDelteAddonPack')}
                </DialogContent>
                <DialogActions>
                    <CadisButton
                        variant="contained"
                        color="error"
                        onClick={deleteAddonPack}
                    >
                        {t('lbl_yes')}
                    </CadisButton>
                    <CadisButton
                        variant="contained"
                        color="success"
                        onClick={handleCloseDelete}
                    >
                        {t('lbl_no')}
                    </CadisButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DataAddOnList;
