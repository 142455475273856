import { useState, useEffect } from 'react';

const useIsMobileDevice = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const userAgent = typeof navigator === 'undefined' ? '' : navigator.userAgent;
        const mobileDevices = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

        setIsMobile(mobileDevices.test(userAgent));
    }, []);

    return isMobile;
};

export default useIsMobileDevice;
