import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { auto } from "@popperjs/core";
import { useEffect, useRef, useState } from "react";
import { GridStyling } from "../../Common/sharedStyling";
import { GetGridNoRecordMessage } from "../../Common/helperFunction";
import { useUserRoleContext } from "../../context/UserRoleContext";
import apis from "../../HttpConfig/Api";
import { toast } from "react-toastify";
import { ButtonActionAdd, ButtonActionCopy, ButtonActionDelete, ButtonActionEdit, DefaultCouponCodeDetails, DefaultGridRowSize, FormAddMode, FormUpdateMode, FormViewMode, GridRowsPerPageOptions } from "../../Common/helper";
import { useTranslation } from "react-i18next";
import { DeleteDiscountCouponCOde, GetAllDiscountCouponCOde } from "../../Common/api.routes";
import AddUpdateCouponCode from "./AddUpdateCouponCode";
import { IDiscountCoupon } from "../../Interfaces/ISubscription";
import CadisButton from "../../Components/Input/CadisButton";

const DiscountCouponCode = () => {
  const { handleToggleLoader } = useUserRoleContext();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState<number>(DefaultGridRowSize);
  const [couponCodeListData, setCouponCodeListData] = useState([]);
  const [currentCouponCodeData, setCurrentCouponCodeData] = useState<IDiscountCoupon>(DefaultCouponCodeDetails);
  const [isOpenAddUpdateCouponCode, setIsOpenAddUpdateCouponCode] = useState<boolean>(false)
  const [couponCodeCreationMode, setCouponCodeCreationMode] = useState(FormViewMode);
  const [tooltipText, setTooltipText] = useState(t('lbl_copyCouponCode'));
  const [openDeleteDialog, setOpenDeleteDialog] = useState({
    'open': false,
    'couponId': null,
    'couponCode': ""
  });

  const handleCloseDelete = () => {
    setOpenDeleteDialog({
      'open': false,
      'couponId': null,
      'couponCode': ""
    });
  }

  const columns: GridColDef[] = [
    { field: 'discount_id', width: 0, hide: true, },
    {
      field: 'discount_code', flex: 1, headerName: 'Coupon Code', minWidth: 160, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: false,
      renderCell: ({ row }: any) => (
        <>{row.discount_code}</>
      ),
    },
    {
      field: 'discount_percentage', flex: 1, headerName: 'Discount', minWidth: 120, cellClassName: 'app-theme-cell', headerClassName: 'app-theme-header', sortable: false,
      renderCell: ({ row }: any) => (
        <>
          {row.discount_percentage}&nbsp;&#37;
        </>
      ),
    },
    {
      field: 'actions', headerName: 'Actions',
      minWidth: 190, flex: 1, headerClassName: 'app-theme-header bg-action',
      headerAlign: "center", align: "center",
      cellClassName: 'app-theme-cell bg-action', sortable: false,
      renderCell: (params: any) => {
        return <Stack direction="row" spacing={0}>
          <Box className='grid-act-btn'>
            <Tooltip title={tooltipText} placement="bottom-start">
              <IconButton aria-label="AdminIcon" id={ButtonActionCopy} onClick={(e) => {
                onClick(e, params)
              }} color="primary"><span className='cad-copy-icon'></span>
              </IconButton>
            </Tooltip>
          </Box>
          <Box className='grid-act-btn'>
            <Tooltip title="Edit Coupon" placement="bottom-end">
              <IconButton aria-label="AdminIcon" id={ButtonActionEdit} onClick={(e) => {
                onClick(e, params)
              }} color="primary"><span className='cad-edit'></span>
              </IconButton>
            </Tooltip>
          </Box>
          <Box className='grid-act-btn'></Box>
          <Box className='grid-act-btn'>
            <Tooltip title={t('lbl_deleteCoupon')} placement="bottom-end">
              <IconButton aria-label="AdminIcon" id={ButtonActionDelete} color="error" onClick={(e) => {
                onClick(e, params)
              }}><span className='cad-trash-icon'></span></IconButton>
            </Tooltip>
          </Box>
        </Stack>
      }
    }
  ]

  const handleCopyClick = (data: any) => {
    const tempInput = document.createElement('input');
    document.body.appendChild(tempInput);
    tempInput.value = data.discount_code;
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    setTooltipText(t('lbl_copid'));

    setTimeout(() => {
      setTooltipText(t('lbl_copyCouponCode'));
    }, 2000);

  };

  const onClick = (e: any, params: any) => {
    e.stopPropagation(); // don't select this row after 
    OnActionClick(e.currentTarget.id, params?.row);
  };
  //#region Action Buttons Click
  const OnActionClick = (actionId: string, row: any) => {
    switch (actionId) {
      case ButtonActionAdd:
        setCouponCodeCreationMode(FormAddMode);
        setCurrentCouponCodeData(DefaultCouponCodeDetails);
        setIsOpenAddUpdateCouponCode(true);
        break;
      case ButtonActionEdit:
        setCouponCodeCreationMode(FormUpdateMode);
        const couponCodeToEdit = {
          'discount_id': row.discount_id,
          'discount_code': row.discount_code ?? "",
          'discount_percentage': row.discount_percentage ?? "",
        }
        setCurrentCouponCodeData(couponCodeToEdit);
        setIsOpenAddUpdateCouponCode(true);
        break;
      case ButtonActionDelete:
        setOpenDeleteDialog({
          'open': true,
          'couponId': row.discount_id,
          'couponCode': row.discount_code
        });
        break;
      case ButtonActionCopy:
        handleCopyClick(row)
        break;
      default:
        break;
    }
  }
  //#endregion

  //#region GET CADIS ADMIN LIST
  const GetAllDiscountCouponCode = async () => {
    handleToggleLoader(true);

    await apis.instance.get(`${GetAllDiscountCouponCOde}`)
      .then((resp: any) => {
        if (resp.data?.success) {
          let response = resp?.data?.data;
          setCouponCodeListData(response)
        }
        handleToggleLoader(false);
      })
      .catch(err => {
        handleToggleLoader(false);
        if (err?.response.status !== 401) toast.error(err.response?.data?.message)
        console.log(err)
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    GetAllDiscountCouponCode();
    getPageTitleHeight();
  }, []);
  //#endregion

  // dynamic scrollbar for grid view start
  const PageTitleRef: any = useRef();
  const tableGridRef: any = useRef();
  const [tableHeight, setTableHeight] = useState<any>(
  );

  const getPageTitleHeight = () => {
    let pageTitleHeight: any = 0;
    if (PageTitleRef && PageTitleRef.current) {
      pageTitleHeight = PageTitleRef.current.clientHeight;
    }
    setTableHeight(window.innerHeight - pageTitleHeight - 82);
  };

  window.addEventListener("resize", getPageTitleHeight);
  // dynamic scrollbar for grid view end

  const DeleteCouponCode = async () => {
    const couponCodeData = {
      'discount_id': openDeleteDialog.couponId
    }
    try {
      await apis.instance.put(`${DeleteDiscountCouponCOde}`,  couponCodeData )
        .then((resp: any) => {
          if (resp.data.success) {
            handleCloseDelete();
            GetAllDiscountCouponCode()
            toast.success(t('responseMsg.msg_couponCodeDeleted'));
          } else {
            toast.error(resp.data.error);
          }
        })
        .finally();
    } catch (error: any) {
      toast.error(t('responseMsg.msg_wrongEntry'));
      console.log(error.message);
    }
  }
  return (
    <div>
      <Box ref={PageTitleRef} className="page-title-row pb-3">
        <Grid container alignItems={'center'} textAlign={'left'} rowSpacing={2} columnSpacing={{ xs: 2, md: 0 }}>
          <Grid item xs={12} md={auto} mr={auto} sm className="page-title-box" padding={0}>
            <Typography className='page-title mt-0 mb-0' sx={(theme) => theme.typography.h5}>{t('lbl_discountCouponCode')}</Typography>
          </Grid>
          <Grid item xs={12} md={auto} padding={0}>
            <Button id={ButtonActionAdd}
              onClick={(e) => {
                onClick(e, null)
              }} startIcon={<span className='cad-plus'></span>}
              variant="contained" sx={{ my: .5, mr: 1 }}>
              {t('lbl_create')}<Typography sx={{ font: 'inherit' }} display={{ xs: 'none', md: 'inline' }}>&nbsp;{t('lbl_addNewCoupon')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={GridStyling} ref={tableGridRef} height={tableHeight}>
        <DataGrid
          components={{
            NoRowsOverlay: GetGridNoRecordMessage,
          }}
          rows={couponCodeListData}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
          rowsPerPageOptions={GridRowsPerPageOptions}
          getRowId={(row: any) => row.discount_id}
          autoHeight={false}
          autoPageSize={false}
          disableColumnFilter={true}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
        />
      </Box>
      <Dialog
        open={openDeleteDialog.open}
        onClose={() => { return null }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('lbl_confirmation')}
        </DialogTitle>
        <DialogContent>
          {t('lbl_confirmationForDeleteCoupon')} {openDeleteDialog.couponCode} {t("lbl_couponPermanently")}
        </DialogContent>
        <DialogActions>
          <CadisButton onClick={DeleteCouponCode} variant="contained" color='error'>
            {t('lbl_yes')}
          </CadisButton>
          <CadisButton onClick={handleCloseDelete} variant="contained" color='success'>
            {t('lbl_no')}
          </CadisButton>
        </DialogActions>
      </Dialog>
      <AddUpdateCouponCode
        isOpenAddUpdateCouponCode={isOpenAddUpdateCouponCode}
        setIsOpenAddUpdateCouponCode={setIsOpenAddUpdateCouponCode}
        mode={couponCodeCreationMode}
        currentCouponCodeData={currentCouponCodeData}
        GetAllDiscountCouponCode={GetAllDiscountCouponCode}
      />
    </div>
  );
}

export default DiscountCouponCode;